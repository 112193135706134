import { apiPost } from "api"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { withTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	FormGroup,
	Label,
	Modal,
	Row,
} from "reactstrap"
import "./styling.scss"

const EditPickUpPoint = props => {
	const history = useHistory()
	const { id } = useParams()

	const [sending, setSending] = useState(false)
	const [errObj, setErrObj] = useState({})
	const [deleteModal, setDeleteModal] = useState(false)

	const { register, handleSubmit, setValue } = useForm()

	const { response: dataPickUp } = useFetch({
		url: `/points/view/${id}`,
	})

	const editPickUp = data => {
		const {
			name,
			country,
			city,
			street,
			house,
			housing,
			structure,
			timeStart,
			timeEnd,
			holdingDays,
		} = data

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/points/update/${id}`,
				postData: {
					name,
					country,
					city,
					street,
					house,
					housing,
					structure,
					holdingDays: parseInt(holdingDays),
					workTime: { start: timeStart, end: timeEnd },
				},
			})
				.then(res => {
					if (res.status === 406) {
						setErrObj(res.data)

						return
					} else {
						history.goBack()
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const deletePickUp = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/points/delete/${id}`,
				postData: {},
			})
				.then(res => {
					if (res.status === 406) {
						return
					} else {
						history.goBack()
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	useEffect(() => {
		setValue("city", dataPickUp?.city)
		setValue("holdingDays", dataPickUp?.holdingDays)
		setValue("house", dataPickUp?.house)
		setValue("housing", dataPickUp?.housing)
		setValue("name", dataPickUp?.name)
		setValue("street", dataPickUp?.street)
		setValue("structure", dataPickUp?.structure)
		setValue("country", dataPickUp?.country)
		setValue(
			"timeStart",
			dataPickUp?.workTime.start.substr(
				0,
				dataPickUp?.workTime.start.length - 3
			)
		)

		setValue(
			"timeEnd",
			dataPickUp?.workTime.end.substr(0, dataPickUp?.workTime.end.length - 3)
		)
	}, [dataPickUp])

	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>Изменение пункта выдачи</h4>
					</div>

					<Row>
						<Col lg={12}>
							<form onSubmit={handleSubmit(editPickUp)}>
								<Card>
									<CardBody>
										<Row>
											<Col sm="6">
												<FormGroup>
													<Label className="mt-2">Пункт выдачи</Label>
													<Input
														name="name"
														type="text"
														error={errObj.name}
														register={register}
													/>
													<Label className="mt-2">Страна</Label>
													<Input
														name="country"
														type="text"
														error={errObj.country}
														register={register}
													/>
													<Label className="mt-2">Город</Label>
													<Input
														name="city"
														type="text"
														error={errObj.city}
														register={register}
													/>
													<Label className="mt-2">Улица</Label>
													<Input
														name="street"
														type="text"
														error={errObj.street}
														register={register}
													/>
													<Label className="mt-2">Дом</Label>
													<Input
														name="house"
														type="text"
														error={errObj.house}
														register={register}
													/>
													<Label className="mt-2">Строение</Label>
													<Input
														name="housing"
														type="text"
														register={register}
														error={errObj.housing}
													/>
													<Label className="mt-2">Корпус</Label>
													<Input
														name="structure"
														type="text"
														register={register}
														error={errObj.structure}
													/>
												</FormGroup>
											</Col>
											<Col sm="6">
												<FormGroup>
													<Label className="mt-2">Время начала работы</Label>
													<Input
														name="timeStart"
														type="time"
														register={register}
														error={errObj.workTime}
													/>
													<Label className="mt-2">Время окончания работы</Label>
													<Input
														register={register}
														name="timeEnd"
														type="time"
														error={errObj.workTime}
													/>
													<Label className="mt-2">
														Срок хранения заказа (дней)
													</Label>
													<Input
														register={register}
														name="holdingDays"
														type="number"
														error={errObj.shelfLife}
													/>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									<CardFooter>
										<Row>
											<Col xs={"auto"}>
												<Button type="submit" color="primary">
													Изменить
												</Button>
											</Col>
											<Col xs={"auto"}>
												<Button
													onClick={() => setDeleteModal(true)}
													color="danger"
												>
													Удалить
												</Button>
											</Col>
										</Row>
									</CardFooter>
								</Card>
							</form>
						</Col>
					</Row>
				</div>
			</div>
			<Modal
				isOpen={deleteModal}
				toggle={() => {
					setDeleteModal(!deleteModal)
				}}
				centered={true}
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0">
						Вы точно хотите удалить пункт выдачи?
					</h5>
					<button
						type="button"
						onClick={() => {
							setDeleteModal(false)
						}}
						className="close"
						data-dismiss="modal"
						aria-label="Закрыть"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body ">
					<div className="text-center mt-4 m-auto">
						<Button
							className="mr-2 "
							color="danger"
							onClick={() => deletePickUp()}
						>
							Да
						</Button>
						<Button color="primary" onClick={() => setDeleteModal(false)}>
							Нет
						</Button>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default withTranslation()(EditPickUpPoint)
