import { apiPost } from "api"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	Container,
	FormGroup,
	Label,
	Modal,
	Row,
} from "reactstrap"

const EditQuestion = () => {
	const [deleteModal, setDeleteModal] = useState(false)
	const { id } = useParams()
	const [answer, setAnswer] = useState("")
	const history = useHistory()
	const [deleteText, setDeleteText] = useState("")
	const [errObj, setErrObj] = useState({})

	const { response: getQuestion, fetchData } = useFetch({
		url: `/question/view/${id}`,
	})

	const answerForQuestion = () => {
		apiPost({
			url: `/question/answer/${id}`,
			postData: { text: answer },
		}).then(res => {
			if (res.status === 406) {
				setErrObj(res.data)
				return
			}

			history.goBack()
		})
	}

	const rejectQuestion = () => {
		apiPost({
			url: `/question/reject/${getQuestion.id}`,
			postData: { reject: deleteText },
		}).then(res => {
			if (res.status === 406) {
				setErrObj(res.data)
				return
			}
			setErrObj({})
			setDeleteModal(false)
			fetchData()
		})
	}

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>Редактирование вопроса</h4>
					</div>

					<Row>
						<Col xs="12">
							<Card>
								<CardBody>
									<Row>
										<Col sm="12">
											<Row>
												<Col sm="4" className="mb-3">
													<Label>Фамилия</Label>
													<Input
														disabled
														defaultValue={getQuestion?.user.lastName}
														type="text"
													/>
												</Col>
												<Col sm="4" className="mb-3">
													<Label>Имя</Label>
													<Input
														disabled
														defaultValue={getQuestion?.user.name}
														type="text"
													/>
												</Col>
												<Col sm="4" className="mb-3">
													<Label>Отчество</Label>
													<Input
														disabled
														defaultValue={getQuestion?.user.patronymic}
														type="text"
													/>
												</Col>
												<Col sm="4" className="mb-3">
													<Label>Email</Label>
													<Input
														disabled
														defaultValue={getQuestion?.user.email}
														type="text"
													/>
												</Col>
												<Col sm="4" className="mb-3">
													<Label>Номер телефона</Label>
													<Input
														disabled
														defaultValue={getQuestion?.user.phone}
														type="text"
													/>
												</Col>
											</Row>
											<FormGroup>
												<Label htmlFor="productdesc">Вопрос</Label>
												<textarea
													disabled
													className={`form-control `}
													id="productdesc"
													rows="5"
													defaultValue={getQuestion?.text}
												/>
											</FormGroup>
											{getQuestion?.reject && (
												<FormGroup>
													<Label htmlFor="productdesc" className="mt-2">
														Причина отклонения
													</Label>
													<textarea
														className={`form-control`}
														id="productdesc"
														rows="2"
														disabled
														defaultValue={getQuestion.reject}
													/>
												</FormGroup>
											)}
											{getQuestion?.answer.map(i => (
												<FormGroup key={i.id}>
													<Label htmlFor="productdesc" className="mt-2">
														Ответ на вопрос
													</Label>
													<textarea
														className={`form-control`}
														id="productdesc"
														rows="2"
														disabled
														defaultValue={i.text}
													/>
												</FormGroup>
											))}
											{!getQuestion?.reject && (
												<FormGroup>
													<Label htmlFor="productdesc" className="mt-2">
														Ответ на вопрос
													</Label>
													<textarea
														className={`form-control ${
															errObj.text && "_error"
														}`}
														id="productdesc"
														rows="5"
														onChange={e => setAnswer(e.target.value)}
														defaultValue={answer}
													/>
													{errObj.text && (
														<div
															className="error-text"
															style={{ color: " #c72626" }}
														>
															{errObj.text}
														</div>
													)}
												</FormGroup>
											)}
										</Col>
									</Row>
								</CardBody>
							</Card>

							<Card>
								{!getQuestion?.reject && (
									<CardFooter>
										<Button
											type="submit"
											color="primary"
											className="mr-1 waves-effect waves-light"
											onClick={() => answerForQuestion()}
										>
											Опубликовать вопрос
										</Button>

										<Button
											onClick={() => setDeleteModal(true)}
											color="danger"
											className="waves-effect"
										>
											Отклонить вопрос
										</Button>
									</CardFooter>
								)}
							</Card>
						</Col>
					</Row>
				</Container>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите отклонить этот вопрос?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<textarea
							className={`form-control d-block ${errObj.reject && "_error"}`}
							id="productdesc"
							rows="5"
							placeholder="Опишите причину отклонения вопроса..."
							onChange={e => setDeleteText(e.target.value)}
						/>
						<div className="mb-2" style={{ color: "#c72626" }}>
							{errObj.reject && errObj.reject}
						</div>
						<div className="text-center mt-4 m-auto">
							<Button
								onClick={() => rejectQuestion()}
								className="mr-2 "
								color="danger"
							>
								Да
							</Button>
							<Button
								color="primary"
								onClick={() => {
									setDeleteModal(false)
								}}
							>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default EditQuestion
