//Import data
import { apiPost } from "api"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import "nouislider/distribute/nouislider.css"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	Modal,
	Row,
	Table,
} from "reactstrap"
import updateUrl from "utils/updateUrl"

const Products = props => {
	const { page } = useUrl()

	const [searchProduct, setSearchProduct] = useState("")
	const [deleteModal, setDeleteModal] = useState(false)
	const [deleteId, setDeleteId] = useState("")
	const [sending, setSending] = useState(false)
	const [entranceModal, setEntranceModal] = useState(false)
	const [writeDownsModal, setWriteDownsModal] = useState(false)

	let url = `product/index?search=${searchProduct}&page=${page || 1}&limit=15`

	const { response: dataProducts, fetchData } = useFetch({
		url: url,
	})

	const deleteProduct = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/product/delete/${deleteId}`,
				postData: {},
			})
				.then(res => {
					if (res.error) {
						return
					} else {
						setDeleteModal(false)
						fetchData()
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	useEffect(() => {
		updateUrl({ key: "page", value: "1" })
	}, [searchProduct])

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Products")}</h4>
						<Link
							to="/product/add"
							color="primary"
							className="btn btn-primary waves-effect waves-light"
						>
							{props.t("Add product")}
						</Link>
					</div>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row className="mb-3">
										<Col>
											<Form className="mt-4 mt-sm-0  form-inline">
												<div className="search-box mr-2">
													<div className="position-relative">
														<Input
															type="text"
															className="form-control border-0"
															placeholder={props.t("Search") + "..."}
															onChange={e => setSearchProduct(e.target.value)}
														/>
														<i className="bx bx-search-alt search-icon" />
													</div>
												</div>
											</Form>
										</Col>
									</Row>
									<Row>
										<div className="table-responsive">
											<Table className="table mb-0">
												<thead className="thead-light">
													<tr>
														<th>Название товара</th>
														<th>Старая цена</th>
														<th>Новая цена</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{dataProducts &&
														dataProducts.models.map((product, key) => (
															<tr key={key}>
																<th>{product.name}</th>
																<td>
																	{product.price.old ? product.price.old : ""}
																</td>
																<td>{product.price.current}</td>
																<td className="edit">
																	<Link
																		to={`/product-version-list/${product.id}`}
																		className="mr-1 btn btn-primary waves-effect waves-light"
																	>
																		<i className="bx bx-show" />
																	</Link>
																	<Link
																		to={`/product/edit/${product.id}`}
																		className="mr-1 btn btn-warning waves-effect waves-light"
																	>
																		<i className="bx bx-cog" />
																	</Link>
																	<Button
																		color="danger"
																		className="mr-1 btn btn-warning waves-effect waves-light"
																		onClick={() => (
																			setDeleteModal(true),
																			setDeleteId(product.id)
																		)}
																	>
																		<i className="bx bx-trash-alt" />
																	</Button>
																</td>
															</tr>
														))}
												</tbody>
											</Table>
										</div>
									</Row>

									<Row>
										<Col lg="12">
											<Pagination
												lastPage={dataProducts?.lastPage}
												page={page}
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этот товар?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deleteProduct()}
							>
								Да
							</Button>
							<Button
								color="primary"
								onClick={() => {
									setDeleteModal(false)
								}}
							>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

Products.propTypes = {
	t: PropTypes.any,
}

export default withTranslation()(Products)
