import { apiPost } from "api"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardTitle,
	Col,
	Container,
	FormGroup,
	Label,
	Modal,
	Row,
} from "reactstrap"

const EditUser = () => {
	const { id } = useParams()
	const history = useHistory()
	const { register, handleSubmit, setValue } = useForm()
	const [sending, setSending] = useState(false)

	const { response: dataUser } = useFetch({
		url: `/user/view/${id}`,
	})

	const updateUser = data => {
		if (!sending) {
			const { email, lastName, name, patronymic, phone, birthdate } = data
			const revertDateFromServer = birthdate.split("-").reverse().join(".")
			setSending(true)
			apiPost({
				url: `/user/update/${id}`,
				postData: {
					email,
					lastname: lastName,
					name,
					patronymic,
					phone,
					birthdate: revertDateFromServer,
				},
			})
				.then(res => {
					if (res.error) {
						return
					}
					history.goBack()
				})
				.finally(() => setSending(false))
		}
	}

	const deleteUser = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/user/delete/${id}`,
			})
				.then(res => {
					history.goBack()
				})
				.finally(() => setSending(false))
		}
	}

	useEffect(() => {
		setValue("email", dataUser?.email)
		setValue("lastName", dataUser?.lastName)
		setValue("name", dataUser?.name)
		setValue("patronymic", dataUser?.patronymic)
		setValue("phone", dataUser?.phone)
		if (dataUser?.birthdate) {
			let newDate = dataUser?.birthdate
			const revertDateFromCalendar = newDate.split(".").reverse().join("-")
			setValue("birthdate", revertDateFromCalendar)
		}
	}, [dataUser])

	const [deleteModal, setDeleteModal] = useState(false)

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Row>
						<Col xs="12">
							<AvForm onValidSubmit={handleSubmit(updateUser)}>
								<Card>
									{/* {updatedUserSuccess ? (
										<SweetAlert
											title="Пользователь успешно изменен"
											onConfirm={() => {
												setUpdatedUserSuccess(false)
											}}
										/>
									) : null} */}
									<CardBody>
										<CardTitle>Изменение пользователя</CardTitle>

										<Row>
											<Col sm="4">
												<FormGroup>
													<Label htmlFor="lastname">Фамилия</Label>
													<Input
														register={register}
														name="lastName"
														type="text"
														className="form-control"
													/>
												</FormGroup>
											</Col>
											<Col sm="4">
												<FormGroup>
													<Label htmlFor="name">Имя</Label>
													<Input
														register={register}
														name="name"
														type="text"
														className="form-control"
													/>
												</FormGroup>
											</Col>
											<Col sm="4">
												<FormGroup>
													<Label htmlFor="surname">Отчество</Label>
													<Input
														register={register}
														name="patronymic"
														type="text"
														className="form-control"
													/>
												</FormGroup>
											</Col>
											<Col sm="4">
												<FormGroup>
													<Label htmlFor="email">E-mail</Label>
													<Input
														register={register}
														id="email"
														name="email"
														type="text"
														className="form-control"
													/>
												</FormGroup>
											</Col>
											<Col sm="4">
												<FormGroup>
													<Label htmlFor="phone">Телефон</Label>
													<Input
														register={register}
														name="phone"
														type="number"
														className="form-control"
													/>
												</FormGroup>
											</Col>
											<Col sm="4">
												<FormGroup>
													<Label htmlFor="birthdate">Дата рождения</Label>
													<Input
														register={register}
														id="birthdate"
														name="birthdate"
														type="date"
														className="form-control"
													/>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									<CardFooter>
										<Row>
											<div className="col-auto">
												<Button
													type="submit"
													color="primary"
													className="mr-1 waves-effect waves-light"
												>
													Изменить
												</Button>
											</div>
											<div className="col-auto">
												<Button
													onClick={() => setDeleteModal(true)}
													color="danger"
													className="waves-effect"
												>
													Удалить
												</Button>
											</div>
										</Row>
									</CardFooter>
								</Card>
							</AvForm>
						</Col>
					</Row>
				</Container>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этого пользователя?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								onClick={() => deleteUser()}
								className="mr-2 "
								color="danger"
							>
								Да
							</Button>
							<Button
								type="submit"
								color="primary"
								onClick={() => setDeleteModal(false)}
							>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default EditUser
