import { apiPost } from "api"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { Link, useHistory } from "react-router-dom"
import Select from "react-select"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	Form,
	FormGroup,
	Label,
	Row,
} from "reactstrap"
import { transliterate } from "utils/transliterate"
import "./styling.scss"

const AddNews = () => {
	const [sending, setSending] = useState(false)
	const [errObj, setErrObj] = useState({})
	const history = useHistory()

	const [newsName, setNewsName] = useState("")
	const [description, setDescription] = useState("")
	const [selectedMulti, setSelectedMulti] = useState([])
	const [options, setOptions] = useState([])
	const [selectedFile, setSelectedFile] = useState()
	const [newsUrl, setNewsUrl] = useState("")

	const { response: tagsData } = useFetch({
		url: `/theme/index`,
	})

	function handleMulti(e) {
		setSelectedMulti(e)
	}

	function formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return "0 Bytes"
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
	}

	const handleAcceptedFiles = files => {
		files.map(file =>
			Object.assign(file, {
				preview: URL.createObjectURL(file),
				formattedSize: formatBytes(file.size),
			})
		)

		setSelectedFile(...files)
	}

	const createNews = () => {
		let arr = []
		selectedMulti?.map(i => arr.push(i.value))

		if (!sending) {
			setSending(true)

			const postData = new FormData()
			postData.append("name", newsName)
			postData.append("text", description)
			postData.append("banner", selectedFile)
			postData.append("permalink", newsUrl)

			if (arr) {
				arr.forEach((f, index) => postData.append(`themeIdList[${index}]`, f))
			}

			apiPost({
				url: `/news/create`,
				postData,
			})
				.then(res => {
					if (res.status === 406) {
						let arrErr = { ...res.data }
						if (selectedMulti.length == 0) {
							arrErr.tags = "Необходимо выбрать теги новости"
						}
						if (res?.data?.text) {
							arrErr.des = "Необходимо описание новости"
						}
						setErrObj(arrErr)
						return
					}
					history.goBack()
					setErrObj({})
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	useEffect(() => {
		setOptions(tagsData?.models?.map(i => ({ value: i.id, label: i.name })))
	}, [tagsData])

	useEffect(() => {
		setNewsUrl(transliterate(newsName))
	}, [newsName])

	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<Row>
										<Col sm={6}>
											<FormGroup>
												<Label htmlFor="newsName">Название новости</Label>
												<Input
													value={newsName}
													onChange={e => setNewsName(e)}
													type="text"
													error={errObj.name}
												/>
											</FormGroup>
										</Col>
										<Col sm={6}>
											<FormGroup>
												<Label htmlFor="newsName">Url новости</Label>
												<Input
													value={newsUrl}
													onChange={e => setNewsUrl(transliterate(e))}
													type="text"
													error={errObj.permalink}
												/>
											</FormGroup>
										</Col>
										<Col sm={6}>
											<Label htmlFor="newsName">Теги новости</Label>
											<Select
												className={`basic-multi-select ${
													errObj.tags && " _error"
												}`}
												value={selectedMulti}
												noOptionsMessage={() => "Нет вариантов.."}
												placeholder="Выберите характеристики..."
												isMulti={true}
												onChange={e => {
													handleMulti(e)
												}}
												options={options}
												classNamePrefix={`select-control`}
											/>
											<div className="form-error">
												{errObj.tags && errObj.tags}
											</div>
										</Col>
									</Row>

									<Form className="mb-3 mt-3">
										<ReactQuill value={description} onChange={setDescription} />

										<div className="form-error">{errObj.des && errObj.des}</div>
									</Form>

									<Dropzone
										onDrop={acceptedFiles => {
											handleAcceptedFiles(acceptedFiles)
										}}
									>
										{({ getRootProps, getInputProps }) => (
											<div className="dropzone">
												<div
													className="dz-message needsclick"
													{...getRootProps()}
												>
													<input {...getInputProps()} />
													<div className="dz-message needsclick">
														<div className="mb-3">
															<i className="display-4 text-muted bx bxs-cloud-upload" />
														</div>
														<h4>Переместите логотип сюда.</h4>
													</div>
												</div>
											</div>
										)}
									</Dropzone>
									<div className="form-error">
										{errObj.banner && errObj.banner}
									</div>
									{selectedFile && (
										<Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
											<div className="p-2">
												<Row className="align-items-center">
													<Col className="col-auto">
														<img
															data-dz-thumbnail=""
															height="80"
															className="avatar-sm rounded bg-light"
															alt={selectedFile.name}
															src={selectedFile.preview}
														/>
													</Col>
													<Col>
														<Link
															to="#"
															className="text-muted font-weight-bold"
														>
															{selectedFile.name}
														</Link>
														<p className="mb-0">
															<strong>{selectedFile.formattedSize}</strong>
														</p>
													</Col>
													<Col xs={"auto"}>
														<Button
															color="danger"
															onClick={() => setSelectedFile()}
														>
															<i className="bx bx-trash-alt" />
														</Button>
													</Col>
												</Row>
											</div>
										</Card>
									)}
								</CardBody>
								<CardFooter>
									<Row>
										<Col lg={12}>
											<Button
												color="primary"
												className="btn btn-primary waves-effect waves-light mb-2"
												onClick={() => createNews()}
											>
												Создать новость
											</Button>
										</Col>
									</Row>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}

export default AddNews
