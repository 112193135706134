import Additionally from "pages/Additionally"
import AddNews from "pages/AddNews"
import AddProduct from "pages/AddProduct"
import Brands from "pages/Brands"
import Catalog from "pages/Category"
import Characters from "pages/Characters"
import CreatePickUpPoint from "pages/CreatePickUpPoint"
import CreatePromo from "pages/CreatePromo"
import CreateSale from "pages/CreateSale"
import editNews from "pages/EditNews"
import EditPickUpPoint from "pages/EditPickUpPoint"
import EditPromo from "pages/EditPromo"
import EditQuestion from "pages/EditQuestion"
import EditReview from "pages/EditReview"
import EditSale from "pages/EditSale"
import EditUser from "pages/EditUser"
import Entrance from "pages/Entrance"
import Faq from "pages/Faq"
import FaqQuestions from "pages/FaqQuestions"
import News from "pages/News"
import Orders from "pages/Orders"
import PickUpPoint from "pages/PickUpPoint"
import ProductEdit from "pages/ProductEdit"
import Products from "pages/Products"
import ProductVersionList from "pages/ProductVersionList"
import Profile from "pages/Profile"
import PromoCode from "pages/PromoCode"
import Questions from "pages/Questions"
import Reviews from "pages/Reviews"
import Sales from "pages/Sales"
import Users from "pages/Users"
import WriteDowns from "pages/WriteDowns"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Register from "../pages/Authentication/Register"

const userRoutes = [
	{ path: "/", component: Products },
	{ path: "/additionally", component: Additionally },
	{ path: "/pick-up-point/edit/:id", component: EditPickUpPoint },
	{ path: "/pick-up-point/create", component: CreatePickUpPoint },
	{ path: "/pick-up-point", component: PickUpPoint },
	{ path: "/promo-code/edit/:id", component: EditPromo },
	{ path: "/promo-code/create", component: CreatePromo },
	{ path: "/promo-code", component: PromoCode },
	{ path: "/edit-review/:id", component: EditReview },
	{ path: "/reviews", component: Reviews },
	{ path: "/question/edit/:id", component: EditQuestion },
	{ path: "/questions", component: Questions },
	{ path: "/user/edit/:id", component: EditUser },
	{ path: "/users", component: Users },
	{ path: "/news/edit/:id", component: editNews },
	{ path: "/news/add", component: AddNews },
	{ path: "/news", component: News },
	{ path: "/orders", component: Orders },
	{ path: "/product/edit/:id", component: ProductEdit },
	{ path: "/category", component: Catalog },
	{ path: "/products", component: Products },
	{ path: "/product/add", component: AddProduct },
	{ path: "/brands", component: Brands },
	{ path: "/product-version-list/:id", component: ProductVersionList },
	{ path: "/characters", component: Characters },
	{ path: "/sales", component: Sales },
	{ path: "/sale/create", component: CreateSale },
	{ path: "/sale/edit/:id", component: EditSale },
	{ path: "/profile", component: Profile },
	{ path: "/entrance/:id", component: Entrance },
	{ path: "/write-downs/:id", component: WriteDowns },
	{ path: "/faq", component: Faq },
	{ path: "/faq/questions/:id/:permalink", component: FaqQuestions },

	// { path: "/dashboard-saas", component: DashboardSaas },
	// { path: "/dashboard-crypto", component: DashboardCrypto },
	// { path: "/blog", component: Blog },

	// //Crypto
	// { path: "/crypto-wallet", component: CryptoWallet },
	// { path: "/crypto-buy-sell", component: CryptoBuySell },
	// { path: "/crypto-exchange", component: CryptoExchange },
	// { path: "/crypto-lending", component: CryptoLending },
	// { path: "/crypto-orders", component: CryptoOrders },
	// { path: "/crypto-kyc-application", component: CryptoKYCApplication },

	// //chat
	// { path: "/chat", component: Chat },

	// //File Manager
	// { path: "/apps-filemanager", component: FileManager },

	// // //calendar
	// { path: "/calendar", component: Calendar },

	// // //profile
	// { path: "/profile", component: UserProfile },

	// //Ecommerce
	// { path: "/ecommerce-products", component: EcommerceProducts },
	// { path: "/ecommerce-product-detail", component: EcommerceProductDetail },
	// { path: "/ecommerce-products/:id", component: EcommerceProductDetail },
	// { path: "/ecommerce-orders", component: EcommerceOrders },
	// { path: "/ecommerce-customers", component: EcommerceCustomers },
	// { path: "/ecommerce-cart", component: EcommerceCart },
	// { path: "/ecommerce-checkout", component: EcommerceCheckout },
	// { path: "/ecommerce-shops", component: EcommerceShops },
	// { path: "/ecommerce-add-product", component: EcommerceAddProduct },

	// //Email
	// { path: "/email-inbox", component: EmailInbox },
	// { path: "/email-read", component: EmailRead },
	// { path: "/email-template-basic", component: EmailBasicTemplte },
	// { path: "/email-template-alert", component: EmailAlertTemplte },
	// { path: "/email-template-billing", component: EmailTemplateBilling },

	// //Invoices
	// { path: "/invoices-list", component: InvoicesList },
	// { path: "/invoices-detail", component: InvoiceDetail },
	// { path: "/invoices-detail/:id", component: InvoiceDetail },

	// // Tasks
	// { path: "/tasks-list", component: TasksList },
	// { path: "/tasks-kanban", component: TasksKanban },
	// { path: "/tasks-create", component: TasksCreate },

	// //Projects
	// { path: "/projects-grid", component: ProjectsGrid },
	// { path: "/projects-list", component: ProjectsList },
	// { path: "/projects-overview", component: ProjectsOverview },
	// { path: "/projects-overview/:id", component: ProjectsOverview },
	// { path: "/projects-create", component: ProjectsCreate },

	// //Blog
	// { path: "/blog-list", component: BlogList },
	// { path: "/blog-grid", component: BlogGrid },
	// { path: "/blog-details", component: BlogDetails },

	// // Contacts
	// { path: "/contacts-grid", component: ContactsGrid },
	// { path: "/contacts-list", component: ContactsList },
	// { path: "/contacts-profile", component: ContactsProfile },

	// //Charts
	// { path: "/apex-charts", component: ChartApex },
	// { path: "/chartist-charts", component: ChartistChart },
	// { path: "/chartjs-charts", component: ChartjsChart },
	// { path: "/e-charts", component: EChart },
	// { path: "/sparkline-charts", component: SparklineChart },
	// { path: "/tui-charts", component: ToastUIChart },
	// { path: "/charts-knob", component: ChartsKnob },

	// // Icons
	// { path: "/icons-boxicons", component: IconBoxicons },
	// { path: "/icons-dripicons", component: IconDripicons },
	// { path: "/icons-materialdesign", component: IconMaterialdesign },
	// { path: "/icons-fontawesome", component: IconFontawesome },

	// // Tables
	// { path: "/tables-basic", component: BasicTables },
	// { path: "/tables-datatable", component: DatatableTables },
	// { path: "/tables-responsive", component: ResponsiveTables },
	// { path: "/tables-editable", component: EditableTables },

	// // Maps
	// { path: "/maps-google", component: MapsGoogle },
	// { path: "/maps-vector", component: MapsVector },
	// { path: "/maps-leaflet", component: MapsLeaflet },

	// // Forms
	// { path: "/form-elements", component: FormElements },
	// { path: "/form-layouts", component: FormLayouts },
	// { path: "/form-advanced", component: FormAdvanced },
	// { path: "/form-editors", component: FormEditors },
	// { path: "/form-mask", component: FormMask },
	// { path: "/form-repeater", component: FormRepeater },
	// { path: "/form-uploads", component: FormUpload },
	// { path: "/form-wizard", component: FormWizard },
	// { path: "/form-validation", component: FormValidations },
	// { path: "/form-xeditable", component: FormXeditable },

	// // Ui
	// { path: "/ui-alerts", component: UiAlert },
	// { path: "/ui-buttons", component: UiButtons },
	// { path: "/ui-cards", component: UiCards },
	// { path: "/ui-carousel", component: UiCarousel },
	// { path: "/ui-colors", component: UiColors },
	// { path: "/ui-dropdowns", component: UiDropdown },
	// { path: "/ui-general", component: UiGeneral },
	// { path: "/ui-grid", component: UiGrid },
	// { path: "/ui-images", component: UiImages },
	// { path: "/ui-lightbox", component: UiLightbox },
	// { path: "/ui-modals", component: UiModal },
	// { path: "/ui-progressbars", component: UiProgressbar },
	// { path: "/ui-sweet-alert", component: UiSweetAlert },
	// { path: "/ui-tabs-accordions", component: UiTabsAccordions },
	// { path: "/ui-typography", component: UiTypography },
	// { path: "/ui-video", component: UiVideo },
	// { path: "/ui-session-timeout", component: UiSessionTimeout },
	// { path: "/ui-rating", component: UiRating },
	// { path: "/ui-rangeslider", component: UiRangeSlider },
	// { path: "/ui-notifications", component: UiNotifications },
	// { path: "/ui-image-cropper", component: UiImageCropper },

	// //Utility
	// { path: "/pages-starter", component: PagesStarter },
	// { path: "/pages-timeline", component: PagesTimeline },
	// { path: "/pages-faqs", component: PagesFaqs },
	// { path: "/pages-pricing", component: PagesPricing },

	// // this route should be at the end of all other routes
	// { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
	{ path: "/", component: Login },
	// { path: "/logout", component: Logout },
	// { path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	//
	// { path: "/pages-maintenance", component: PagesMaintenance },
	// { path: "/pages-comingsoon", component: PagesComingsoon },
	// { path: "/pages-404", component: Pages404 },
	// { path: "/pages-500", component: Pages500 },
	// { path: "/crypto-ico-landing", component: CryptoIcoLanding },
	//
	// // Authentication Inner
	// { path: "/pages-login", component: Login1 },
	// { path: "/pages-login-2", component: Login2 },
	// { path: "/pages-register", component: Register1 },
	// { path: "/pages-register-2", component: Register2 },
	// { path: "/page-recoverpw", component: Recoverpw },
	// { path: "/page-recoverpw-2", component: Recoverpw2 },
	// { path: "/pages-forgot-pwd", component: ForgetPwd1 },
	// { path: "/auth-lock-screen", component: LockScreen },
	// { path: "/auth-lock-screen-2", component: LockScreen2 },
	// { path: "/page-confirm-mail", component: ConfirmMail },
	// { path: "/page-confirm-mail-2", component: ConfirmMail2 },
	// { path: "/auth-email-verification", component: EmailVerification },
	// { path: "/auth-email-verification-2", component: EmailVerification2 },
	// { path: "/auth-two-step-verification", component: TwostepVerification },
	// { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
