import PropTypes from "prop-types"
import React, { memo } from "react"
import "./styling.scss"

const Input = ({
	register = null,
	label = null,
	fieldClasses = "",
	disabled = false,
	onChange = () => {},
	error = null,
	...props
}) => {
	let inputClassName = "form-input"

	if (error) {
		inputClassName += " _error"
	}

	return (
		<label className={`form-field ${fieldClasses}`}>
			{label && (
				<span
					className={`form-label label ${disabled ? "cursor-default" : ""}`}
				>
					{label}
				</span>
			)}

			<input
				ref={register}
				className={inputClassName}
				onChange={e => onChange(e.target.value)}
				disabled={disabled}
				{...props}
			/>

			{error && <div className="form-error">{error}</div>}
		</label>
	)
}
Input.propTypes = {
	label: PropTypes.any,
	register: PropTypes.any,
	fieldClasses: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	error: PropTypes.any,
}

export default memo(Input)
