import { apiPost } from "api"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Input from "components/Input"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardImg,
	CardTitle,
	Col,
	Modal,
	Row
} from "reactstrap"
import { transliterate } from "utils/transliterate"
import "./styling.scss"
const Brands = props => {
	const { page } = useUrl()

	const [sending, setSending] = useState(false)

	const [addBrandModal, setAddBrandModal] = useState(false)
	const [editBrandModal, setEditBrandModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [selectedFile, setSelectedFile] = useState()

	const [createBrandName, setCreateBrandName] = useState("")
	const [createBrandUrl, setCreateBrandUrl] = useState("")

	const [editBrandName, setEditBrandName] = useState("")
	const [editBrandUrl, setEditBrandUrl] = useState("")
	const [editBrandId, setEditBrandId] = useState("")
	const [editBrandImg, setEditBrandImg] = useState()

	const [deleteBrandId, setDeleteBrandId] = useState("")

	const [errObj, setErrObj] = useState({})

	const { response: dataBrands, fetchData } = useFetch({
		url: `/brand/index?page=${page || 1}&limit=12`,
	})

	const handleAcceptedFiles = files => {
		files.map(file =>
			Object.assign(file, {
				preview: URL.createObjectURL(file),
				formattedSize: formatBytes(file.size),
			})
		)

		setSelectedFile(...files)
	}

	const editBrandModalOpen = i => {
		setEditBrandName(i.name)
		setEditBrandId(i.id)
		setEditBrandUrl(i.url)
		setEditBrandModal(true)
		i?.banner?.path && setEditBrandImg(i?.banner?.path)
	}

	function formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return "0 Bytes"
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
	}

	const createBrand = () => {
		if (!sending) {
			setSending(true)
			const postData = new FormData()
			postData.append("name", createBrandName)
			postData.append("url", createBrandUrl)
			postData.append("file", selectedFile)

			apiPost({
				url: "/brand/create",
				postData,
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}
					setAddBrandModal(false)
					fetchData()
					setSelectedFile()
					setCreateBrandName("")
					setCreateBrandUrl("")
					setErrObj({})
				})
				.finally(() => setSending(false))
		}
	}

	const editBrand = () => {
		const postData = new FormData()
		postData.append("name", editBrandName)
		postData.append("url", editBrandUrl)
		postData.append("file", selectedFile)
		if (!sending) {
			setSending(true)

			apiPost({
				url: `/brand/update/${editBrandId}`,
				postData,
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}
					setEditBrandModal(false)
					fetchData()
					setSelectedFile()
				})
				.finally(() => setSending(false))
		}
	}

	const deleteBrand = () => {
		const postData = new FormData()
		postData.append("name", editBrandName)
		postData.append("url", editBrandUrl)
		postData.append("file", selectedFile)
		if (!sending) {
			setSending(true)

			apiPost({
				url: `/brand/delete/${deleteBrandId}`,
				postData,
			})
				.then(res => {
					setDeleteModal(false)
					fetchData()
				})
				.finally(() => setSending(false))
		}
	}

	useEffect(() => {
		setCreateBrandUrl(transliterate(createBrandName))
	}, [createBrandName])

	useEffect(() => {
		setEditBrandUrl(transliterate(editBrandName))
	}, [editBrandName])

	useEffect(() => {
		setErrObj({})
	}, [addBrandModal, editBrandModal])

	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Brands")}</h4>
						<Button
							onClick={() => setAddBrandModal(true)}
							color="primary"
							className="btn btn-primary waves-effect waves-light mb-2"
						>
							Добавить бренд
						</Button>
					</div>

					<Row>
						{dataBrands &&
							dataBrands.models.map(i => (
								<Col xl={2} key={i.id}>
									<Card>
										{i?.banner?.path && (
											<CardImg
												top
												className="img-fluid"
												src={i.banner.path}
												alt="Skote"
											/>
										)}

										<CardBody>
											<CardTitle className="mt-0 brand-img">{i.name}</CardTitle>
											<Button
												color="warning"
												className="mr-1 btn btn-primary waves-effect waves-light"
												onClick={() => editBrandModalOpen(i)}
											>
												<i className="bx bx-cog" />
											</Button>
											<Button
												color="danger"
												className="mr-1 waves-effect waves-light"
												onClick={() => {
													setDeleteModal(true), setDeleteBrandId(i.id)
												}}
											>
												<i className="bx bx-trash-alt" />
											</Button>
										</CardBody>
									</Card>
								</Col>
							))}
					</Row>
					<Pagination lastPage={dataBrands?.lastPage} page={page} />
				</div>
				<Modal
					isOpen={addBrandModal}
					toggle={() => {
						setAddBrandModal(!addBrandModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Создание</h5>
						<button
							type="button"
							onClick={() => {
								setAddBrandModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal" onValidSubmit={createBrand}>
							<div className="form-group">
								<Input
									fieldClasses="mb-2"
									name="createBrandName"
									value={createBrandName}
									label="Наименование"
									onChange={e => setCreateBrandName(e)}
									placeholder="Введите название бренда"
									type="text"
									error={errObj.name}
								/>
								<Input
									fieldClasses="mb-2"
									name="createBrandUrl"
									label="url"
									value={createBrandUrl}
									onChange={e => setCreateBrandUrl(transliterate(e))}
									placeholder="Введите url"
									type="text"
									error={errObj.url}
								/>
							</div>

							<Dropzone
								onDrop={acceptedFiles => {
									handleAcceptedFiles(acceptedFiles)
								}}
							>
								{({ getRootProps, getInputProps }) => (
									<div className="dropzone">
										<div className="dz-message needsclick" {...getRootProps()}>
											<input {...getInputProps()} />
											<div className="dz-message needsclick">
												<div className="mb-3">
													<i className="display-4 text-muted bx bxs-cloud-upload" />
												</div>
												<h4>Переместите логотип сюда.</h4>
											</div>
										</div>
									</div>
								)}
							</Dropzone>
							{selectedFile && (
								<Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
									<div className="p-2">
										<Row className="align-items-center">
											<Col className="col-auto">
												<img
													data-dz-thumbnail=""
													height="80"
													className="avatar-sm rounded bg-light"
													alt={selectedFile.name}
													src={selectedFile.preview}
												/>
											</Col>
											<Col>
												<Link to="#" className="text-muted font-weight-bold">
													{selectedFile.name}
												</Link>
												<p className="mb-0">
													<strong>{selectedFile.formattedSize}</strong>
												</p>
											</Col>
											<Col xs={"auto"}>
												<Button
													color="danger"
													onClick={() => setSelectedFile()}
												>
													<i className="bx bx-trash-alt" />
												</Button>
											</Col>
										</Row>
									</div>
								</Card>
							)}
							<div className="text-center mt-4">
								<Button type="submit" color="primary">
									Создать
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>

				<Modal
					isOpen={editBrandModal}
					toggle={() => {
						setEditBrandModal(!editBrandModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Изменение</h5>
						<button
							type="button"
							onClick={() => {
								setEditBrandModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal" onValidSubmit={editBrand}>
							<div className="form-group">
								{editBrandImg && (
									<CardImg
										top
										className="img-fluid"
										src={editBrandImg}
										alt="Skote"
									/>
								)}

								<Input
									fieldClasses="mt-2 mb-2	"
									name="editCategory"
									label="Наименование"
									value={editBrandName}
									onChange={e => setEditBrandName(e)}
									placeholder="Введите название бренда"
									type="text"
									error={errObj.name}
								/>
								<Input
									fieldClasses="mb-2"
									name="editCategory"
									label="url"
									value={editBrandUrl}
									onChange={e => setEditBrandUrl(transliterate(e))}
									placeholder="Введите url"
									type="text"
									error={errObj.url}
								/>
							</div>

							<Dropzone
								onDrop={acceptedFiles => {
									handleAcceptedFiles(acceptedFiles)
								}}
							>
								{({ getRootProps, getInputProps }) => (
									<div className="dropzone">
										<div className="dz-message needsclick" {...getRootProps()}>
											<input {...getInputProps()} />
											<div className="dz-message needsclick">
												<div className="mb-3">
													<i className="display-4 text-muted bx bxs-cloud-upload" />
												</div>
												<h4>Переместите логотип сюда.</h4>
											</div>
										</div>
									</div>
								)}
							</Dropzone>
							{selectedFile && (
								<Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
									<div className="p-2">
										<Row className="align-items-center">
											<Col className="col-auto">
												<img
													data-dz-thumbnail=""
													height="80"
													className="avatar-sm rounded bg-light"
													alt={selectedFile.name}
													src={selectedFile.preview}
												/>
											</Col>
											<Col>
												<Link to="#" className="text-muted font-weight-bold">
													{selectedFile.name}
												</Link>
												<p className="mb-0">
													<strong>{selectedFile.formattedSize}</strong>
												</p>
											</Col>
											<Col xs={"auto"}>
												<Button
													color="danger"
													onClick={() => setSelectedFile()}
												>
													<i className="bx bx-trash-alt" />
												</Button>
											</Col>
										</Row>
									</div>
								</Card>
							)}
							<div className="text-center mt-4">
								<Button color="primary" type="submit">
									Изменить
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этот бренд?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								type="submit"
								className="mr-2 "
								color="danger"
								onClick={() => deleteBrand()}
							>
								Да
							</Button>
							<Button
								type="submit"
								color="primary"
								onClick={() => setDeleteModal(false)}
							>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default withTranslation()(Brands)
