/* eslint-disable indent */
import { COUNT_NOTIFICATIONS, UPDATE_PROFILE } from "../types"

const initialState = {
	profile: {},
}

export const userReducer = (state = initialState, action) => {
	const { type, payload } = action

	switch (type) {
		case UPDATE_PROFILE:
			return { ...state, profile: payload }
		case COUNT_NOTIFICATIONS:
			return { ...state, notifications: payload }
		default:
			return state
	}
}
