import PropTypes from "prop-types"
import React, { memo, useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import { useHistory, useLocation } from "react-router-dom"
import getUrlParams from "../../hooks/getUrlParams"
import "./styling.scss"

const Pagination = ({ lastPage, page, onPageChange = () => {} }) => {
	const { pathname, search, hash } = useLocation()
	const history = useHistory()

	const [pagePag, setPagePag] = useState("")

	const pageChanging = e => {
		const { page } = getUrlParams()
		setPagePag(page)

		let newSearch = ""
		const newPage = e.selected + 1

		if (search.length) {
			if (page) {
				newSearch = search.replace(/page=[0-9]+/, `page=${newPage}`)
			} else {
				newSearch = `${search}&page=${newPage}`
			}
		} else {
			newSearch = `?page=${newPage}`
		}

		history.replace(`${pathname}${newSearch}${hash}`)
		onPageChange()
	}

	useEffect(() => {
		const { page } = getUrlParams()
		setPagePag(page)
	}, [window.location.href])

	useEffect(() => {
		setPagePag(page)
	}, [page])

	return (
		<div className="paginate-center">
			<ReactPaginate
				pageCount={lastPage && lastPage === 1 ? 0 : lastPage}
				pageRangeDisplayed={5}
				marginPagesDisplayed={3}
				onPageChange={pageChanging}
				activeClassName={"active"}
				containerClassName={"pagination d-inline-flex ta-center"}
				pageLinkClassName={"link"}
				pageClassName={"link"}
				previousLabel={false}
				nextLabel={false}
				forcePage={pagePag ? +pagePag - 1 : 0}
			/>
		</div>
	)
}
Pagination.propTypes = {
	lastPage: PropTypes.number,
	page: PropTypes.string,
	onPageChange: PropTypes.func,
}

export default memo(Pagination)
