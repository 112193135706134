import { apiGet, apiPost } from "api"
import AccordeonItem from "components/Accodeons/AccordeonItem"
import CharactersProduct from "components/CharactersProduct"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { Link, useHistory } from "react-router-dom"
import Select from "react-select"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	Container,
	FormGroup,
	Label,
	Modal,
	Row,
} from "reactstrap"
import "./styling.scss"

const AddProduct = () => {
	const history = useHistory()

	const { register, handleSubmit } = useForm()

	const [modalValidChar, setModalValidChar] = useState(false)
	const [errObj, setErrObj] = useState({})
	const [sending, setSending] = useState(false)
	const [charactersModal, setCharactersModal] = useState(false)
	const [clickedBrandId, setClickedBrandId] = useState("")
	const [clickedCategoryId, setClickedCategoryId] = useState("")
	const [catalogData, setCatalogData] = useState()
	const [prodId, setProdId] = useState()
	const [description, setDescription] = useState("")
	const [prodTags, setProdTags] = useState("")
	const [selectedMulti, setselectedMulti] = useState()
	const [saveBtnTags, setSaveBtnTags] = useState(false)

	const { response: brandsData } = useFetch({
		url: `/brand/index`,
	})

	const { response: catalogList } = useFetch({
		url: `/category/index`,
	})

	const { response: charactersCategory, fetchData } = useFetch({
		url: `/characteristic/index?categoryId=${clickedCategoryId}`,
	})

	const clickedTabId = id => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/category/index?categoryId=${id}`,
			})
				.then(res => {
					if (res.error) {
						return
					}
					let arr = [...catalogData]

					const recSearchIdFunc = (stack, id) => {
						stack.map(i =>
							i.id == id
								? (i.children = res.data)
								: i.children && recSearchIdFunc(i.children, id)
						)
						setCatalogData(arr)
					}
					recSearchIdFunc(arr, id)
				})
				.finally(() => setSending(false))
		}
	}

	const renderAccordeonCatalog = item => {
		return item.map(i => (
			<AccordeonItem
				key={i.id}
				blocked={i.hasSubCategories == false}
				item={i}
				clickCategory={() => setClickedCategoryId(i.id)}
				openModalAdd={() => addCategory(i.id)}
				openModalEdit={() => editCategory(i.id)}
				clickedTabId={() => clickedTabId(i.id)}
			>
				{i.children && renderAccordeonCatalog(i.children)}
			</AccordeonItem>
		))
	}

	const createProduct = data => {
		const { name, price, oldPrice } = data
		if (!sending) {
			setSending(true)
			apiPost({
				url: `product/create`,
				postData: {
					name,
					price,
					oldPrice,
					description,
					brandId: clickedBrandId,
					categoryId: clickedCategoryId,
				},
			})
				.then(res => {
					if (res.status === 406) {
						setErrObj(res.data)
						return
					} else {
						setModalValidChar(true)
						setProdId(res.data.id)
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	function handleMulti(e) {
		setselectedMulti(e)
	}

	useEffect(() => {
		if (selectedMulti?.length >= 1) {
			setSaveBtnTags(true)
		} else {
			setSaveBtnTags(false)
		}
	}, [selectedMulti])

	const renderCharactersCategory = characters => {
		if (characters) {
			return characters.models.map((i, index) => (
				<CharactersProduct
					item={i}
					key={index}
					productId={prodId}
				></CharactersProduct>
			))
		}
	}

	const saveNewTagProd = () => {
		let arr = []
		if (selectedMulti?.length) {
			selectedMulti.map(i => arr.push(i.value))
		}
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/theme-product/bind-theme/${prodId}`,
				postData: {
					ids: arr,
				},
			})
				.then(res => {
					if (!res.error) {
						setSaveBtnTags(false)
					}
				})
				.finally(() => setSending(false))
		}
	}

	const getProdTags = () => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/theme-product/index`,
			})
				.then(res => {
					setProdTags([
						{
							options: res.data.models.map(i => ({
								value: i.id,
								label: i.name,
							})),
						},
					])
				})
				.finally(() => setSending(false))
		}
	}

	useEffect(() => {
		getProdTags()
	}, [])

	useEffect(() => {
		setCatalogData(catalogList)
	}, [catalogList])

	useEffect(() => {
		fetchData()
	}, [clickedCategoryId])

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Row>
						<Col xs="12">
							<form onSubmit={handleSubmit(createProduct)}>
								<Card>
									<CardBody>
										<Row>
											<Col sm="4" className="mb-3">
												<Label>Название товара</Label>
												<Input
													name="name"
													type="text"
													register={register}
													error={errObj.name}
												/>
											</Col>
											<Col sm="4">
												<Label for="formrow-InputState">Название бренда</Label>
												<select
													className={`form-control ${
														errObj.brand_id && "form-input _error"
													}`}
													onChange={e => setClickedBrandId(e.target.value)}
												>
													<option>Выбор бренда</option>
													{brandsData &&
														brandsData.models.map(i => (
															<option key={i.id} value={i.id}>
																{i.name}
															</option>
														))}
												</select>
												<div className="form-error">
													{errObj.brand_id && errObj.brand_id}
												</div>
											</Col>
											<Col sm="2">
												<Label>Старая цена</Label>
												<Input
													name="oldPrice"
													type="text"
													register={register}
													error={errObj.old_price}
												/>
											</Col>
											<Col sm="2">
												<Label>Новая цена</Label>
												<Input
													name="price"
													type="text"
													register={register}
													error={errObj.price}
												/>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card>
									<CardBody>
										<Row>
											<Col lg={12}>
												<FormGroup>
													<Label htmlFor="productdesc">Описание товара</Label>

													<ReactQuill
														value={description}
														onChange={setDescription}
													/>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
								</Card>

								<Card>
									<CardBody>
										<Row>
											<Col sm={12}>
												<FormGroup>
													<h5> Категория товара</h5>

													{catalogData && renderAccordeonCatalog(catalogData)}

													<div className="form-error">
														{errObj.category_id && errObj.category_id}
													</div>
												</FormGroup>
											</Col>
										</Row>
										{/* {clickedCategoryId && (
											<Row>
												<Col>
													<Button
														color="success"
														className="w-100"
														onClick={e => e}
													>
														Характеристики товара
													</Button>
												</Col>
											</Row>
										)} */}
									</CardBody>
									<CardFooter>
										<Button
											type="submit"
											color="primary"
											className="mr-1 waves-effect waves-light"
										>
											Создать товар
										</Button>
									</CardFooter>
								</Card>
							</form>
						</Col>
					</Row>
				</Container>

				<Modal
					size="xl"
					isOpen={charactersModal}
					toggle={() => {
						setCharactersModal(!charactersModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Теги товара</h5>
						<Link
							to="/products"
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</Link>
					</div>
					<div className="modal-body">
						<Row>
							<Col xs={10}>
								<Select
									noOptionsMessage={() => "Нет вариантов.."}
									className="basic-multi-select"
									value={selectedMulti}
									placeholder="Выберите теги..."
									isMulti={true}
									onChange={e => {
										handleMulti(e)
									}}
									options={prodTags}
									classNamePrefix={`select-control`}
								/>
							</Col>
							<Col xs={1}>
								{saveBtnTags && (
									<Button
										style={{ height: "100%" }}
										color="success"
										className="mr-1 waves-effect waves-light "
										onClick={() => saveNewTagProd()}
									>
										<i className="bx bx-save" />
									</Button>
								)}
							</Col>
						</Row>
					</div>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Характеристики товара</h5>
					</div>
					<div className="modal-body ">
						<FormGroup>
							{charactersCategory &&
								renderCharactersCategory(charactersCategory)}
						</FormGroup>
					</div>
					<div className="modal-footer">
						<Link to="/products" className="btn btn-primary">
							Завершить
						</Link>
					</div>
				</Modal>

				<Modal
					isOpen={modalValidChar}
					toggle={() => {
						setModalValidChar(!modalValidChar)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Товар успешно создан!<br></br> Хотите ли вы добавить
							характеристики к товару?
						</h5>
						<button
							type="button"
							onClick={() => {
								setModalValidChar(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => setCharactersModal(true)}
							>
								Да
							</Button>
							<Button color="primary" onClick={() => history.goBack()}>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default AddProduct
