import { apiPost } from "api"
import Input from "components/Input"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardImg,
	CardText,
	CardTitle,
	Col,
	Modal,
	Row,
} from "reactstrap"
import updateUrl from "utils/updateUrl"
import "./styling.scss"

const News = props => {
	const { page } = useUrl()
	const [deleteModal, setDeleteModal] = useState(false)
	const [searchData, setSearchData] = useState("")
	const [deleteId, setDeleteId] = useState()
	const [sending, setSending] = useState(false)

	const { response: newsData, fetchData } = useFetch({
		url: `/news/index?page=${page || 1}&limit=9&search=${searchData}`,
	})

	const deleteNews = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/news/delete/${deleteId}`,
			})
				.then(res => {
					if (res.error) {
						return
					}
					fetchData()
					setDeleteModal(false)
				})
				.finally(() => setSending(false))
		}
	}

	useEffect(() => {
		updateUrl({ key: "page", value: "1" })
	}, [searchData])

	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("News")}</h4>
						<Link
							to="/news/add"
							type="submit"
							color="primary"
							className="btn btn-primary waves-effect waves-light mb-2"
						>
							Добавить новость
						</Link>
					</div>

					<Row className="mb-2">
						<Col>
							<div className="top-controller">
								<div className="mb-2 ">
									<Input
										placeholder="Поиск по новостям..."
										fieldClasses="search"
										onChange={setSearchData}
									/>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						{newsData?.models?.map(i => (
							<Col lg={4} key={i.id}>
								<Card>
									<CardImg
										top
										className="img-fluid"
										src={i?.banner}
										alt="Skote"
									/>
									<CardBody>
										<CardTitle className="mt-0">{i.name}</CardTitle>
										{/* <CardText>
											This is a wider card with supporting text below as a
											natural lead-in to additional content. This content is a
											little bit longer.
										</CardText> */}
										<CardText>
											<small className="text-muted">
												{new Date(i?.date * 1000).toLocaleString()}
											</small>
										</CardText>
										<Link
											to={`/news/edit/${i.permalink}`}
											color="primary"
											className="mr-1 btn btn-primary waves-effect waves-light"
										>
											<i className="bx bx-cog" />
										</Link>
										<Button
											color="danger"
											className="mr-1 waves-effect waves-light"
											onClick={() => (setDeleteModal(true), setDeleteId(i.id))}
										>
											<i className="bx bx-trash-alt" />
										</Button>
									</CardBody>
								</Card>
							</Col>
						))}
					</Row>
					<Row>
						<div className="m-auto">
							<Pagination lastPage={newsData?.lastPage} page={page} />
						</div>
					</Row>
				</div>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить эту новость?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								onClick={() => deleteNews()}
								className="mr-2 "
								color="danger"
							>
								Да
							</Button>
							<Button color="primary" onClick={() => setDeleteModal(false)}>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

News.propTypes = {
	t: PropTypes.any,
}

export default withTranslation()(News)
