import Input from "components/Input"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Modal, Row, Table } from "reactstrap"
import updateUrl from "utils/updateUrl"
import "./index.scss"

const Users = props => {
	const { page } = useUrl()

	const [deleteModal, setDeleteModal] = useState(false)
	const [searchData, setSearchData] = useState("")
	const [filterStatus, setFilterStatus] = useState("")
	const { response: dataUsers } = useFetch({
		url: `/user/index?search=${searchData}&status=${filterStatus}&page=${
			page || 1
		}&limit=15`,
	})

	useEffect(() => {
		updateUrl({ key: "page", value: "1" })
	}, [searchData, filterStatus])

	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Users")}</h4>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<Row className="mb-2">
										<Col>
											<div className="top-controller">
												<div className="mb-2 ">
													<Input
														placeholder="Поиск по фио..."
														fieldClasses="search"
														onChange={setSearchData}
													/>
												</div>
												<div className="d-flex">
													<div className="col-auto">
														<select
															onChange={e => setFilterStatus(e.target.value)}
															className="form-control"
														>
															<option value="">Все статусы</option>
															<option value="10">Активный</option>
															<option value="9">Заблокирован</option>
															<option value="0">Удален</option>
														</select>
													</div>
												</div>
											</div>
										</Col>
									</Row>

									<div className="table-responsive">
										<Table className="table mb-0">
											<thead className="thead-light">
												<tr>
													<th>#</th>
													<th>ФИО</th>
													<th>Дата регистрации</th>
													<th>Статус</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{dataUsers &&
													dataUsers?.models.map(i => (
														<tr key={i.id}>
															<th scrope="row">{i.id}</th>
															<td>
																{i.lastName || ""} {i.name || ""}{" "}
																{i.patronymic || ""}
															</td>
															<td>{i.regDate}</td>
															<td>
																{i.status == 10 && "Активный"}
																{i.status == 9 && "Заблокирован"}
																{i.status == 0 && "Удаленный"}
															</td>
															<td className="edit">
																<Link
																	to={`/user/edit/${i.id}`}
																	className="mr-1 btn btn-warning waves-effect waves-light"
																>
																	<i className="bx bx-cog" />
																</Link>
																{/* <Button
																	type="submit"
																	color="danger"
																	className="mr-1 waves-effect waves-light"
																	onClick={() => setDeleteModal(true)}
																>
																	<i className="bx bx-trash-alt" />
																</Button> */}
															</td>
														</tr>
													))}
											</tbody>
										</Table>
									</div>
								</CardBody>
							</Card>
							<Pagination lastPage={dataUsers?.lastPage} page={page} />
						</Col>
					</Row>
				</div>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этого пользователя?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button type="submit" className="mr-2 " color="danger">
								Да
							</Button>
							<Button type="submit" color="primary">
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

Users.propTypes = {
	t: PropTypes.any,
}

export default withTranslation()(Users)
