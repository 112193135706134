import { useEffect, useState } from "react"
import getUrlParams from "./getUrlParams"

const useUrl = () => {
	const [state, setState] = useState({})
	// const location = useLocation()
	// useEffect(() => {
	// 	setState(getUrlParams())
	// }, [location])
	// const location = useLocation()
	useEffect(() => {
		setState(getUrlParams())
	}, [window.location.href])

	return state
}

export default useUrl
