import { apiGet, apiPost } from "api"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AccordeonCategories from "components/Accodeons/AccordeonCategories"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Button, Card, CardBody, Col, Container, Modal, Row } from "reactstrap"
import { transliterate } from "utils/transliterate"
import "./styling.scss"

const Category = props => {
	const [dataCategory, setDataCategory] = useState()
	const [errObj, setErrObj] = useState({})
	const [indexError, setIndexError] = useState()

	const [sending, setSending] = useState(false)

	const [modalCreateOpen, setModalCreateOpen] = useState(false)

	const [сategory, setСategory] = useState("")
	const [topUrl, setTopUrl] = useState("")
	const [clickedId, setClickedId] = useState("")

	const [parentId, setParentId] = useState("")
	const [editCategoryId, setEditCategoryId] = useState("")
	const [editCategoryTitle, setEditCategoryTitle] = useState("")
	const [editCategoryUrl, setEditCategoryUrl] = useState("")
	const [openModalEditCategory, setOpenModalEditCategory] = useState(false)

	const [deleteCategoreId, setDeleteCategoryId] = useState("")
	const [deleteModal, setDeleteModal] = useState(false)

	const [modalViewCategory, setModalViewCategory] = useState(false)

	const [dataCategoryCharacters, setDataCategoryCharacters] = useState()
	const [categoryCharactersIdOpened, setCategoryCharactersIdOpened] = useState()

	const [deleteModalCharacter, setDeleteModalCharacter] = useState(false)
	const [deleteIndex, setDeleteIndex] = useState()

	const { response: catalogData } = useFetch({
		url: `/category/index`,
	})
	const createCategory = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/category/create`,
				postData: {
					parentId: clickedId,
					name: сategory,
					url: topUrl,
				},
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}

					setModalCreateOpen(false)
					setСategory("")
					setTopUrl("")
					setErrObj({})

					if (!clickedId) {
						let arr = [...dataCategory]
						arr.push({
							id: res.data.id,
							name: res.data.name,
							url: res.data.url,
							public: true,
						})
						setDataCategory(arr)

						return
					}

					let arr = [...dataCategory]
					const recSearchIdFunc = stack => {
						stack.map(i =>
							i.id == clickedId
								? ((i.hasSubCategories = true), clickedTabId(clickedId))
								: i.children && recSearchIdFunc(i.children)
						)
						setDataCategory(arr)
					}
					recSearchIdFunc(arr)
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const updateCategory = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/category/update/${editCategoryId}`,
				postData: {
					parentId: parentId,
					name: editCategoryTitle,
					url: editCategoryUrl,
				},
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}
					setOpenModalEditCategory(false)
					setErrObj({})
					setСategory("")
					setTopUrl("")

					let arr = [...dataCategory]
					const recSearchIdFunc = stack => {
						stack.map(i =>
							i.id == editCategoryId
								? ((i.name = editCategoryTitle), (i.url = editCategoryUrl))
								: i.children && recSearchIdFunc(i.children)
						)
						setDataCategory(arr)
					}
					recSearchIdFunc(arr)
				})
				.finally(() => setSending(false))
		}
	}

	const deleteCategoryRes = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/category/delete/${deleteCategoreId}`,
			})
				.then(res => {
					if (res) {
						let arr = [...dataCategory]
						const recSearchIdFunc = stack => {
							stack.map((i, index) =>
								i.id == deleteCategoreId
									? stack.splice(index, 1)
									: i.children && recSearchIdFunc(i.children)
							)
							setDataCategory(arr)
						}
						recSearchIdFunc(arr)

						setDeleteModal(false)
					}
				})
				.finally(() => setSending(false))
		}
	}

	const addCategory = id => {
		id ? setClickedId(id) : setClickedId(null)
		setModalCreateOpen(true)
	}

	const viewCategory = id => {
		setCategoryCharactersIdOpened(id)
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/characteristic/index?categoryId=${id}`,
			})
				.then(res => {
					if (res) {
						if (res.error) {
							return
						}

						setDataCategoryCharacters(res.data.models)
					}
				})
				.finally(() => setSending(false))
		}
		setModalViewCategory(true)
	}

	const editCategory = (item, parentId) => {
		setParentId(parentId)
		setOpenModalEditCategory(true)
		setEditCategoryId(item.id)
		setEditCategoryTitle(item.name)
		setEditCategoryUrl(item.url)
	}

	const deleteCategory = id => {
		setDeleteModal(true)
		setDeleteCategoryId(id)
	}

	const addToCategoryCharacterLine = () => {
		const arr = [...dataCategoryCharacters]
		arr.push({ name: "", permalink: "", created: true })
		setDataCategoryCharacters(arr)
	}

	const clickedTabId = id => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/category/index?categoryId=${id}`,
			})
				.then(res => {
					if (res.error) {
						return
					}
					let arr = [...dataCategory]

					const recSearchIdFunc = (stack, id) => {
						stack.map(i =>
							i.id == id
								? (i.children = res.data)
								: i.children && recSearchIdFunc(i.children, id)
						)
						setDataCategory(arr)
					}
					recSearchIdFunc(arr, id)
				})
				.finally(() => setSending(false))
		}
	}

	const togglePublicCategory = item => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/category/public/${item.id}`,
				postData: {
					public: item.public ? 0 : 1,
				},
			})
				.then(res => {
					const recFuncForUpdateChildrens = (i, bool) => {
						i.map(i => {
							i.public = bool
							i.children && recFuncForUpdateChildrens(i.children, bool)
						})
					}

					let arr = [...dataCategory]

					const recFuncForUpdateParents = (stack, parId) => {
						stack.map(i =>
							i.id == parId
								? ((i.public = true),
								  i.parent && recFuncForUpdateParents(arr, i.parent.id))
								: i.children && recFuncForUpdateParents(i.children, parId)
						)
					}

					const recSearchIdFunc = (stack, id) => {
						stack.map(i => {
							if (i.id == item.id) {
								i.public = !item.public
								i.children && recFuncForUpdateChildrens(i.children, item.public)
								if (i.public) {
									i.parent && recFuncForUpdateParents(arr, i.parent.id)
								}
							} else {
								i.children && recSearchIdFunc(i.children, id)
							}
						})
						setDataCategory(arr)
					}
					recSearchIdFunc(arr, item.id)
				})
				.finally(() => setSending(false))
		}
	}

	const createdCategoryCharacter = (field, index) => {
		const data = [...dataCategoryCharacters]
		const name = data[index][field]
		const permalink = data[index].permalink

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/characteristic/create/${categoryCharactersIdOpened}`,
				postData: {
					name,
					permalink,
				},
			})
				.then(res => {
					if (res.error) {
						setIndexError(index)
						setErrObj(res.data)
						return
					}
					data[index].id = res.data.id
					data[index].name = res.data.name
					data[index].edit = false
					data[index].created = false
					setErrObj({})
				})
				.finally(() => setSending(false))
		}
	}

	const updateCategoryCharacter = (field, index) => {
		const data = [...dataCategoryCharacters]
		const name = data[index][field]
		const id = data[index].id
		const permalink = data[index].permalink

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/characteristic/update/${id}`,
				postData: {
					name,
					permalink,
				},
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}
					data[index].editable = false
					data[index].created = false
				})
				.finally(() => setSending(false))
		}
	}

	const changeCategoryCharacter = (e, field, index) => {
		const data = [...dataCategoryCharacters]
		data[index][field] = e
		if (!data[index].created) {
			data[index].editable = true
		}
		data[index].permalink = transliterate(e)

		setDataCategoryCharacters(data)
	}

	const deleteChatacterFunc = index => {
		setDeleteModalCharacter(true)
		setDeleteIndex(index)
	}

	const deleteCharacter = () => {
		const data = [...dataCategoryCharacters]
		if (data[deleteIndex].created) {
			data.splice(deleteIndex, 1)
			setDataCategoryCharacters(data)
			setDeleteModalCharacter(false)
		} else {
			const id = data[deleteIndex].id

			if (!sending) {
				setSending(true)
				apiPost({
					url: `/characteristic/delete/${id}`,
					postData: {},
				})
					.then(res => {
						if (res.error) {
							return
						}
						setDeleteModalCharacter(false)

						data.splice(deleteIndex, 1)

						setDataCategoryCharacters(data)
					})
					.finally(() => setSending(false))
			}
		}
	}

	const renderAccordeonCatalog = (catList, parentId = null) => {
		if (catList) {
			return catList.map(i => (
				<AccordeonCategories
					key={i.id}
					blocked={i.hasSubCategories == false}
					item={i}
					openModalView={() => viewCategory(i.id)}
					openModalAdd={() => addCategory(i.id)}
					openModalEdit={() => editCategory(i, parentId)}
					openModalDelete={() => deleteCategory(i.id)}
					clickedTabId={() => clickedTabId(i.id)}
					togglePublic={() => togglePublicCategory(i)}
				>
					{i.children && renderAccordeonCatalog(i.children, i.id)}
				</AccordeonCategories>
			))
		}
	}

	useEffect(() => {
		setErrObj({})
	}, [modalCreateOpen])

	useEffect(() => {
		setDataCategory(catalogData)
	}, [catalogData])

	useEffect(() => {
		setTopUrl(transliterate(сategory))
	}, [сategory])

	useEffect(() => {
		setEditCategoryUrl(transliterate(editCategoryTitle))
	}, [editCategoryTitle])

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Category")}</h4>
						<Button
							color="primary"
							className="btn btn-primary waves-effect waves-light"
							onClick={() => addCategory()}
						>
							Создать главную категорию
						</Button>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									{dataCategory && renderAccordeonCatalog(dataCategory)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
				<Modal
					isOpen={modalCreateOpen}
					toggle={() => {
						setModalCreateOpen(!modalCreateOpen)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Создание категории</h5>
						<button
							type="button"
							onClick={() => {
								setModalCreateOpen(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal">
							<div className="form-group">
								<Input
									name="name"
									label="Название категории"
									value={сategory}
									onChange={e => setСategory(e)}
									placeholder="Введите название категории"
									type="text"
									fieldClasses="mb-2"
									error={errObj.name}
								/>
								<Input
									name="topUrl"
									label="Url"
									value={topUrl}
									onChange={e => setTopUrl(transliterate(e))}
									placeholder="Введите url категории"
									type="text"
									error={errObj.url}
									fieldClasses="mb-2"
								/>
							</div>
							<div className="text-center mt-4">
								<Button type="submit" color="primary" onClick={createCategory}>
									Создать категорию
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>
				<Modal
					isOpen={openModalEditCategory}
					toggle={() => {
						setOpenModalEditCategory(!openModalEditCategory)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Редактирование</h5>
						<button
							type="button"
							onClick={() => {
								setOpenModalEditCategory(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal">
							<div className="form-group">
								<Input
									name="editCategory"
									label="Наименование"
									value={editCategoryTitle}
									onChange={e => setEditCategoryTitle(e)}
									placeholder="Введите название категории"
									type="text"
									fieldClasses="mb-2"
									error={errObj.name}
								/>
								<Input
									name="editUrl"
									label="Url"
									value={editCategoryUrl}
									onChange={e => setEditCategoryUrl(transliterate(e))}
									placeholder="Введите url категории"
									type="text"
									fieldClasses="mb-2"
									error={errObj.url}
								/>
							</div>
							<div className="text-center mt-4">
								<Button color="primary" onClick={() => updateCategory()}>
									Изменить категорию
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>

				<Modal
					isOpen={modalViewCategory}
					toggle={() => {
						setModalViewCategory(!modalViewCategory)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Просмотр характеристик</h5>
						<button
							type="button"
							onClick={() => {
								setModalViewCategory(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>

					<div className="modal-body">
						{dataCategoryCharacters &&
							dataCategoryCharacters.map((i, index) => (
								<Row className="mb-2" key={index}>
									<Col>
										<Input
											name="editCategory"
											value={i.name}
											onChange={e => changeCategoryCharacter(e, "name", index)}
											placeholder="Введите название категории"
											type="text"
											error={indexError == index && errObj.name}
										/>
									</Col>
									<Col>
										<Input
											name="editCategory"
											value={i.permalink}
											onChange={e =>
												changeCategoryCharacter(e, "permalink", index)
											}
											placeholder="Введите url категории"
											type="text"
											error={indexError == index && errObj.permalink}
										/>
									</Col>
									{i.created && (
										<Col xs={"auto"}>
											<Button
												style={{ height: "100%" }}
												color="primary"
												className="mr-1 waves-effect waves-light "
												onClick={() => createdCategoryCharacter("name", index)}
											>
												<i className="bx bx-save" />
											</Button>
										</Col>
									)}
									{i.editable && (
										<Col xs={"auto"}>
											<Button
												style={{ height: "100%" }}
												color="success"
												className="mr-1 waves-effect waves-light "
												onClick={() => updateCategoryCharacter("name", index)}
											>
												<i className="bx bx-save" />
											</Button>
										</Col>
									)}
									<Col xs={"auto"}>
										<Button
											style={{ height: "100%" }}
											type="submit"
											color="danger"
											className="mr-1 waves-effect waves-light "
											onClick={() => deleteChatacterFunc(index)}
										>
											<i className="bx bx-trash-alt" />
										</Button>
									</Col>
								</Row>
							))}
					</div>
					<div className="modal-footer">
						<div className="text-center mt-4">
							<Button
								color={"primary"}
								onClick={() => addToCategoryCharacterLine()}
							>
								Добавить характеристику
							</Button>
						</div>
					</div>
				</Modal>

				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить эту категорию?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={deleteCategoryRes}
							>
								Да
							</Button>
							<Button color="primary" onClick={() => setDeleteModal(false)}>
								Нет
							</Button>
						</div>
					</div>
				</Modal>

				<Modal
					isOpen={deleteModalCharacter}
					toggle={() => {
						setDeleteModalCharacter(!deleteModalCharacter)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить категорию?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModalCharacter(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deleteCharacter()}
							>
								Да
							</Button>
							<Button
								color="primary"
								onClick={() => {
									setDeleteModalCharacter(false)
								}}
							>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default withTranslation()(Category)
