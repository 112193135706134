//Import Product Images
import { apiGet, apiPost } from "api"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import "nouislider/distribute/nouislider.css"
import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import Dropzone from "react-dropzone"
import { withTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import Select from "react-select"
import {
	Button,
	Card,
	CardBody,
	CardTitle,
	Col,
	Container,
	Form,
	Label,
	Modal,
	Row,
} from "reactstrap"
import "./styling.scss"

const ProductVersionList = props => {
	const { id } = useParams()

	const { response: dataProductVersion, fetchData } = useFetch({
		url: `/product-version/index/${id}`,
	})

	const { response: dataColors } = useFetch({
		url: `color/index`,
	})

	const [editedSizes, setEditedSizes] = useState([])
	const [dataSizes, setDataSizes] = useState([])
	const [clickedColorId, setClickedColorId] = useState()
	const [selectedFiles, setSelectedFiles] = useState([])
	const [sending, setSending] = useState(false)
	const [errObj, setErrObj] = useState()

	const [selectedMulti, setselectedMulti] = useState()

	const [dataVersion, setDataVersion] = useState("")
	const [versionImages, setVersionImages] = useState()

	const [addVerionModal, setAddVersionModal] = useState(false)
	const [editVersionModal, setEditVersionModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [deleteId, setDeleteId] = useState("")

	const getDataSizes = () => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/size/index`,
			})
				.then(res => {
					setDataSizes([
						{ options: res.data.map(i => ({ value: i.id, label: i.name })) },
					])
				})
				.finally(() => setSending(false))
		}
	}

	const deleteFunc = id => {
		setDeleteModal(true)
		setDeleteId(id)
	}

	const deleteVersionProduct = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/product-version/delete/${deleteId}`,
			})
				.then(res => {
					if (res?.error) {
						return
					}
					fetchData()
					setEditVersionModal(false)
					setDeleteModal(false)
				})
				.finally(() => setSending(false))
		}
	}

	function handleMulti(e) {
		setselectedMulti(e)
	}

	const handleOnDragEnd = result => {
		if (!result.destination) return
		const items = Array.from(selectedFiles)
		const [reorderItem] = items.splice(result.source.index, 1)
		items.splice(result.destination.index, 0, reorderItem)

		setSelectedFiles(items)
	}

	const handleDragEndImages = result => {
		if (!result.destination) return
		const items = Array.from(versionImages)
		const [reorderItem] = items.splice(result.source.index, 1)
		items.splice(result.destination.index, 0, reorderItem)

		if (!sending) {
			setSending(true)

			let arr = items.map(i => {
				return i.id
			})

			apiPost({
				url: `/product-version/sort-image`,
				postData: {
					productId: dataVersion.id,
					sort: arr,
				},
			})
				.then(res => {
					if (res?.error) {
						console.error("Ошибка перемещения")
						return
					}
				})
				.finally(() => {
					setSending(false)
				})
		}

		setVersionImages(items)
	}

	const openEditModalVersion = item => {
		let arr2 = dataSizes[0].options
		let arr1 = item.sizes

		const arr = arr2.filter(i1 => !arr1.find(i2 => i1.value == i2.id))

		setEditedSizes(arr)
		setDataVersion(item)
		setVersionImages(item.images)

		setEditVersionModal(true)
	}

	function formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return "0 Bytes"
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
	}

	const handleAcceptedFiles = files => {
		files.map(file =>
			Object.assign(file, {
				preview: URL.createObjectURL(file),
				formattedSize: formatBytes(file.size),
			})
		)

		setSelectedFiles([...selectedFiles, ...files])
	}

	const createVersionProduct = () => {
		let sizes = []

		if (selectedMulti?.length) {
			sizes = selectedMulti.map(i => i.value)
		}

		if (!sending) {
			setSending(true)
			let formData = new FormData()
			formData.append("product_id", id)
			formData.append("color_id", clickedColorId)
			if (sizes) {
				sizes.forEach((f, index) => formData.append(`sizes[${index}]`, f))
			} else {
				formData.append("sizes", new Array())
			}

			if (selectedFiles) {
				selectedFiles.forEach((f, index) =>
					formData.append(`images[${index}]`, f)
				)
			}

			apiPost({
				url: `/product-version/create`,
				postData: formData,
			})
				.then(res => {
					if (res.status === 406) {
						setErrObj(res.data)
						return
					}
					setAddVersionModal(false)
					fetchData()
					setSelectedFiles([])
					setClickedColorId([])
					setselectedMulti([])
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const deleteImg = id => {
		if (!sending) {
			apiPost({
				url: `/document/delete/${id}`,
				postData: {},
			})
				.then(res => {
					if (res.status === 406) {
						return
					}
					let arr = { ...dataVersion }

					arr.images.map((i, index) => {
						if (i.id === id) {
							arr.images.splice(index, 1)
							setDataVersion(arr)
							fetchData()
						}
					})
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const deleteSize = (idSize, index) => {
		if (!sending) {
			apiPost({
				url: `/product-version/delete-size?id=${dataVersion.id}&sizeId=${idSize}`,
				postData: {},
			})
				.then(res => {
					if (res.status === 406) {
						return
					}
					let obj = { ...dataVersion }
					obj.sizes.splice(index, 1)
					setDataVersion(obj)
					fetchData()
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const updateVersion = () => {
		let sizes = []

		if (selectedMulti?.length) {
			sizes = selectedMulti.map(i => i.value)
		}

		if (!sending) {
			let formData = new FormData()

			if (sizes) {
				sizes.forEach((f, index) => formData.append(`sizes[${index}]`, f))
			}
			if (selectedFiles) {
				selectedFiles.forEach((f, index) =>
					formData.append(`images[${index}]`, f)
				)
			}

			apiPost({
				url: `/product-version/update/${dataVersion.id}`,
				postData: formData,
			})
				.then(res => {
					if (res.status === 406) {
						return
					}
					setselectedMulti()
					setSelectedFiles([])
					setEditVersionModal(false)
					fetchData()
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const deleteItemImage = index => {
		let arr = [...selectedFiles]
		arr.splice(index, 1)
		setSelectedFiles(arr)
	}

	useEffect(() => {
		getDataSizes()
	}, [])

	useEffect(() => {
		setselectedMulti()
	}, [addVerionModal])

	useEffect(() => {
		setselectedMulti()
	}, [editVersionModal])

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Версии товара")}</h4>

						<div>
							<Link
								to={`/entrance/${id}`}
								color="success"
								className="btn btn-success waves-effect waves-light mr-2"
							>
								Поступление
							</Link>
							<Link
								to={`/write-downs/${id}`}
								color="warning"
								className="btn btn-warning waves-effect waves-light mr-2"
								onClick={() => setAddVersionModal(true)}
							>
								Списание
							</Link>
							<Button
								color="primary"
								className="btn btn-primary waves-effect waves-light"
								onClick={() => setAddVersionModal(true)}
							>
								{props.t("Add verison")}
							</Button>
						</div>
					</div>
					<Row>
						<Col lg="12">
							<Row>
								{dataProductVersion &&
									dataProductVersion.map(product => (
										<Col xl="2" sm="6" key={product.id} className="mb-4">
											<Card
												style={{ cursor: "pointer", height: "100%" }}
												onClick={() => openEditModalVersion(product)}
											>
												<CardBody>
													<div className="product-img position-relative">
														<img
															src={product.images[0]?.thumbnail || ""}
															alt=""
															className="img-fluid mx-auto d-block"
														/>
													</div>
													<div className="mt-4 text-center">
														<h5 className="mb-3 text-truncate">
															{product.name}
														</h5>
													</div>
													<div className="mt-4">
														<span className="mb-3 text-truncate">
															{product.sizes.map((i, index) => (
																<span key={i.name + index} className="d-block">
																	{i.name} : {i.quantity}
																</span>
															))}
														</span>
													</div>
												</CardBody>
											</Card>
										</Col>
									))}
							</Row>
						</Col>
					</Row>
				</Container>
				<Modal
					size="xl"
					isOpen={addVerionModal}
					toggle={() => {
						setAddVersionModal(!addVerionModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Создание</h5>
						<button
							type="button"
							onClick={() => {
								setAddVersionModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<Row className="mb-2">
							<Col>
								<select
									className="form-control"
									onChange={e => setClickedColorId(e.target.value)}
								>
									<option>Выберите цвет</option>
									{dataColors &&
										dataColors.map(i => (
											<option key={i.id} value={i.id}>
												{i.name}
											</option>
										))}
								</select>
							</Col>
							<Col>
								<Select
									noOptionsMessage={() => "Нет вариантов.."}
									className="basic-multi-select"
									value={selectedMulti}
									placeholder="Выберите размеры..."
									isMulti={true}
									onChange={e => {
										handleMulti(e)
									}}
									options={dataSizes}
									classNamePrefix={`select-control`}
								/>
							</Col>
						</Row>

						<CardTitle className="mb-3">Фотографии товара</CardTitle>
						<Form>
							<Dropzone
								onDrop={acceptedFiles => {
									handleAcceptedFiles(acceptedFiles)
								}}
							>
								{({ getRootProps, getInputProps }) => (
									<div className="dropzone">
										<div className="dz-message needsclick" {...getRootProps()}>
											<input {...getInputProps()} />
											<div className="dz-message needsclick">
												<div className="mb-3">
													<i className="display-4 text-muted bx bxs-cloud-upload" />
												</div>
												<h4>Переместите фотографии сюда.</h4>
											</div>
										</div>
									</div>
								)}
							</Dropzone>
							<div className="dropzone-previews mt-3" id="file-previews">
								<DragDropContext onDragEnd={handleOnDragEnd}>
									<Droppable droppableId="selectedFiles">
										{provided => (
											<ul {...provided.droppableProps} ref={provided.innerRef}>
												{selectedFiles?.map((f, index) => (
													<Draggable
														key={index + "-file"}
														draggableId={index + ""}
														index={index}
													>
														{provided => (
															<div
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																ref={provided.innerRef}
															>
																<Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
																	<div className="p-2">
																		<Row className="align-items-center">
																			<Col className="col-auto">
																				<img
																					height="80"
																					className="avatar-sm rounded bg-light"
																					src={f.preview}
																				/>
																			</Col>
																			<Col>
																				{f.name}
																				<p className="mb-0">
																					<strong>{f.formattedSize}</strong>
																				</p>
																			</Col>
																			<Col xs={"auto"}>
																				<Button
																					color="danger"
																					onClick={() => deleteItemImage(index)}
																				>
																					<i className="bx bx-trash-alt" />
																				</Button>
																			</Col>
																		</Row>
																	</div>
																</Card>
															</div>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</ul>
										)}
									</Droppable>
								</DragDropContext>
							</div>
						</Form>
					</div>
					<div className="modal-footer">
						<Button color="primary" onClick={() => createVersionProduct()}>
							Создать
						</Button>
					</div>
				</Modal>

				<Modal
					size="xl"
					isOpen={editVersionModal}
					toggle={() => {
						setEditVersionModal(!editVersionModal)
						fetchData()
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Изменение версии</h5>
						<button
							type="button"
							onClick={() => {
								setEditVersionModal(false)
								fetchData()
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<Row>
							<Col>
								<Label>Размеры версии товара</Label>
							</Col>
						</Row>

						<Row>
							{dataVersion?.sizes?.map((i, index) => (
								<Col xs={2} className="d-flex mb-2 mt-2" key={i.id}>
									<Col>
										<Input defaultValue={i.name} readOnly />
									</Col>
									<Col sm={"auto"}>
										<Button
											color="danger"
											style={{ height: "100%" }}
											onClick={() => deleteSize(i?.id, index)}
										>
											<i className="bx bx-trash-alt" />
										</Button>
									</Col>
								</Col>
							))}
						</Row>
						<Row className="mt-2 mb-2">
							<Col>
								<Label>Добавить размеры</Label>
								<Select
									className="basic-multi-select"
									value={selectedMulti}
									isMulti={true}
									placeholder="Выберите размеры..."
									onChange={e => {
										handleMulti(e)
									}}
									options={editedSizes}
									classNamePrefix={"select-control"}
								/>
							</Col>
						</Row>
						<Row className="mb-4 mt-4">
							<div className="dropzone-previews mt-3" id="file-preview">
								<DragDropContext onDragEnd={handleDragEndImages}>
									<Droppable droppableId="versionImages" direction="horizontal">
										{provided => (
											<ul
												{...provided.droppableProps}
												ref={provided.innerRef}
												className="d-flex"
											>
												{versionImages?.map((i, index) => (
													<Draggable
														key={index + "-file"}
														draggableId={index + ""}
														index={index}
													>
														{provided => (
															<div
																className="pos mr-2"
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																ref={provided.innerRef}
															>
																<img
																	src={i.thumbnail}
																	alt=""
																	className="w-100"
																/>
																<Button
																	className="btn-delete-version"
																	color="danger"
																	onClick={() => deleteImg(i.id)}
																>
																	<i className="bx bx-trash-alt" />
																</Button>
															</div>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</ul>
										)}
									</Droppable>
								</DragDropContext>
							</div>
							{/* {versionImages &&
								versionImages.map((i, index) => (
									<Col key={i.id} sm="4" className="mb-4">
										<div className="pos">
											<img src={i.thumbnail} alt="" className="w-100" />
											<Button
												className="btn-delete-version"
												color="danger"
												onClick={() => deleteImg(i.id)}
											>
												<i className="bx bx-trash-alt" />
											</Button>
										</div>
									</Col>
								))} */}
						</Row>
						<CardTitle className="mb-3">Фотографии товара</CardTitle>
						<Form>
							<Dropzone
								onDrop={acceptedFiles => {
									handleAcceptedFiles(acceptedFiles)
								}}
							>
								{({ getRootProps, getInputProps }) => (
									<div className="dropzone">
										<div className="dz-message needsclick" {...getRootProps()}>
											<input {...getInputProps()} />
											<div className="dz-message needsclick">
												<div className="mb-3">
													<i className="display-4 text-muted bx bxs-cloud-upload" />
												</div>
												<h4>Переместите фотографии сюда.</h4>
											</div>
										</div>
									</div>
								)}
							</Dropzone>
							<div className="dropzone-previews mt-3" id="file-previews">
								<DragDropContext onDragEnd={handleOnDragEnd}>
									<Droppable droppableId="selectedFiles">
										{provided => (
											<ul {...provided.droppableProps} ref={provided.innerRef}>
												{selectedFiles?.map((f, index) => (
													<Draggable
														key={index + "-file"}
														draggableId={index + ""}
														index={index}
													>
														{provided => (
															<div
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																ref={provided.innerRef}
															>
																<Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
																	<div className="p-2">
																		<Row className="align-items-center">
																			<Col className="col-auto">
																				<img
																					data-dz-thumbnail=""
																					height="80"
																					className="avatar-sm rounded bg-light"
																					alt={f.name}
																					src={f.preview}
																				/>
																			</Col>
																			<Col>
																				{f.name}

																				<p className="mb-0">
																					<strong>{f.formattedSize}</strong>
																				</p>
																			</Col>
																			<Col xs={"auto"}>
																				<Button
																					color="danger"
																					onClick={() => deleteItemImage(index)}
																				>
																					<i className="bx bx-trash-alt" />
																				</Button>
																			</Col>
																		</Row>
																	</div>
																</Card>
															</div>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</ul>
										)}
									</Droppable>
								</DragDropContext>
							</div>
						</Form>
					</div>
					<div className="modal-footer">
						<Button color="primary" onClick={() => updateVersion()}>
							Изменить
						</Button>
						<Button color="danger" onClick={() => deleteFunc(dataVersion?.id)}>
							Удалить
						</Button>
					</div>
				</Modal>

				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить эту версию товара?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deleteVersionProduct()}
							>
								Да
							</Button>
							<Button color="primary" onClick={() => setDeleteModal(false)}>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default withTranslation()(ProductVersionList)
