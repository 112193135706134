import { apiPost } from "api"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Modal, Row, Table } from "reactstrap"
import "./styling.scss"

const PickUpPoint = props => {
	const { page } = useUrl()

	const { response: pickUpData, fetchData } = useFetch({
		url: `/points/index?page=${page || 1}&limit=15`,
	})

	const [deleteModal, setDeleteModal] = useState(false)
	const [deleteId, setDeleteId] = useState("")
	const [sending, setSending] = useState(false)

	const deletePickUp = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/points/delete/${deleteId}`,
				postData: {},
			})
				.then(res => {
					if (res.status === 406) {
						return
					}
					setDeleteModal(false)
					fetchData()
				})
				.finally(() => {
					setSending(false)
				})
		}
	}
	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Pick-up point")}</h4>
						<Link className="btn btn-primary" to="/pick-up-point/create">
							Добавить пункт выдачи
						</Link>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<div className="table-responsive">
										<Table className="table mb-0">
											<thead className="thead-light">
												<tr>
													<th>#</th>
													<th>Пункт выдачи</th>
													<th>Адрес</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{pickUpData &&
													pickUpData?.models.map(i => (
														<tr key={i.id}>
															<th scope="row">{i.id}</th>
															<td>{i.name}</td>
															<td>{i.address}</td>
															<td className="edit">
																<Link
																	to={`/pick-up-point/edit/${i.id}`}
																	type="submit"
																	className="mr-1 btn btn-warning waves-effect waves-light"
																>
																	<i className="bx bx-cog" />
																</Link>
																<Button
																	type="submit"
																	color="danger"
																	className="mr-1 waves-effect waves-light"
																	onClick={() => (
																		setDeleteModal(true), setDeleteId(i.id)
																	)}
																>
																	<i className="bx bx-trash-alt" />
																</Button>
															</td>
														</tr>
													))}
											</tbody>
										</Table>
									</div>
									<Row>
										<Col lg="12">
											<Pagination lastPage={pickUpData?.lastPage} page={page} />
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить пункт выдачи?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deletePickUp()}
							>
								Да
							</Button>
							<Button color="primary" onClick={() => setDeleteModal(false)}>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

PickUpPoint.propTypes = {
	t: PropTypes.any,
}

export default withTranslation()(PickUpPoint)
