import PropTypes from "prop-types"
import React from "react"
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Table,
} from "reactstrap"
import img4 from "../../assets/images/product/img-4.png"
import img7 from "../../assets/images/product/img-7.png"

const EcommerceOrdersModal = props => {
	const orderData = {
		surname: "Алексеев",
		name: "Андрей",
		lastname: "Михайлович",
		date: "27.12.2020 12:57",
		statusText: "Выдано",
		pickUpPoint: "г.Казань, Кремлевская д.57",
		number: "#321321",
		subTotal: "$400",
		shipping: "$20",
		total: "$420",
		items: [
			{
				id: 0,
				imgUrl: img4,
				name: "Wireless Headphone (Black)",
				price: "$225",
				col: "2",
				totalPrice: "$450",
			},
			{
				id: 1,
				imgUrl: img7,
				name: "Wireless Headphone (Black)",
				price: "$225",
				col: "1",
				totalPrice: "$450",
			},
			{
				id: 2,
				imgUrl: img4,
				name: "Wireless Headphone (Black)",
				price: "$225",
				col: "2",
				totalPrice: "$450",
			},
			{
				id: 3,
				imgUrl: img7,
				name: "Wireless Headphone (Black)",
				price: "$225",
				col: "1",
				totalPrice: "$450",
			},
		],
	}
	const { data, isOpen, toggle } = props

	return (
		<Modal
			isOpen={isOpen}
			role="dialog"
			autoFocus={true}
			centered={true}
			className="exampleModal"
			tabIndex="-1"
			toggle={toggle}
		>
			<div className="modal-content">
				<ModalHeader toggle={toggle}>Детали заказа</ModalHeader>
				<ModalBody>
					<p className="mb-2">
						ФИО:{" "}
						<span className="text-primary">
							{data?.user?.lastName} {data?.user?.name} {data?.user?.patronymic}
						</span>
					</p>
					<p className="mb-2">
						Номер: <span className="text-primary">{data?.user?.phone}</span>
					</p>
					<p className="mb-2">
						Почта: <span className="text-primary">{data?.user?.email}</span>
					</p>
					<p className="mb-2">
						Дата заказа:{" "}
						<span className="text-primary">{data?.order?.createdAt}</span>
					</p>
					<p className="mb-2">
						Статус заказа:{" "}
						<span className="text-primary">{data?.order?.status?.text}</span>
					</p>
					<p className="mb-2">
						Пункт выдачи:{" "}
						<span className="text-primary">
							{data?.delivery?.full}"{data?.point?.name}" {data?.point?.country}{" "}
							{data?.point?.city} {data?.point?.street} {data?.point?.house}
						</span>
					</p>
					<p className="mb-2">
						ID заказа: <span className="text-primary">{data?.order?.num}</span>
					</p>

					<div className="table-responsive">
						<Table className="table table-centered table-nowrap">
							<thead>
								<tr>
									<th scope="col">Товар</th>
									<th scope="col">Название товара</th>
									<th scope="col">Цена</th>
								</tr>
							</thead>
							<tbody>
								{data?.productList.map(i => (
									<tr key={i?.id}>
										<th scope="row">
											<div>
												<img
													src={i?.image[0].thumbnail}
													alt=""
													className="avatar-sm"
												/>
											</div>
										</th>
										<td>
											<div>
												<h5 className="text-truncate font-size-14">
													{i?.parent?.name} ({i?.product?.name})
												</h5>
												<p className="text-muted mb-0">
													{i?.parent?.price} x {i?.availability?.quantity}
												</p>
											</div>
										</td>
										<td> {i?.parent?.price * i?.availability?.quantity}</td>
									</tr>
								))}
								<tr>
									<td colSpan="2">
										<h6 className="m-0 text-right">Общая цена:</h6>
									</td>
									<td>{data?.order?.price?.total}</td>
								</tr>
								{data?.order?.price?.delivery && (
									<tr>
										<td colSpan="2">
											<h6 className="m-0 text-right">Доставка:</h6>
										</td>
										<td>{data?.order?.price?.delivery}</td>
									</tr>
								)}
								{data?.order?.price?.promocode && (
									<tr>
										<td colSpan="2">
											<h6 className="m-0 text-right">Промокод:</h6>
										</td>
										<td>{data?.order?.price?.promocode?.code}</td>
										<td>{data?.order?.price?.promocode?.saleValue}</td>
									</tr>
								)}

								<tr>
									<td colSpan="2">
										<h6 className="m-0 text-right">Цена со скидкой:</h6>
									</td>
									<td>{data?.order?.price?.priceWithSale}</td>
								</tr>
								<tr>
									<td colSpan="2">
										<h6 className="m-0 text-right">Итого:</h6>
									</td>
									<td>{data?.order?.price?.finishPrice}</td>
								</tr>
							</tbody>
						</Table>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button type="button" color="secondary" onClick={toggle}>
						Закрыть
					</Button>
				</ModalFooter>
			</div>
		</Modal>
	)
}

EcommerceOrdersModal.propTypes = {
	toggle: PropTypes.func,
	isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
