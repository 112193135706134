import PropTypes from "prop-types"
import React from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import SidebarContent from "./SidebarContent"

const Sidebar = props => {
	return (
		<div className="vertical-menu">
			<div data-simplebar className="h-100">
				<SidebarContent />
			</div>
		</div>
	)
}

Sidebar.propTypes = {
	type: PropTypes.string,
}

const mapStatetoProps = state => {
	return {
		layout: state.Layout,
	}
}
export default connect(
	mapStatetoProps,
	{}
)(withRouter(withTranslation()(Sidebar)))
