import { apiPost } from "api"
import Input from "components/Input"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import Select from "react-select"
import { Button, Col, Row } from "reactstrap"
import "./styling.scss"

const CharactersProductEdit = ({ item, productId }) => {
	const [selectedMulti, setSelectedMulti] = useState([])
	const [editable, setEditable] = useState(false)
	const [charType, setChatType] = useState(1)
	const [text, setText] = useState("")
	const [sending, setSending] = useState(false)
	const [errObj, setErrObj] = useState({})
	const [options, setOptions] = useState([])

	function handleMulti(e) {
		setSelectedMulti(e)
		setEditable(true)
	}

	const saveCharactersForProduct = () => {
		let arr = []
		let characteristicValue
		if (charType == 1) {
			selectedMulti.map(f => arr.push(f.value))
			characteristicValue = arr
		} else characteristicValue = text

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/product-characteristic/create`,
				postData: {
					productId,
					characteristicId: item.id,
					characteristicType: charType,
					characteristicValue,
				},
			})
				.then(res => {
					if (res.status === 406) {
						setErrObj(res.data)
						return
					} else {
						setEditable(false), setErrObj({})
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	useEffect(() => {
		setEditable(true)
	}, [text])

	useEffect(() => {
		setEditable(false)
	}, [])

	useEffect(() => {
		let arr = [...options]
		let idArr = [...selectedMulti]
		item?.characteristicList &&
			item?.characteristicList.map(f => {
				arr.push({ value: f.id, label: f.name })
				setOptions(arr)
				item?.value &&
					item?.value.map(
						i =>
							i == f.id &&
							(idArr.push({ value: f.id, label: f.name }),
							setSelectedMulti(idArr))
					)
			})
	}, [item])

	useEffect(() => {
		{
			item.characteristicList?.length >= 1 ? setChatType(1) : setChatType(0)
		}
	}, [item])

	return (
		<Row className="mb-3">
			<Col md={5}>
				<Input
					id="price"
					name="price"
					defaultValue={item.name}
					onChange={e => e}
					type="text"
					disabled
				/>
			</Col>

			<Col md={5}>
				{item?.characteristicList?.length ? (
					<Select
						className="basic-multi-select"
						value={selectedMulti}
						noOptionsMessage={() => "Нет вариантов.."}
						placeholder="Выберите характеристики..."
						isMulti={true}
						onChange={e => {
							handleMulti(e)
						}}
						options={options}
						classNamePrefix={`select-control`}
					/>
				) : (
					<Input
						onChange={e => setText(e)}
						error={errObj.characteristic_value}
						defaultValue={item.value}
					/>
				)}
			</Col>

			<Col xs={"auto"}>
				{editable && (
					<Button
						style={{ height: "100%" }}
						color="success"
						className="mr-1 waves-effect waves-light "
						onClick={() => saveCharactersForProduct()}
					>
						<i className="bx bx-save" />
					</Button>
				)}
			</Col>
		</Row>
	)
}

export default withTranslation()(CharactersProductEdit)
