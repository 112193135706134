import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import App from "./App"
import "./i18n"
import { store } from "./redux/store"
import * as serviceWorker from "./serviceWorker"

const app = (
	<Provider store={store}>
		<App />
	</Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
