import { apiPost } from "api"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import { useHistory, useParams } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	Container,
	FormGroup,
	Label,
	Modal,
	Row,
} from "reactstrap"
import "./styling.scss"

const EditReview = () => {
	const { id } = useParams()
	const history = useHistory()

	const { response: getReviewData } = useFetch({
		url: `reviews/view/${id}`,
	})

	const [answerText, setAnswerText] = useState("")
	const [photoIndex, setphotoIndex] = useState(0)
	const [isGallery, setisGallery] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [errObj, setErrObj] = useState({})

	const answerReview = () => {
		apiPost({
			url: `reviews/answer/${id}`,
			postData: {
				text: answerText,
			},
		}).then(res => {
			if (res.status === 406) {
				setErrObj(res.data)
				return
			}
			setAnswerText("")
			history.goBack()
		})
	}

	const deleteReview = () => {
		apiPost({
			url: `reviews/delete/${id}`,
		}).then(res => {
			if (res.status === 406) {
				setErrObj(res.data)
				return
			}
			history.goBack()
		})
	}

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>Редактирование отзыва</h4>
					</div>

					{isGallery ? (
						<Lightbox
							mainSrc={getReviewData?.images[photoIndex].path}
							// nextSrc={getReviewData?.images[photoIndex + 1].path}
							// prevSrc={getReviewData?.images[photoIndex - 1].path}
							enableZoom={true}
							onCloseRequest={() => {
								setisGallery(false)
							}}
							onMovePrevRequest={() => {
								setphotoIndex(
									(photoIndex + getReviewData?.images.length - 1) %
										getReviewData?.images.length
								)
							}}
							onMoveNextRequest={() => {
								setphotoIndex((photoIndex + 1) % getReviewData?.images.length)
							}}
							imageCaption={"Project " + parseFloat(photoIndex + 1)}
						/>
					) : null}

					<Row>
						<Col xs="12">
							<Card>
								<CardBody>
									<Row>
										<Col sm="12">
											<Row>
												<Col sm="4" className="mb-3">
													<Label>Фамилия</Label>
													<Input
														disabled
														defaultValue={getReviewData?.user?.lastName}
														type="text"
													/>
												</Col>
												<Col sm="4" className="mb-3">
													<Label>Имя</Label>
													<Input
														disabled
														defaultValue={getReviewData?.user?.name}
														type="text"
													/>
												</Col>
												<Col sm="4" className="mb-3">
													<Label>Отчество</Label>
													<Input
														disabled
														defaultValue={getReviewData?.user?.patronymic}
														type="text"
													/>
												</Col>
												<Col sm="4" className="mb-3">
													<Label>Email</Label>
													<Input
														disabled
														defaultValue={getReviewData?.user?.email}
														type="text"
													/>
												</Col>
												<Col sm="4" className="mb-3">
													<Label>Номер телефона</Label>
													<Input
														disabled
														defaultValue={getReviewData?.user?.phone}
														type="text"
													/>
												</Col>
											</Row>
											<FormGroup>
												<Label htmlFor="productdesc">Отзыв</Label>
												<textarea
													disabled
													className="form-control"
													id="productdesc"
													rows="5"
													defaultValue={getReviewData?.text}
												/>
											</FormGroup>
											<FormGroup>
												<Label htmlFor="productdesc">Преимущества товара</Label>
												<textarea
													disabled
													className="form-control"
													id="productdesc"
													rows="5"
													defaultValue={getReviewData?.pros}
												/>
											</FormGroup>
											<FormGroup>
												<Label htmlFor="productdesc">Недостатки товара</Label>
												<textarea
													disabled
													className="form-control"
													id="productdesc"
													rows="5"
													defaultValue={getReviewData?.cons}
												/>
											</FormGroup>
											{getReviewData?.images.length > 0 && (
												<>
													<Label htmlFor="productdesc" className="mt-2">
														Фотографии отзыва
													</Label>
													<Row>
														{getReviewData?.images.map((i, index) => (
															<Col xs="auto" key={i.id}>
																<img
																	src={i.path}
																	alt=""
																	className="img-fluid mx-auto d-block img-page-content"
																	onClick={() => {
																		setisGallery(true)
																		setphotoIndex(index)
																	}}
																/>
															</Col>
														))}
													</Row>
												</>
											)}
											{getReviewData?.answer.length > 0 &&
												getReviewData?.answer.map(i => (
													<FormGroup key={i.id}>
														<Label htmlFor="productdesc" className="mt-2">
															Ответ на отзыв
														</Label>
														<textarea
															className="form-control"
															id="productdesc"
															rows="5"
															disabled
															defaultValue={i.text}
														/>
													</FormGroup>
												))}

											<FormGroup>
												<Label htmlFor="productdesc" className="mt-2">
													Ответить на отзыв
												</Label>
												<textarea
													className={`form-control ${errObj.text && "_error"}`}
													id="productdesc"
													rows="5"
													onChange={e => setAnswerText(e.target.value)}
													value={answerText}
												/>
											</FormGroup>
											{errObj?.text && (
												<span style={{ color: "#c72626" }}>{errObj?.text}</span>
											)}
										</Col>
									</Row>
								</CardBody>
							</Card>

							<Card>
								<CardFooter>
									<Button
										color="primary"
										className="mr-1 waves-effect waves-light"
										onClick={() => answerReview()}
									>
										Опубликовать отзыв
									</Button>
									<Button
										onClick={() => setDeleteModal(true)}
										color="danger"
										className="waves-effect"
									>
										Удалить отзыв
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</Container>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этот отзыв?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								onClick={() => deleteReview()}
								className="mr-2 "
								color="danger"
							>
								Да
							</Button>
							<Button color="primary" onClick={() => setDeleteModal(false)}>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default EditReview
