import { apiPost } from "api"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Table,
} from "reactstrap"
import updateUrl from "utils/updateUrl"
import "./styling.scss"

const PromoCode = props => {
	const { page } = useUrl()

	const [search, setSearch] = useState("")
	const [deleteModal, setDeleteModal] = useState(false)
	const [promoCodesData, setPromoCodesData] = useState()
	const [deleteId, setDeleteId] = useState("")
	const [sending, setSending] = useState(false)
	const { response, fetchData } = useFetch({
		url: `/promocode/index?page=${page || 1}&limit=15&search=${search}`,
	})

	const deletePromo = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/promocode/delete/${deleteId}`,
			})
				.then(res => {
					if (res.error) {
						return
					}
					fetchData()
					setDeleteModal(false)
				})
				.finally(() => setSending(false))
		}
	}

	useEffect(() => {
		if (response) {
			setPromoCodesData(response.models)
		}
	}, [response])

	useEffect(() => {
		updateUrl({ key: "page", value: "1" })
	}, [search])

	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Promocodes")}</h4>
						<Link to="/promo-code/create" className="btn btn-primary">
							Создать промокод
						</Link>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<Row className="mb-3">
										<Col>
											<Form className="mt-4 mt-sm-0  form-inline">
												<div className="search-box mr-2">
													<div className="position-relative">
														<Input
															type="text"
															className="form-control border-0"
															placeholder={props.t("Search") + "..."}
															onChange={e => setSearch(e.target.value)}
														/>
														<i className="bx bx-search-alt search-icon" />
													</div>
												</div>
											</Form>
										</Col>
									</Row>

									<div className="table-responsive">
										<Table className="table mb-0">
											<thead className="thead-light">
												<tr>
													<th>#</th>
													<th>Название промокода</th>
													<th>Промокод</th>
													<th>Скидка</th>
													<th>Действие промокода</th>
													<th>Категории</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{promoCodesData &&
													promoCodesData.map(i => (
														<tr key={i.id}>
															<th>{i.id}</th>
															<td>{i.name}</td>
															<td>{i.code}</td>
															<td>
																{i.sale.value}
																{i.sale.type == 1 && "%"}
															</td>
															<td>
																{i.date.start} - {i.date.end}
															</td>
															<td style={{ maxWidth: 150 }}>
																{i.categoryList.map((i, index) => (
																	<span key={index} className="d-block">
																		{i.name}
																	</span>
																)) || " "}
															</td>
															<td className="edit">
																<Link
																	to={`/promo-code/edit/${i.id}`}
																	color="warning"
																	className="mr-1 btn btn-warning waves-effect waves-light"
																>
																	<i className="bx bx-cog" />
																</Link>
																<Button
																	color="danger"
																	className="mr-1 waves-effect waves-light"
																	onClick={() => (
																		setDeleteModal(true), setDeleteId(i.id)
																	)}
																>
																	<i className="bx bx-trash-alt" />
																</Button>
															</td>
														</tr>
													))}
											</tbody>
										</Table>
									</div>
									<Row>
										<Col lg="12">
											<Pagination lastPage={response?.lastPage} page={page} />
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этот промокод?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deletePromo()}
							>
								Да
							</Button>
							<Button color="primary" onClick={() => setDeleteModal(false)}>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

PromoCode.propTypes = {
	t: PropTypes.any,
}

export default withTranslation()(PromoCode)
