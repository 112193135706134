import { apiPost } from "api"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AccordeonQustions from "components/Accodeons/AccordeonQustions"
import Input from "components/Input"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import ReactQuill from "react-quill"
import { useParams } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	Col,
	FormGroup,
	Label,
	Modal,
	Row,
} from "reactstrap"

const FaqQuestions = props => {
	const { id, permalink } = useParams()
	const [modal, setModal] = useState({
		create: false,
		edit: false,
		delete: false,
	})
	const { page } = useUrl()
	const [question, setQuestion] = useState("")
	const [answer, setAnswer] = useState("")
	const [errObj, setErrObj] = useState({})

	const { response: faqQuestionsData, fetchData } = useFetch({
		url: `faq-question/index?page=${page || 1}&limit=10&permalink=${permalink}`,
	})

	const createQuestion = () => {
		apiPost({
			url: `/faq-question/create`,
			postData: {
				question,
				answer,
				themeId: id,
			},
		}).then(res => {
			if (res?.error) {
				setErrObj(res.data)
				return
			}
			setErrObj({})
			setQuestion("")
			setAnswer("")
			setModal(s => ({ ...s, create: false }))
			fetchData()
		})
	}
	return (
		<>
			{" "}
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Список тем Faq")}</h4>
						<Button
							color="primary"
							className="btn btn-primary waves-effect waves-light mb-2"
							onClick={() => setModal(s => ({ ...s, create: !s.create }))}
						>
							Добавить вопрос
						</Button>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									{faqQuestionsData?.models.map(i => (
										<Row key={i.id}>
											<Col xs={12}>
												<AccordeonQustions
													key={i.id}
													item={i}
													refresh={() => fetchData()}
												/>
											</Col>
										</Row>
									))}
								</CardBody>
							</Card>
							<Pagination lastPage={faqQuestionsData?.lastPage} page={page} />
						</Col>
					</Row>
				</div>
				<Modal
					isOpen={modal.create}
					toggle={() => setModal(s => ({ ...s, create: !s.create }))}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Создание темы частых вопросов</h5>
						<button
							type="button"
							onClick={() => setModal(s => ({ ...s, create: false }))}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal">
							<div className="form-group">
								<Input
									label="Вопрос"
									value={question}
									onChange={e => setQuestion(e)}
									placeholder="Введите вопрос..."
									type="text"
									fieldClasses="mb-2"
									error={errObj.question}
								/>
								<FormGroup>
									<Label
										style={{ paddingLeft: 15, fontWeight: 400, fontSize: 14 }}
									>
										Ответ
									</Label>

									<ReactQuill value={answer} onChange={setAnswer} />
								</FormGroup>
								{/* <Input
									label="Ответ"
									value={answer}
									onChange={e => setAnswer(e)}
									placeholder="Введите ответ..."
									type="text"
									error={errObj.answer}
									fieldClasses="mb-2"
								/> */}
							</div>
							<div className="text-center mt-4">
								<Button type="submit" color="primary" onClick={createQuestion}>
									Создать вопрос
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default withTranslation()(FaqQuestions)
