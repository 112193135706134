import languages from "common/languages"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Dropdown, DropdownItem, DropdownMenu } from "reactstrap"
//i18n
import i18n from "../../../i18n"

const LanguageDropdown = () => {
	const [selectedLang, setSelectedLang] = useState("")
	const [menu, setMenu] = useState(false)

	useEffect(() => {
		const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
		setSelectedLang(currentLanguage)
	}, [])

	const changeLanguageAction = lang => {
		//set language as i18n
		i18n.changeLanguage(lang)
		localStorage.setItem("I18N_LANGUAGE", lang)
		setSelectedLang(lang)
	}

	const toggle = () => {
		setMenu(!menu)
	}

	return (
		<>
			<Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
				{/* <DropdownToggle className="btn header-item waves-effect" tag="button">
					<img
						src={languages[`${selectedLang}`]?.flag}
						alt="Skote"
						height="16"
						className="mr-1"
					/>
					<span className="align-middle">
						{languages[`${selectedLang}`]?.label}
					</span>
				</DropdownToggle> */}
				<DropdownMenu className="language-switch" right>
					{Object.keys(languages).map(key => (
						<DropdownItem
							key={key}
							onClick={() => changeLanguageAction(key)}
							className={`notify-item ${
								selectedLang === key ? "active" : "none"
							}`}
						>
							<img
								src={languages[`${key}`]?.flag}
								alt="Skote"
								className="mr-1"
								height="12"
							/>
							<span className="align-middle">{languages[`${key}`]?.label}</span>
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		</>
	)
}

export default withTranslation()(LanguageDropdown)
