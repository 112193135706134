// availity-reactstrap-validation
import { apiGet, apiPost } from "api"
import { AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
// Redux
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Input from "../../components/Input"
import { appLogin } from "../../redux/actions/app"
import { updateProfile } from "../../redux/actions/user"

// actions

const Login = props => {
	const dispatch = useDispatch()

	const [sending, setSending] = useState(false)
	const [errObj, setErrObj] = useState({})

	const { register, handleSubmit } = useForm()

	const authUser = data => {
		const { username, password } = data

		if (!sending) {
			setSending(true)

			apiPost({
				url: "/auth/login-admin",
				postData: {
					username,
					password,
				},
			})
				.then(authRes => {
					if (authRes.status === 406) {
						setErrObj(authRes.data)
						return
					}
					setErrObj({})

					apiGet({
						url: `/user/profile`,
						headerList: {
							Authorization: `Bearer ${authRes.data.access_token}`,
						},
					}).then(res => {
						setSending(false)
						dispatch(updateProfile(authRes.data.profile))
						dispatch(appLogin(authRes.data.access_token))
					})
				})
				.finally(() => setSending(false))
		}
	}

	return (
		<>
			<div className="home-btn d-none d-sm-block">
				<Link to="/" className="text-dark">
					<i className="fas fa-home h2" />
				</Link>
			</div>
			<div className="account-pages my-5 pt-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5}>
							<Card>
								<CardBody>
									<div className="p-2">
										<AvForm
											className="form-horizontal"
											onValidSubmit={handleSubmit(authUser)}
										>
											<div className="form-group">
												<Input
													register={register}
													name="username"
													label="Email"
													placeholder="Email"
													type="email"
													fieldClasses="mb-2"
													error={errObj.username}
												/>
												<Input
													register={register}
													name="password"
													label="Password"
													type="password"
													fieldClasses="mb-2"
													placeholder="Password"
													error={errObj.password}
												/>
												{/* <AvField
													innerRef={register}
													name="username"
													label="Email"
													// value="admin@themesbrand.com"
													className="form-control"
													placeholder="Email"
													type="email"
													required
													invalid={(errObj.username && true) || false}
												/> */}
											</div>

											{/* <div className="form-group">
												<AvField
													innerRef={register}
													name="password"
													label="Password"
													// value="123456"
													type="password"
													required
													placeholder="Password"
													invalid={(errObj.password && true) || false}
												/>
											</div> */}

											{/* <div className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													id="customControlInline"
												/>
												<label
													className="custom-control-label"
													htmlFor="customControlInline"
												>
													Remember me
												</label>
											</div> */}

											<div className="mt-3">
												<button
													className="btn btn-primary btn-block waves-effect waves-light"
													type="submit"
												>
													Войти
												</button>
											</div>
											{/* <div className="mt-4 text-center">
												<Link to="/forgot-password" className="text-muted">
													<i className="mdi mdi-lock mr-1" />
													Forgot your password?
												</Link>
											</div> */}
										</AvForm>
									</div>
								</CardBody>
							</Card>
							{/* <div className="mt-5 text-center">
								<p>
									Don&#39;t have an account ?{" "}
									<Link
										to="register"
										className="font-weight-medium text-primary"
									>
										{" "}
										Signup now{" "}
									</Link>{" "}
								</p>
							</div> */}
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default Login
