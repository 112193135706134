import React from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Card, CardBody, Col, Input, Row } from "reactstrap"
import "./style.scss"

const Profile = props => {
	const state = useSelector(state => state)
	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Profile")}</h4>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<Row>
										<Col md={4}>
											<Input defaultValue={state.user.profile?.username} />
										</Col>
										<Col md={4}>
											<Input defaultValue={state.user.profile?.email} />
										</Col>
										<Col md={4}>
											<Input defaultValue={state.user.profile?.phone} />
										</Col>
									</Row>
									<Row className="mt-3">
										<Col md={4}>
											<Input defaultValue={state.user.profile?.name} />
										</Col>
										<Col md={4}>
											<Input defaultValue={state.user.profile?.patronymic} />
										</Col>
										<Col md={4}>
											<Input defaultValue={state.user.profile?.lastName} />
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}

export default withTranslation()(Profile)
