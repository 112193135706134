import { apiGet, apiPost } from "api"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Input from "components/Input"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Collapse,
	Container,
	Modal,
	Row,
} from "reactstrap"
import { transliterate } from "utils/transliterate"

const Additionally = props => {
	const [sending, setSending] = useState(false)

	const [errObj, setErrObj] = useState({})

	const [colorData, setColorData] = useState()
	const [sizeData, setSizeData] = useState()
	const [tagData, setTagData] = useState()
	const [tagProdData, setTagProdData] = useState()

	const [modalCreateSize, setModalCreateSize] = useState(false)
	const [modalCreateColor, setModalCreateColor] = useState(false)
	const [modalCreateTag, setModalCreateTag] = useState(false)
	const [modalCreateProdTag, setModalCreateProdTag] = useState(false)

	const [accSizes, setAccSizes] = useState(false)
	const [accColors, setAccColors] = useState(false)
	const [accTag, setAccTag] = useState(false)
	const [accProdTag, setAccProdTag] = useState(false)

	const [createColor, setCreateColor] = useState("")
	const [createSize, setCreateSize] = useState("")
	const [createTag, setCreateTag] = useState("")
	const [createTagUrl, setCreateTagUrl] = useState("")
	const [createProdTag, setCreateProdTag] = useState("")
	const [createProdTagUrl, setCreateProdTagUrl] = useState("")

	const [modalDeleteSize, setModalDeleteSize] = useState(false)
	const [modalDeleteColor, setModalDeleteColor] = useState(false)
	const [modalDeleteTag, setModalDeleteTag] = useState(false)
	const [modalDeleteProdTag, setModalDeleteProdTag] = useState(false)

	const [deleteIndex, setDeleteIndex] = useState("")

	const getColorData = () => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/color/index`,
			})
				.then(res => {
					if (res.error) {
						return
					}

					setColorData(res.data)
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const getSizeData = () => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/size/index`,
			})
				.then(res => {
					if (res.error) {
						return
					}

					setSizeData(res.data)
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const getTagData = () => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/theme/index`,
			})
				.then(res => {
					if (res.error) {
						return
					}

					setTagData(res.data.models)
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const getTagProdData = () => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/theme-product/index?`,
			})
				.then(res => {
					if (res.error) {
						return
					}

					setTagProdData(res.data.models)
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const changeTagLine = (e, field, index) => {
		const data = [...tagData]
		data[index][field] = e
		data[index].edit = true
		data[index].permalink = transliterate(e)

		setTagData(data)
	}

	const changeTagProdData = (e, field, index) => {
		const data = [...tagProdData]
		data[index][field] = e
		data[index].edit = true
		data[index].permalink = transliterate(e)

		setTagProdData(data)
	}

	const updateTag = (field, index) => {
		const data = [...tagData]
		const name = data[index][field]
		const permalink = data[index].permalink
		const id = data[index].id

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/theme/update/${id}`,
				postData: {
					name,
					permalink,
				},
			})
				.then(res => {
					if (res.error) {
						return
					}
					data[index].edit = false
				})
				.finally(() => setSending(false))
		}
	}

	const updateProdTag = (field, index) => {
		const data = [...tagProdData]
		const name = data[index][field]
		const permalink = data[index].permalink
		const id = data[index].id

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/theme-product/update/${id}`,
				postData: {
					name,
					permalink,
				},
			})
				.then(res => {
					if (res.error) {
						return
					}
					data[index].edit = false
				})
				.finally(() => setSending(false))
		}
	}

	//Размеры
	// const addItemSizesLine = () => {
	// 	// setSizesLineArr([...sizesLineArr, { key: "" }])
	// }

	const changeSizeLine = (e, field, index) => {
		const data = [...sizeData]
		data[index][field] = e
		data[index].edit = true

		setSizeData(data)
	}

	const updateSize = (field, index) => {
		const data = [...sizeData]
		const value = data[index][field]
		const id = data[index].id

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/size/update/${id}`,
				postData: {
					value,
				},
			})
				.then(res => {
					if (res.error) {
						return
					}
					data[index].edit = false
				})
				.finally(() => setSending(false))
		}
	}

	const deleteSize = () => {
		const data = [...sizeData]
		const id = data[deleteIndex].id

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/size/delete/${id}`,
				postData: {},
			})
				.then(res => {
					if (res.error) {
						return
					}
					setModalDeleteSize(false)
					getSizeData()
				})
				.finally(() => setSending(false))
		}
	}

	const deleteTag = () => {
		const data = [...tagData]
		const id = data[deleteIndex].id

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/theme/delete/${id}`,
				postData: {},
			})
				.then(res => {
					if (res.error) {
						return
					}
					setModalDeleteTag(false)
					getTagData()
				})
				.finally(() => setSending(false))
		}
	}

	const deleteProdTag = () => {
		const data = [...tagProdData]
		const id = data[deleteIndex].id

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/theme-product/delete/${id}`,
				postData: {},
			})
				.then(res => {
					if (res.error) {
						return
					}
					setModalDeleteProdTag(false)
					getTagProdData()
				})
				.finally(() => setSending(false))
		}
	}

	const addItemSizesLine = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/size/create`,
				postData: {
					value: createSize,
				},
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}

					setModalCreateSize(false)
					setModalCreateSize("")
					getSizeData()
					setErrObj({})
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const addItemTagLine = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/theme/create`,
				postData: {
					name: createTag,
					permalink: createTagUrl,
				},
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}

					setModalCreateTag(false)
					setCreateTag("")
					setCreateTagUrl("")
					getTagData()
					setErrObj({})
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const addItemTagProdDataLine = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/theme-product/create`,
				postData: {
					name: createProdTag,
					permalink: createProdTagUrl,
				},
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}

					setModalCreateProdTag(false)
					setCreateProdTag("")
					setCreateProdTagUrl("")
					getTagProdData()
					setErrObj({})
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const addItemColorLine = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/color/create`,
				postData: {
					value: createColor,
				},
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}

					setModalCreateColor(false)
					setCreateColor("")
					getColorData()
					setErrObj({})
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const changeColorLine = (e, field, index) => {
		const data = [...colorData]
		data[index][field] = e
		data[index].edit = true

		setColorData(data)
	}

	const updateColor = (field, index) => {
		const data = [...colorData]
		const value = data[index][field]
		const id = data[index].id

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/color/update/${id}`,
				postData: {
					value,
				},
			})
				.then(res => {
					if (res.error) {
						return
					}
					data[index].edit = false
				})
				.finally(() => setSending(false))
		}
	}

	const deleteColor = () => {
		const data = [...colorData]
		const id = data[deleteIndex].id

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/color/delete/${id}`,
				postData: {},
			})
				.then(res => {
					if (res.error) {
						return
					}
					setModalDeleteColor(false)
					getColorData()
				})
				.finally(() => setSending(false))
		}
	}

	const deleteColorFunc = index => {
		setDeleteIndex(index)
		setModalDeleteColor(true)
	}

	const deleteProdTagDataFunc = index => {
		setDeleteIndex(index)
		setModalDeleteProdTag(true)
	}

	const deleteSizeFunc = index => {
		setDeleteIndex(index)
		setModalDeleteSize(true)
	}

	const deleteTagFunc = index => {
		setDeleteIndex(index)
		setModalDeleteTag(true)
	}

	useEffect(() => {
		getColorData()
		getSizeData()
		getTagData()
		getTagProdData()
	}, [])

	useEffect(() => {
		setErrObj({})
	}, [modalCreateColor, modalCreateSize, modalCreateTag, modalCreateProdTag])

	useEffect(() => {
		setCreateTagUrl(transliterate(createTag))
	}, [createTag])

	useEffect(() => {
		setCreateProdTagUrl(transliterate(createProdTag))
	}, [createProdTag])

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Additionally")}</h4>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<Row>
										<Col xs={12}>
											<div id="accordion">
												<CardHeader
													className="p-3"
													id="headingOne"
													onClick={e => {
														e.target.id == "headingOne" &&
															setAccSizes(!accSizes)
													}}
													style={{ cursor: "pointer" }}
												>
													<div
														id="headingOne"
														className="d-flex justify-content-between ai-center"
													>
														<h6 className="m-0 font-14" id="headingOne">
															Размеры
														</h6>
														<div className="btn-group btn-group-sm">
															<Button
																color="primary"
																className="mr-1"
																onClick={() => setModalCreateSize(true)}
															>
																<i className="bx bx-plus" />
															</Button>
														</div>
													</div>
												</CardHeader>

												<Collapse isOpen={accSizes}>
													<CardBody>
														{sizeData &&
															sizeData.map((i, index) => (
																<Row className="mb-2" key={i.id}>
																	<Col>
																		<Input
																			id="price"
																			value={i.name}
																			name="price"
																			onChange={e =>
																				changeSizeLine(e, "name", index)
																			}
																			type="text"
																		/>
																	</Col>

																	{i.edit && (
																		<Col xs={"auto"}>
																			<Button
																				style={{ height: "100%" }}
																				type="submit"
																				color="success"
																				className="mr-1 waves-effect waves-light "
																				onClick={() =>
																					updateSize("name", index)
																				}
																			>
																				<i className="bx bx-save" />
																			</Button>
																		</Col>
																	)}

																	<Col xs={"auto"}>
																		<Button
																			style={{ height: "100%" }}
																			type="submit"
																			color="danger"
																			className="mr-1 waves-effect waves-light "
																			onClick={() => deleteSizeFunc(index)}
																		>
																			<i className="bx bx-trash-alt" />
																		</Button>
																	</Col>
																</Row>
															))}
													</CardBody>
												</Collapse>

												<CardHeader
													className="p-3"
													id="headingOne"
													onClick={e => {
														e.target.id == "headingOne" &&
															setAccColors(!accColors)
													}}
													style={{ cursor: "pointer" }}
												>
													<div
														id="headingOne"
														className="d-flex justify-content-between ai-center"
													>
														<h6 className="m-0 font-14" id="headingOne">
															Цвета
														</h6>
														<div className="btn-group btn-group-sm">
															<Button
																color="primary"
																className="mr-1"
																onClick={() => setModalCreateColor(true)}
															>
																<i className="bx bx-plus" />
															</Button>
														</div>
													</div>
												</CardHeader>

												<Collapse isOpen={accColors}>
													<CardBody>
														{colorData &&
															colorData.map((i, index) => (
																<Row className="mb-2" key={i.id}>
																	<Col>
																		<Input
																			id="color"
																			value={i.name}
																			name="color"
																			onChange={e =>
																				changeColorLine(e, "name", index)
																			}
																			type="text"
																		/>
																	</Col>

																	{i.edit && (
																		<Col xs={"auto"}>
																			<Button
																				style={{ height: "100%" }}
																				color="success"
																				className="mr-1 waves-effect waves-light "
																				onClick={() =>
																					updateColor("name", index)
																				}
																			>
																				<i className="bx bx-save" />
																			</Button>
																		</Col>
																	)}

																	<Col xs={"auto"}>
																		<Button
																			style={{ height: "100%" }}
																			color="danger"
																			className="mr-1 waves-effect waves-light "
																			onClick={() => deleteColorFunc(index)}
																		>
																			<i className="bx bx-trash-alt" />
																		</Button>
																	</Col>
																</Row>
															))}
													</CardBody>
												</Collapse>

												<CardHeader
													className="p-3"
													id="headingOne"
													onClick={e => {
														e.target.id == "headingOne" && setAccTag(!accTag)
													}}
													style={{ cursor: "pointer" }}
												>
													<div
														id="headingOne"
														className="d-flex justify-content-between ai-center"
													>
														<h6 className="m-0 font-14" id="headingOne">
															Теги новостейы
														</h6>
														<div className="btn-group btn-group-sm">
															<Button
																color="primary"
																className="mr-1"
																onClick={() => setModalCreateTag(true)}
															>
																<i className="bx bx-plus" />
															</Button>
														</div>
													</div>
												</CardHeader>

												<Collapse isOpen={accTag}>
													<CardBody>
														{tagData &&
															tagData.map((i, index) => (
																<Row className="mb-2" key={i.id}>
																	<Col>
																		<Input
																			id="color"
																			value={i.name}
																			name="color"
																			onChange={e =>
																				changeTagLine(e, "name", index)
																			}
																			type="text"
																		/>
																	</Col>

																	<Col>
																		<Input
																			id="color"
																			value={i.permalink}
																			name="color"
																			onChange={e =>
																				changeTagLine(e, "permalink", index)
																			}
																			type="text"
																		/>
																	</Col>

																	{i.edit && (
																		<Col xs={"auto"}>
																			<Button
																				style={{ height: "100%" }}
																				color="success"
																				className="mr-1 waves-effect waves-light "
																				onClick={() => updateTag("name", index)}
																			>
																				<i className="bx bx-save" />
																			</Button>
																		</Col>
																	)}

																	<Col xs={"auto"}>
																		<Button
																			style={{ height: "100%" }}
																			color="danger"
																			className="mr-1 waves-effect waves-light "
																			onClick={() => deleteTagFunc(index)}
																		>
																			<i className="bx bx-trash-alt" />
																		</Button>
																	</Col>
																</Row>
															))}
													</CardBody>
												</Collapse>

												<CardHeader
													className="p-3"
													id="headingOne"
													onClick={e => {
														e.target.id == "headingOne" &&
															setAccProdTag(!accProdTag)
													}}
													style={{ cursor: "pointer" }}
												>
													<div
														id="headingOne"
														className="d-flex justify-content-between ai-center"
													>
														<h6 className="m-0 font-14" id="headingOne">
															Теги товаров
														</h6>
														<div className="btn-group btn-group-sm">
															<Button
																color="primary"
																className="mr-1"
																onClick={() => setModalCreateProdTag(true)}
															>
																<i className="bx bx-plus" />
															</Button>
														</div>
													</div>
												</CardHeader>

												<Collapse isOpen={accProdTag}>
													<CardBody>
														{tagProdData &&
															tagProdData.map((i, index) => (
																<Row className="mb-2" key={i.id}>
																	<Col>
																		<Input
																			id="color"
																			value={i.name}
																			name="color"
																			onChange={e =>
																				changeTagProdData(e, "name", index)
																			}
																			type="text"
																		/>
																	</Col>

																	<Col>
																		<Input
																			id="color"
																			value={i.permalink}
																			name="color"
																			onChange={e =>
																				changeTagProdData(e, "permalink", index)
																			}
																			type="text"
																		/>
																	</Col>

																	{i.edit && (
																		<Col xs={"auto"}>
																			<Button
																				style={{ height: "100%" }}
																				color="success"
																				className="mr-1 waves-effect waves-light "
																				onClick={() =>
																					updateProdTag("name", index)
																				}
																			>
																				<i className="bx bx-save" />
																			</Button>
																		</Col>
																	)}

																	<Col xs={"auto"}>
																		<Button
																			style={{ height: "100%" }}
																			color="danger"
																			className="mr-1 waves-effect waves-light "
																			onClick={() =>
																				deleteProdTagDataFunc(index)
																			}
																		>
																			<i className="bx bx-trash-alt" />
																		</Button>
																	</Col>
																</Row>
															))}
													</CardBody>
												</Collapse>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>

				<Modal
					isOpen={modalCreateSize}
					toggle={() => {
						setModalCreateSize(!modalCreateSize)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Создание</h5>
						<button
							type="button"
							onClick={() => {
								setModalCreateSize(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal">
							<div className="form-group">
								<Input
									name="editCategory"
									label="Наименование"
									onChange={e => setCreateSize(e)}
									placeholder="Введите название размера"
									type="text"
									error={errObj.name}
									required
								/>
							</div>
							<div className="text-center mt-4">
								<Button color="primary" onClick={() => addItemSizesLine()}>
									Создать
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>

				<Modal
					isOpen={modalCreateColor}
					toggle={() => {
						setModalCreateColor(!modalCreateColor)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Создание</h5>
						<button
							type="button"
							onClick={() => {
								setModalCreateColor(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal">
							<div className="form-group">
								<Input
									name="editCategory"
									label="Наименование"
									onChange={e => setCreateColor(e)}
									error={errObj.name}
									placeholder="Введите название цвета"
									type="text"
									required
								/>
							</div>
							<div className="text-center mt-4">
								<Button color="primary" onClick={() => addItemColorLine()}>
									Создать
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>

				<Modal
					isOpen={modalCreateTag}
					toggle={() => {
						setModalCreateTag(!modalCreateTag)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Создание</h5>
						<button
							type="button"
							onClick={() => {
								setModalCreateTag(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal">
							<div className="form-group">
								<Input
									label="Наименование"
									onChange={e => setCreateTag(e)}
									placeholder="Название тега"
									type="text"
									error={errObj.name}
									required
								/>
							</div>
							<div className="form-group">
								<Input
									label="Url"
									onChange={e => setCreateTagUrl(transliterate(e))}
									placeholder="Url тега"
									type="text"
									value={createTagUrl}
									error={errObj.name}
									required
								/>
							</div>
							<div className="text-center mt-4">
								<Button color="primary" onClick={() => addItemTagLine()}>
									Создать
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>

				<Modal
					isOpen={modalCreateProdTag}
					toggle={() => {
						setModalCreateProdTag(!modalCreateProdTag)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Создание</h5>
						<button
							type="button"
							onClick={() => {
								setModalCreateProdTag(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal">
							<div className="form-group">
								<Input
									label="Наименование"
									onChange={e => setCreateProdTag(e)}
									placeholder="Название тега"
									type="text"
									error={errObj.name}
									required
								/>
							</div>
							<div className="form-group">
								<Input
									label="Url"
									onChange={e => setCreateProdTagUrl(transliterate(e))}
									placeholder="Url тега"
									type="text"
									value={createProdTagUrl}
									error={errObj.name}
									required
								/>
							</div>
							<div className="text-center mt-4">
								<Button
									color="primary"
									onClick={() => addItemTagProdDataLine()}
								>
									Создать
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>

				<Modal
					isOpen={modalDeleteColor}
					toggle={() => {
						setModalDeleteColor(!modalDeleteColor)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этот цвет?
						</h5>
						<button
							type="button"
							onClick={() => {
								setModalDeleteColor(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deleteColor()}
							>
								Да
							</Button>
							<Button
								color="primary"
								onClick={() => setModalDeleteColor(false)}
							>
								Нет
							</Button>
						</div>
					</div>
				</Modal>

				<Modal
					isOpen={modalDeleteTag}
					toggle={() => {
						setModalDeleteTag(!modalDeleteTag)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этот тег?
						</h5>
						<button
							type="button"
							onClick={() => {
								setModalDeleteTag(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deleteTag()}
							>
								Да
							</Button>
							<Button color="primary" onClick={() => setModalDeleteTag(false)}>
								Нет
							</Button>
						</div>
					</div>
				</Modal>

				<Modal
					isOpen={modalDeleteProdTag}
					toggle={() => {
						setModalDeleteProdTag(!modalDeleteProdTag)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этот тег?
						</h5>
						<button
							type="button"
							onClick={() => {
								setModalCreateProdTag(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deleteProdTag()}
							>
								Да
							</Button>
							<Button
								color="primary"
								onClick={() => setModalDeleteProdTag(false)}
							>
								Нет
							</Button>
						</div>
					</div>
				</Modal>

				<Modal
					isOpen={modalDeleteSize}
					toggle={() => {
						setModalDeleteSize(!modalDeleteSize)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этот размер?
						</h5>
						<button
							type="button"
							onClick={() => {
								setModalDeleteSize(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deleteSize()}
							>
								Да
							</Button>
							<Button
								color="primary"
								onClick={() => {
									setModalDeleteSize(false)
								}}
							>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default withTranslation()(Additionally)
