import PropTypes from "prop-types"
import React, { useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Card, CardBody, CardHeader, Collapse } from "reactstrap"
import "./styling.scss"

const AccordeonItem = ({
	children,
	item,
	blocked,
	key,
	clickCategory,
	clickedTabId,
	checkedPrev,
}) => {
	const [opened, setOpened] = useState(false)

	const openAccordeon = () => {
		setOpened(!opened)
	}

	return (
		<>
			<div id="accordion">
				<Card className="mb-0">
					<CardHeader
						className={`${
							!blocked && "clicked"
						} p-3 card-header d-flex head-acc`}
						id="headingThree"
						onClick={e => {
							!blocked && e.target.id == "headingThree" && openAccordeon(),
								clickedTabId(e)
						}}
					>
						<div className="form-check">
							<input
								className="form-check-input"
								type="radio"
								name="exampleRadios"
								checked={checkedPrev}
								onChange={e => e}
								id={`exampleRadios${key}`}
								onClick={
									(() => {
										setOpened(false)
									},
									clickCategory)
								}
							/>
						</div>
						{item.name}
					</CardHeader>
					<Collapse isOpen={opened}>
						<Card>
							<CardBody className="content-acc">{children}</CardBody>
						</Card>
					</Collapse>{" "}
				</Card>
			</div>
		</>
	)
}

AccordeonItem.propTypes = {
	name: PropTypes.any,
}

export default withTranslation()(AccordeonItem)
