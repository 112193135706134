// layouts Format
import { apiGet } from "api"
import NonAuthLayout from "components/NonAuthLayout"
import VerticalLayout from "components/VerticalLayout"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { Spinner } from "reactstrap"
import { appLogin, appLogout } from "redux/actions/app"
import { updateProfile } from "redux/actions/user"
import { authRoutes, userRoutes } from "routes/allRoutes"
import { checkUserAuth } from "utils/checkAuth"
// Import scss
import "./assets/scss/theme.scss"
import "./index.scss"

const App = () => {
	const state = useSelector(state => state)
	const dispatch = useDispatch()

	const [isLoading, setLoading] = useState(true)

	useEffect(() => {
		checkUserAuth().then(authRes => {
			if (authRes) {
				apiGet({
					url: `/user/profile`,
					headerList: {
						Authorization: `Bearer ${authRes.authToken}`,
					},
				}).then(res => {
					if (res.error) {
						console.error("Получение прав пользователя")
						return
					}
					dispatch(updateProfile(authRes.userData))
					dispatch(appLogin(authRes.authToken))
				})
			} else {
				dispatch(appLogout())
			}

			setTimeout(() => {
				setLoading(false)
			}, 300)
		})
	}, [])

	if (isLoading) {
		return (
			<div className="load ta-center">
				<Spinner color="primary" className="m-auto" />
			</div>
		)
	}

	return (
		<Router>
			{state.app.authToken ? (
				<VerticalLayout>
					{userRoutes.map((route, idx) => (
						<Route
							path={route.path}
							component={route.component}
							key={idx}
							exact
						/>
					))}
				</VerticalLayout>
			) : (
				<NonAuthLayout>
					{authRoutes.map((route, idx) => (
						<Route
							path={route.path}
							component={route.component}
							key={idx}
							exact
						/>
					))}
				</NonAuthLayout>
			)}
		</Router>
	)
}

export default App
