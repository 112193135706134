import { apiPost } from "api"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory, useParams } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	Container,
	FormGroup,
	Row,
} from "reactstrap"
import "./style.scss"

const Entrance = () => {
	const { id } = useParams()

	const history = useHistory()
	const [errAlert, setErrAlert] = useState(false)
	const [errText, setErrText] = useState("")
	const [dataVersions, setDataVersions] = useState([])
	const { response } = useFetch({
		url: `/product-version/index/${id}`,
	})

	const funcUpdateArr = (prodId, sizeId, quantity) => {
		let arr = [...dataVersions]

		arr.map(i => {
			if (i.version_id === prodId) {
				i.sizes.map(s => {
					if (s.size_id === sizeId) {
						s.quantity = +quantity
					}
				})
			}
		})

		setDataVersions(arr)
	}

	const addCommentFunc = (prodId, comment) => {
		let arr = [...dataVersions]

		arr.map(i => {
			if (i.version_id === prodId) {
				i.comment = comment
			}
		})

		setDataVersions(arr)
	}

	const getAddition = () => {
		let error = false
		let versions = dataVersions.map(i => {
			let sizes = i.sizes.filter(s => s.quantity !== 0)

			sizes.map(s => ({
				size_id: s.size_id,
				quantity: s.quantity,
			}))

			return { version_id: i.version_id, comment: i.comment, sizes }
		})

		const filteredVersionList = versions.filter(i => i.sizes.length > 0)
		filteredVersionList.map(i => {
			if (i.comment == "") {
				setErrAlert(true)
				setErrText("Необходимо заполнить все комментарии")
				error = true
			}
		})

		if (!error) {
			apiPost({
				url: "/quantity/augment",
				postData: {
					versions: filteredVersionList,
				},
			}).then(res => {
				if (res?.error) {
					setErrAlert(true)
					setErrText(res.data.versions)
					return
				}
				history.goBack()
			})
		}
	}

	useEffect(() => {
		if (response) {
			const versions = response.map(i => {
				// const sizes = i.sizes.filter(s => s.quantity !== 0)

				const sizes1 = i.sizes.map(s => ({
					size_id: s.size_id,
					name: s.name,
					quantity: 0,
				}))

				return { name: i.name, version_id: i.id, comment: "", sizes: sizes1 }
			})

			setDataVersions(versions)
		}
	}, [response])

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<div className="d-flex justify-content-between mb-2 ai-center">
						<h4>Поступление</h4>
					</div>

					<Row>
						<Col xs="12">
							<Card>
								<CardBody>
									{dataVersions?.map(i => (
										<Row key={i.version_id}>
											<Col sm={12}>
												<Row>
													<Col sm={3}>
														<FormGroup>
															<Input disabled value={i.name} />
														</FormGroup>
													</Col>
													<Col sm={5}>
														{i?.sizes.map(s => (
															<Row key={s.size_id}>
																<Col sm={6}>
																	<FormGroup>
																		<Input value={s.name} disabled />
																	</FormGroup>
																</Col>
																<Col sm={6}>
																	<FormGroup>
																		<Input
																			type="number"
																			min="0"
																			onChange={e =>
																				funcUpdateArr(
																					i.version_id,
																					s.size_id,
																					e
																				)
																			}
																		/>
																	</FormGroup>
																</Col>
															</Row>
														))}
													</Col>
													<Col sm={4}>
														<textarea
															className="form-control mb-2"
															id="commentmessage-input"
															placeholder="Комментарий к поступлению..."
															rows="3"
															onChange={e =>
																addCommentFunc(i.version_id, e.target.value)
															}
														></textarea>
													</Col>
												</Row>
											</Col>
										</Row>
									))}
								</CardBody>
								<CardFooter>
									<Button
										onClick={() => getAddition()}
										color="primary"
										className="mr-1 waves-effect waves-light"
									>
										Добавить поступление
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</Container>
				{errAlert ? (
					<SweetAlert
						title={errText}
						onConfirm={() => {
							setErrAlert(false)
						}}
					></SweetAlert>
				) : null}
			</div>
		</>
	)
}

export default Entrance
