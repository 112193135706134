import React from "react"
import { Col, Container, Row } from "reactstrap"

const Footer = () => {
	return (
		<>
			<footer className="footer">
				<Container fluid={true}>
					<Row>
						<Col>{new Date().getFullYear()} © Jon's Admin.</Col>
					</Row>
				</Container>
			</footer>
		</>
	)
}

export default Footer
