import { apiGet, apiPost } from "api"
import { useFetch } from "hooks/useFetch"
import React, { memo, useEffect, useState } from "react"
import Select from "react-select"
import { Button, Col, Row } from "reactstrap"
import "./style.scss"

const ProductsLineFromSale = ({
	item,
	dataProducts,
	index,
	createdId,
	errorAlert,
	deleteProduct,
	valueSelect,
}) => {
	const [selectValue, setSelectValue] = useState([])
	const [productModels, setProductModels] = useState()
	const [sending, setSending] = useState(false)
	const [selectModelValue, setSelectModelValue] = useState([])
	const [deletable, setDeletable] = useState()
	const [errObj, setErrObj] = useState({})
	const [editable, setEditable] = useState(false)

	const { response: getSelectedValue } = useFetch({
		url:
			typeof valueSelect == "number" && `/product-version/view/${valueSelect}`,
	})

	const getProductModels = () => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/product-version/index/${selectValue.value}`,
			})
				.then(res => {
					if (res?.error) {
						return
					}
					let arr = []
					if (res?.data.length) {
						arr = res.data.map(i => ({ value: i.id, label: i.name }))
						arr.unshift({ value: 0, label: "Все" })
					} else arr = []
					setProductModels(arr)
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	useEffect(() => {
		typeof selectValue.value == "number" && getProductModels()
	}, [selectValue])

	function handleMulti(e) {
		setSelectValue(e)
		setSelectModelValue({})
	}

	function handleMultiModel(e) {
		setSelectModelValue(e)
		setEditable(true)
	}

	const bindProduct = () => {
		let productVersionId = ""
		let productId = ""

		if (selectModelValue.value === 0 || selectModelValue.length === 0) {
			productId = selectValue.value
		} else productVersionId = selectModelValue.value

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/discount/bind-discount`,
				postData: {
					id: createdId,
					productVersionId,
					productId,
				},
			})
				.then(res => {
					if (res.error) {
						errorAlert(true)
						return
					}
					setEditable(false)
					item.created = false
					item.deletable = true
				})
				.finally(() => setSending(false))
		}
	}

	const unbindProduct = () => {
		let productVersionId = ""
		let productId = ""
		if (selectModelValue.value === 0 || selectModelValue.length === 0) {
			productId = selectValue.value
		} else productVersionId = selectModelValue.value

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/discount/untie-discount`,
				postData: {
					id: createdId,
					productVersionId,
					productId,
				},
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}
					setEditable(false)
					setDeletable(true)
				})
				.finally(() => setSending(false))
		}
	}

	useEffect(() => {
		setDeletable(true)
	}, [item])

	useEffect(() => {
		setSelectValue({
			value: valueSelect,
			label: getSelectedValue?.product.name,
		})
	}, [getSelectedValue])

	useEffect(() => {
		setSelectModelValue({
			value: selectValue.value,
			label: getSelectedValue?.color.name,
		})
	}, [selectValue])

	return (
		<Row style={{ justifyContent: "space-between" }} className="mt-3">
			<Col>
				<Select
					className="basic-multi-select"
					onChange={e => {
						handleMulti(e)
					}}
					options={dataProducts}
					classNamePrefix={`select-control`}
					placeholder="Товар..."
					value={selectValue}
				/>
			</Col>

			<Col>
				{selectValue && (
					<Select
						noOptionsMessage={() => "Нет вариантов.."}
						className="basic-multi-select"
						options={productModels}
						classNamePrefix={`select-control`}
						placeholder="Версия товара..."
						onChange={e => {
							handleMultiModel(e)
						}}
						value={selectModelValue}
					/>
				)}
			</Col>
			{(item.created || editable) && (
				<Col xs={"auto"}>
					<Button
						style={{ height: "100%" }}
						color="primary"
						className="mr-1 waves-effect waves-light "
						onClick={() => bindProduct()}
					>
						<i className="bx bx-save" />
					</Button>
				</Col>
			)}

			<Col xs={"auto"}>
				{deletable && (
					<Button
						style={{ height: "100%" }}
						color="danger"
						className="mr-1 waves-effect waves-light "
						onClick={() => {
							deleteProduct(), unbindProduct(index)
						}}
					>
						<i className="bx bx-trash-alt" />
					</Button>
				)}
			</Col>
		</Row>
	)
}

export default memo(ProductsLineFromSale)
