import { apiGet, apiPost } from "api"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Input from "components/Input"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Collapse,
	Modal,
	Row,
} from "reactstrap"
import "./styling.scss"

const AccordeonCharacters = ({ item }) => {
	const [opened, setOpened] = useState(false)
	const [sending, setSending] = useState(false)
	const [modalAddCharacter, setModalAddCharacter] = useState(false)
	const [addNewCharacter, setAddNewCharacter] = useState("")
	const [accContent, setAccContent] = useState("")
	const [errObj, setErrObj] = useState({})
	const [deleteId, setDeleteID] = useState("")
	const [deleteModal, setDeleteModal] = useState(false)

	const addNewCharData = () => {
		let arr = []
		if (addNewCharacter) {
			arr.push(addNewCharacter)
		}

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/characteristic-value/create/${item.id}`,
				postData: {
					values: arr,
				},
			})
				.then(res => {
					if (res.error) {
						setErrObj(res.data)
						return
					}
					setErrObj({})

					setAddNewCharacter("")
					setModalAddCharacter(false)
					if (!sending) {
						setSending(true)
						apiGet({
							url: `/characteristic-value/index/${item.id}`,
						})
							.then(res => {
								if (res.error) {
									return
								}
								setOpened(true)
								setAccContent(res.data)
							})
							.finally(() => {
								setSending(false)
							})
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const getCharData = () => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/characteristic-value/index/${item.id}`,
			})
				.then(res => {
					if (res.error) {
						return
					}
					setOpened(!opened)
					setAccContent(res.data)
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const deleteChar = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/characteristic-value/delete/${deleteId}`,
			})
				.then(res => {
					if (res.error) {
						return
					}
					setDeleteModal(false)

					if (!sending) {
						setSending(true)
						apiGet({
							url: `/characteristic-value/index/${item.id}`,
						})
							.then(res => {
								if (res.error) {
									return
								}
								setOpened(true)
								setAccContent(res.data)
							})
							.finally(() => {
								setSending(false)
							})
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}
	const changeChar = (e, field, index) => {
		const data = [...accContent]
		data[index][field] = e
		data[index].edit = true
		setAccContent(data)
	}

	const updateChar = index => {
		const data = [...accContent]
		const value = data[index].value
		const id = data[index].id
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/characteristic-value/update/${id}`,
				postData: {
					value: value,
				},
			})
				.then(res => {
					if (res.error) {
						return
					}
					data[index].edit = false
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const deleteSizeFunc = id => {
		setDeleteID(id)
		setDeleteModal(true)
	}

	useEffect(() => {
		setErrObj({})
	}, [modalAddCharacter])

	return (
		<>
			<div id="accordion">
				<Card className="mb-0">
					<CardHeader
						className="p-3"
						id="headingOne"
						style={item.data.length ? { cursor: "pointer" } : {}}
						onClick={e => {
							e.target.id == "headingOne" && getCharData()
						}}
					>
						<div
							id="headingOne"
							className="d-flex justify-content-between ai-center"
						>
							<h6 className="m-0 font-14 d-flex" id="headingOne">
								{item.name}
								<div style={{ fontWeight: 200, marginLeft: 5 }}>
									({item.category.name})
								</div>
							</h6>
							<div className="btn-group btn-group-sm">
								<Button
									color="primary"
									className="mr-1"
									onClick={() => setModalAddCharacter(true)}
								>
									<i className="bx bx-plus" />
								</Button>
							</div>
						</div>
					</CardHeader>

					<Collapse isOpen={accContent.length && opened}>
						<CardBody>
							{accContent &&
								accContent.map((i, index) => (
									<Row className="mb-2" key={i.id}>
										<Col>
											<Input
												id="price"
												value={i.value}
												name="price"
												onChange={e => changeChar(e, "value", index)}
												type="text"
											/>
										</Col>
										{i.edit && (
											<Col xs={"auto"}>
												<Button
													style={{ height: "100%" }}
													type="submit"
													color="success"
													className="mr-1 waves-effect waves-light "
													onClick={() => updateChar(index)}
												>
													<i className="bx bx-save" />
												</Button>
											</Col>
										)}

										<Col xs={"auto"}>
											<Button
												style={{ height: "100%" }}
												type="submit"
												color="danger"
												className="mr-1 waves-effect waves-light "
												onClick={() => deleteSizeFunc(i.id)}
											>
												<i className="bx bx-trash-alt" />
											</Button>
										</Col>
									</Row>
								))}
						</CardBody>
					</Collapse>
				</Card>
			</div>

			<Modal
				isOpen={modalAddCharacter}
				toggle={() => {
					setModalAddCharacter(!modalAddCharacter)
				}}
				centered={true}
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0">Создание</h5>
					<button
						type="button"
						onClick={() => {
							setModalAddCharacter(false)
						}}
						className="close"
						data-dismiss="modal"
						aria-label="Закрыть"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">
					{" "}
					<AvForm className="form-horizontal">
						<div className="form-group">
							<Input
								name="editCategory"
								label="Наименование"
								onChange={e => setAddNewCharacter(e)}
								placeholder="Введите значение характеристики"
								type="text"
								error={errObj.values}
							/>
						</div>
						<div className="text-center mt-4">
							<Button color="primary" onClick={() => addNewCharData()}>
								Создать
							</Button>
						</div>
					</AvForm>
				</div>
			</Modal>

			<Modal
				isOpen={deleteModal}
				toggle={() => {
					setDeleteModal(!deleteModal)
				}}
				centered={true}
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0">
						Вы точно хотите удалить это значение характеристики?
					</h5>
					<button
						type="button"
						onClick={() => {
							setDeleteModal(false)
						}}
						className="close"
						data-dismiss="modal"
						aria-label="Закрыть"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body ">
					<div className="text-center mt-4 m-auto">
						<Button
							className="mr-2 "
							color="danger"
							onClick={() => deleteChar()}
						>
							Да
						</Button>
						<Button color="primary" onClick={() => setDeleteModal(false)}>
							Нет
						</Button>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default withTranslation()(AccordeonCharacters)
