import { apiPost } from "api"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Input from "components/Input"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardImg,
	Col,
	Modal,
	Row,
	Table,
} from "reactstrap"
import { transliterate } from "utils/transliterate"

const Faq = props => {
	const { page } = useUrl()
	const [modal, setModal] = useState({
		delete: false,
		create: false,
		edit: false,
	})

	const [errObj, setErrObj] = useState({})
	const [deleteId, setDeleteId] = useState()
	const [selectedFile, setSelectedFile] = useState()

	const [faqTag, setFaqTag] = useState("")
	const [faqTagUrl, setFaqTagUrl] = useState("")

	const [editFaqTagId, setEditFaqTagId] = useState("")
	const [editFaqTag, setEditFaqTag] = useState("")
	const [editFaqTagUrl, setEditFaqTagUrl] = useState("")
	const [editFaqImg, setEditFaqImg] = useState()

	const { response: faqTagsData, fetchData } = useFetch({
		url: `faq/index?page=${page || 1}&limit=10`,
	})

	const handleAcceptedFiles = files => {
		files.map(file =>
			Object.assign(file, {
				preview: URL.createObjectURL(file),
				formattedSize: formatBytes(file.size),
			})
		)

		setSelectedFile(...files)
	}

	function formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return "0 Bytes"
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
	}

	const deleteFaqTag = () => {
		apiPost({
			url: `/faq/delete/${deleteId}`,
		}).then(res => {
			if (res?.error) {
				return
			}
			setModal(s => ({ ...s, delete: false }))
			fetchData()
		})
	}

	const createTagFaq = () => {
		const postData = new FormData()
		if (faqTag) {
			postData.append("name", faqTag)
		}
		if (faqTagUrl) {
			postData.append("permalink", faqTagUrl)
		}
		if (selectedFile) {
			postData.append("image", selectedFile)
		}
		apiPost({
			url: `/faq/create`,
			postData,
		}).then(res => {
			if (res?.error) {
				setErrObj(res.data)
				return
			}
			setFaqTagUrl("")
			setFaqTag("")
			setSelectedFile()
			setModal(s => ({ ...s, create: false }))
			fetchData()
		})
	}

	const updateFaqTag = () => {
		const postData = new FormData()
		if (editFaqTag) {
			postData.append("name", editFaqTag)
		}
		if (editFaqTagUrl) {
			postData.append("permalink", editFaqTagUrl)
		}
		if (selectedFile) {
			postData.append("image", selectedFile)
		}
		apiPost({
			url: `/faq/update/${editFaqTagId}`,
			postData,
		}).then(res => {
			if (res?.error) {
				setErrObj(res.data)
				return
			}
			setEditFaqTag("")
			setEditFaqTagUrl("")
			setSelectedFile()
			setModal(s => ({ ...s, edit: false }))
			fetchData()
		})
	}

	const openEdit = data => {
		setEditFaqTagId(data.id)
		setEditFaqTag(data.name)
		setEditFaqImg(data?.image?.path)
		setEditFaqTagUrl(data.permalink)
		setModal(s => ({ ...s, edit: true }))
	}

	useEffect(() => {
		setFaqTagUrl(transliterate(faqTag))
	}, [faqTag])

	useEffect(() => {
		setSelectedFile()
	}, [modal])

	return (
		<>
			{" "}
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Список тем Faq")}</h4>
						<Button
							color="primary"
							className="btn btn-primary waves-effect waves-light mb-2"
							onClick={() => setModal(s => ({ ...s, create: !s.create }))}
						>
							Добавить тему faq
						</Button>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<div className="table-responsive">
										<Table className="table mb-0">
											<thead className="thead-light">
												<tr>
													<th>#</th>
													<th>Название</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{faqTagsData &&
													faqTagsData?.models.map(i => (
														<tr key={i.id}>
															<th scrope="row">{i.id}</th>
															<td>{i.name}</td>
															<td className="edit">
																<Link
																	to={`/faq/questions/${i.id}/${i.permalink}`}
																	className="mr-1 btn btn-primary waves-effect waves-light"
																	onClick={() => openEdit(i)}
																>
																	<i className="mdi mdi-eye" />
																</Link>
																<Button
																	color="warning"
																	className="mr-1  waves-effect waves-light"
																	onClick={() => openEdit(i)}
																>
																	<i className="bx bx-cog" />
																</Button>
																<Button
																	type="submit"
																	color="danger"
																	className="mr-1 waves-effect waves-light"
																	onClick={() => {
																		setModal(s => ({ ...s, delete: true }))
																		setDeleteId(i.id)
																	}}
																>
																	<i className="bx bx-trash-alt" />
																</Button>
															</td>
														</tr>
													))}
											</tbody>
										</Table>
									</div>
								</CardBody>
							</Card>
							<Pagination lastPage={faqTagsData?.lastPage} page={page} />
						</Col>
					</Row>
				</div>
				<Modal
					isOpen={modal.create}
					toggle={() => setModal(s => ({ ...s, create: !s.create }))}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Создание темы частых вопросов</h5>
						<button
							type="button"
							onClick={() => setModal(s => ({ ...s, create: false }))}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal">
							<div className="form-group">
								<Input
									label="Тема частых вопросов"
									value={faqTag}
									onChange={e => setFaqTag(e)}
									placeholder="Введите название темы..."
									type="text"
									fieldClasses="mb-2"
									error={errObj.name}
								/>
								<Input
									label="Url"
									value={faqTagUrl}
									onChange={e => setFaqTagUrl(transliterate(e))}
									placeholder="Введите url темы..."
									type="text"
									error={errObj.permalink}
									fieldClasses="mb-2"
								/>

								<Dropzone
									onDrop={acceptedFiles => {
										handleAcceptedFiles(acceptedFiles)
									}}
								>
									{({ getRootProps, getInputProps }) => (
										<div className="dropzone">
											<div
												className="dz-message needsclick"
												{...getRootProps()}
											>
												<input {...getInputProps()} />
												<div className="dz-message needsclick">
													<div className="mb-3">
														<i className="display-4 text-muted bx bxs-cloud-upload" />
													</div>
													<h4>Переместите логотип сюда.</h4>
												</div>
											</div>
										</div>
									)}
								</Dropzone>
								{selectedFile && (
									<Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
										<div className="p-2">
											<Row className="align-items-center">
												<Col className="col-auto">
													<img
														data-dz-thumbnail=""
														height="80"
														className="avatar-sm rounded bg-light"
														alt={selectedFile.name}
														src={selectedFile.preview}
													/>
												</Col>
												<Col>
													<Link to="#" className="text-muted font-weight-bold">
														{selectedFile.name}
													</Link>
													<p className="mb-0">
														<strong>{selectedFile.formattedSize}</strong>
													</p>
												</Col>
												<Col xs={"auto"}>
													<Button
														color="danger"
														onClick={() => setSelectedFile()}
													>
														<i className="bx bx-trash-alt" />
													</Button>
												</Col>
											</Row>
										</div>
									</Card>
								)}
							</div>
							<div className="text-center mt-4">
								<Button type="submit" color="primary" onClick={createTagFaq}>
									Создать тему частых вопросов
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>
				<Modal
					isOpen={modal.edit}
					toggle={() => setModal(s => ({ ...s, edit: !s.edit }))}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Изменение темы частых вопросов</h5>
						<button
							type="button"
							onClick={() => setModal(s => ({ ...s, edit: false }))}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm className="form-horizontal">
							<div className="form-group">
								{editFaqImg && (
									<CardImg
										top
										className="img-fluid"
										style={{ objectFit: "contain" }}
										src={editFaqImg}
										alt="Skote"
									/>
								)}
								<Input
									label="Тема частых вопросов"
									value={editFaqTag}
									onChange={e => setEditFaqTag(e)}
									placeholder="Введите название темы..."
									type="text"
									fieldClasses="mb-2"
									error={errObj.name}
								/>
								<Input
									label="Url"
									value={editFaqTagUrl}
									onChange={e => setEditFaqTagUrl(transliterate(e))}
									placeholder="Введите url темы..."
									type="text"
									error={errObj.permalink}
									fieldClasses="mb-2"
								/>

								<Dropzone
									onDrop={acceptedFiles => {
										handleAcceptedFiles(acceptedFiles)
									}}
								>
									{({ getRootProps, getInputProps }) => (
										<div className="dropzone">
											<div
												className="dz-message needsclick"
												{...getRootProps()}
											>
												<input {...getInputProps()} />
												<div className="dz-message needsclick">
													<div className="mb-3">
														<i className="display-4 text-muted bx bxs-cloud-upload" />
													</div>
													<h4>Переместите логотип сюда.</h4>
												</div>
											</div>
										</div>
									)}
								</Dropzone>
								{selectedFile && (
									<Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
										<div className="p-2">
											<Row className="align-items-center">
												<Col className="col-auto">
													<img
														data-dz-thumbnail=""
														height="80"
														className="avatar-sm rounded bg-light"
														alt={selectedFile.name}
														src={selectedFile.preview}
													/>
												</Col>
												<Col>
													<Link to="#" className="text-muted font-weight-bold">
														{selectedFile.name}
													</Link>
													<p className="mb-0">
														<strong>{selectedFile.formattedSize}</strong>
													</p>
												</Col>
												<Col xs={"auto"}>
													<Button
														color="danger"
														onClick={() => setSelectedFile()}
													>
														<i className="bx bx-trash-alt" />
													</Button>
												</Col>
											</Row>
										</div>
									</Card>
								)}
							</div>
							<div className="text-center mt-4">
								<Button type="submit" color="primary" onClick={updateFaqTag}>
									Изменить тему частых вопросов
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>
				<Modal
					isOpen={modal.delete}
					toggle={() => setModal(s => ({ ...s, delete: !s.delete }))}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить эту тему?
						</h5>
						<button
							type="button"
							onClick={() => setModal(s => ({ ...s, delete: false }))}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deleteFaqTag()}
							>
								Да
							</Button>
							<Button
								color="primary"
								onClick={() => setModal(s => ({ ...s, delete: !s.delete }))}
							>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default withTranslation()(Faq)
