//Import Breadcrumb
import { apiGet, apiPost } from "api"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Label,
	Modal,
	Row,
	Table,
} from "reactstrap"
import { updateCountNotifications } from "redux/actions/user"
import EcommerceOrdersModal from "./EcommerceOrdersModal"
import "./styling.scss"

const Orders = props => {
	const { page } = useUrl()
	const dispatch = useDispatch()
	const { response, fetchData } = useFetch({
		url: `order/index-admin?page=${page || 1}&limit=12`,
	})

	const [modal, setModal] = useState(false)
	const [clickedId, setClickedId] = useState()
	const [changeStatus, setChangeStatus] = useState()
	const [optionsInput, setOptionsInput] = useState()
	const [modalData, setModalData] = useState()
	const [openModalEditOrder, setOpenModalEditOrder] = useState(false)

	const updateNotifications = () => {
		apiGet({
			url: `/notification/index`,
		}).then(res => {
			if (res.error) {
				return
			}

			dispatch(updateCountNotifications(res.data))
		})
	}

	const toggleModal = id => {
		if (modal) {
			setModal(false)
		} else {
			apiGet({
				url: `/order/view-admin/${id}`,
			}).then(res => {
				if (res.error) {
					return
				}
				setModal(true)
				setModalData(res.data)
			})
		}
	}

	const changeStatusFunc = () => {
		apiPost({
			url: `/order/update-status/${clickedId}`,
			postData: {
				status: changeStatus,
			},
		}).then(res => {
			if (res.error) {
				return
			}
			fetchData()
			setOpenModalEditOrder(false)
			updateNotifications()
		})
	}

	useEffect(() => {
		setOptionsInput([
			{ id: 3, name: "Ожидает оплату" },
			{ id: 4, name: "Ожидает доставку" },
			{ id: 5, name: "В пути" },
			{ id: 6, name: "В пункте выдачи" },
			{ id: 7, name: "Выполнен" },
			{ id: 8, name: "Отменен" },
		])
	}, [])

	const toggleModalEditOrder = id => {
		setClickedId(id)
		setOpenModalEditOrder(true)
	}

	return (
		<>
			{modalData && (
				<EcommerceOrdersModal
					data={modalData}
					isOpen={modal}
					toggle={toggleModal}
				/>
			)}

			<div className="page-content">
				<Container fluid>
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Orders")}</h4>
					</div>
					<Row>
						<Col xs="12">
							<Card>
								<CardBody>
									<Table className="table mb-0">
										<thead className="thead-light">
											<tr>
												<th>#</th>
												<th>ФИО</th>
												<th>Дата</th>
												<th>Сумма без скидок</th>
												<th>Сумма с учетом скидок</th>
												<th>Сумма к оплате</th>
												<th>Статус оплаты</th>
												<th>Способ оплаты</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{response?.models?.map(i => (
												<tr
													key={i.id}
													onClick={e =>
														e.target.nodeName == "TD" ? toggleModal(i.id) : e
													}
													style={{ cursor: "pointer" }}
												>
													<th>{i.order.num}</th>
													<td>
														{i.user.lastName} {i.user.name} {i.user.patronymic}
													</td>
													<td>{i.order.createdAt}</td>
													<td>{i.order.price.total}</td>
													<td>{i.order.price.priceWithSale}</td>
													<td>{i.order.price.finishPrice}</td>
													<td>{i.order.status.text}</td>
													<td>{i.payment.text}</td>
													<td className="edit">
														<Button
															type="submit"
															color="primary"
															className="mr-1 btn btn-primary waves-effect waves-light"
															onClick={() => toggleModalEditOrder(i.id)}
														>
															<i className="mdi mdi-pencil" />
														</Button>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								</CardBody>
								<Pagination lastPage={response?.lastPage} page={page} />
								{/* {!isEmpty(orderList) && (
									<CardBody>
										<PaginationProvider
											pagination={paginationFactory(pageOptions)}
										>
											{({ paginationProps, paginationTableProps }) => (
												<ToolkitProvider
													keyField="id"
													data={orderList || []}
													columns={EcommerceOrderColumns(
														toggleModal,
														toggleModalEditOrder
													)}
													bootstrap4
													search
												>
													{toolkitProps => (
														<>
															<Row className="mb-2">
																<Col sm="4">
																	<div className="search-box mr-2 mb-2 d-inline-block">
																		<div className="position-relative">
																			<SearchBar
																				{...toolkitProps.searchProps}
																			/>
																			<i className="bx bx-search-alt search-icon" />
																		</div>
																	</div>
																</Col>
															</Row>
															<Row>
																<Col xl="12">
																	<div className="table-responsive">
																		<BootstrapTable
																			responsive
																			remote
																			bordered={false}
																			striped={false}
																			classes={
																				"table table-centered table-nowrap"
																			}
																			headerWrapperClasses={"thead-light"}
																			{...toolkitProps.baseProps}
																			onTableChange={handleTableChange}
																			{...paginationTableProps}
																		/>
																	</div>
																</Col>
															</Row>
															<Row className="align-items-md-center mt-30">
																<Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
																	<PaginationListStandalone
																		{...paginationProps}
																	/>
																</Col>
															</Row>
														</>
													)}
												</ToolkitProvider>
											)}
										</PaginationProvider>
									</CardBody>
								)} */}
							</Card>
						</Col>
					</Row>
				</Container>
				<Modal
					isOpen={openModalEditOrder}
					toggle={() => {
						setOpenModalEditOrder(!openModalEditOrder)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Изменение статуса заказа</h5>
						<button
							type="button"
							onClick={() => {
								setOpenModalEditOrder(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{" "}
						<AvForm
							className="form-horizontal"
							onValidSubmit={(e, v) => {
								e, v
							}}
						>
							<div className="form-group">
								<Label className="control-label">Статус заказа</Label>
								<select
									className="form-control select2"
									onChange={e => setChangeStatus(e.target.value)}
									value={changeStatus}
								>
									<option>Выбор</option>
									{optionsInput &&
										optionsInput.map(i => (
											<option value={i.id}>{i.name}</option>
										))}
								</select>
							</div>
							<div className="text-center mt-4">
								<Button
									type="submit"
									color="primary"
									onClick={() => changeStatusFunc()}
								>
									Изменить статус
								</Button>
							</div>
						</AvForm>
					</div>
				</Modal>
			</div>
		</>
	)
}

Orders.propTypes = {
	t: PropTypes.any,
}

export default withTranslation()(Orders)
