import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from "reactstrap"
import { appLogout } from "redux/actions/app"

const ProfileMenu = props => {
	// Declare a new state variable, which we'll call "menu"
	const [menu, setMenu] = useState(false)
	const dispatch = useDispatch()
	const [username, setUsername] = useState("")

	useEffect(() => {
		if (localStorage.getItem("userData")) {
			if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
				const obj = JSON.parse(localStorage.getItem("userData"))
				setUsername(obj.username || "")
			} else if (
				process.env.REACT_APP_DEFAULTAUTH === "fake" ||
				process.env.REACT_APP_DEFAULTAUTH === "jwt"
			) {
				const obj = JSON.parse(localStorage.getItem("userData"))
				setUsername(obj.name || "")
			}
		}
	}, [props.success])

	return (
		<>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className="d-inline-block"
			>
				<DropdownToggle
					className="btn header-item waves-effect"
					id="page-header-user-dropdown"
					tag="button"
				>
					{/* <img
						className="rounded-circle header-profile-user"
						src={user1}
						alt="Header Avatar"
					/> */}
					<span className="d-none d-xl-inline-block ml-2 mr-1">
						{username || ""}
					</span>
					<i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
				</DropdownToggle>
				<DropdownMenu right>
					<DropdownItem tag="a" href="/profile">
						{" "}
						<i className="bx bx-user font-size-16 align-middle mr-1" />
						{props.t("Profile")}{" "}
					</DropdownItem>
					{/* <DropdownItem tag="a" href="/crypto-wallet">
						<i className="bx bx-wallet font-size-16 align-middle mr-1" />
						{props.t("My Wallet")}
					</DropdownItem> */}
					{/* <DropdownItem tag="a" href="#">
						<span className="badge badge-success float-right">11</span>
						<i className="mdi mdi-settings font-size-17 align-middle mr-1" />
						{props.t("Settings")}
					</DropdownItem> */}
					{/* <DropdownItem tag="a" href="auth-lock-screen">
						<i className="bx bx-lock-open font-size-16 align-middle mr-1" />
						{props.t("Lock screen")}
					</DropdownItem> */}
					<div className="dropdown-divider" />
					<Link
						to="/"
						className="dropdown-item"
						onClick={() => dispatch(appLogout())}
					>
						<i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
						<span>{props.t("Logout")}</span>
					</Link>
				</DropdownMenu>
			</Dropdown>
		</>
	)
}

ProfileMenu.propTypes = {
	success: PropTypes.any,
	t: PropTypes.any,
}

export default withTranslation()(ProfileMenu)
