import { apiGet, apiPost } from "api"
import AccordeonPromo from "components/Accodeons/AccordeonPromo"
import Input from "components/Input"
import ProductsLineFromSale from "components/ProductsLineFromSale"
import { useFetch } from "hooks/useFetch"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useForm } from "react-hook-form"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	FormGroup,
	Label,
	Modal,
	Row,
} from "reactstrap"
import "./styling.scss"

const CreateSale = props => {
	const history = useHistory()

	const { register, handleSubmit } = useForm()

	const [modalItems, setModalItems] = useState(false)
	const [errObj, setErrObj] = useState({})
	const [sending, setSending] = useState(false)
	const [categoryList, setCategoryList] = useState([])
	const [dataCategory, setDataCategory] = useState()
	const [createdId, setCreatedId] = useState("")
	const [dataProducts, setDataProducts] = useState()
	const [prodsLine, setProdsLine] = useState([])
	const [errorAlert, setErrorAlert] = useState(false)

	const { response: catalogData } = useFetch({
		url: `/category/index`,
	})

	const { response: getProducts } = useFetch({
		url: `product/index`,
	})

	const addProdLine = () => {
		const arr = [...prodsLine]
		arr.push({ name: "", created: true })
		setProdsLine(arr)
	}

	useEffect(() => {
		setDataProducts(
			getProducts?.models?.map(i => ({ value: i.id, label: i.name }))
		)
	}, [getProducts])

	const createSale = data => {
		const { name, dateStart, dateEnd, value, type } = data

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/discount/create`,
				postData: {
					name,
					type,
					value,
					startDate: dateStart.replace("T", " "),
					endDate: dateEnd.replace("T", " "),
				},
			})
				.then(res => {
					if (res.status === 406) {
						setErrObj(res.data)
						return
					} else {
						setCreatedId(res.data.id)
						setErrObj({})
						setModalItems(true)
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const clickAcc = (id, bool) => {
		let arr = [...categoryList]
		let access = false
		categoryList.map(
			(i, index) =>
				i === id &&
				(arr.splice(index, 1), setCategoryList(arr), (access = true))
		)

		if (access === false) {
			arr.push(id)
			setCategoryList(arr)
		}
		let url = ""
		if (!bool.length) {
			url = "/discount/bind-discount"
		} else {
			url = "/discount/untie-discount"
		}

		if (!sending) {
			setSending(true)
			apiPost({
				url: url,
				postData: {
					id: createdId,
					categoryId: id,
				},
			})
				.then(res => {
					if (res?.error) {
						return
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const clickedTabId = id => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/category/index?categoryId=${id}`,
			})
				.then(res => {
					if (res.error) {
						return
					}
					let arr = [...dataCategory]

					const recSearchIdFunc = (stack, id) => {
						stack.map(i =>
							i.id == id
								? (i.children = res.data)
								: i.children && recSearchIdFunc(i.children, id)
						)
						setDataCategory(arr)
					}
					recSearchIdFunc(arr, id)
					renderAccordeonCatalog(dataCategory)
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const renderAccordeonCatalog = item => {
		return item.map(i => (
			<AccordeonPromo
				key={i.id}
				blocked={i.hasSubCategories == false}
				item={i}
				checked={categoryList?.filter(f => i.id === f)}
				click={() =>
					clickAcc(
						i.id,
						categoryList?.filter(f => i.id === f)
					)
				}
				clickedTabId={() => clickedTabId(i.id)}
			>
				{i.children && renderAccordeonCatalog(i.children)}
			</AccordeonPromo>
		))
	}

	const deleteProduct = index => {
		let line = [...prodsLine]
		line.splice(index, 1)
		setProdsLine(line)
	}

	const renderProductsLine = data => {
		return data?.map((i, index) => (
			<ProductsLineFromSale
				createdId={createdId}
				key={index}
				item={i}
				dataProducts={dataProducts}
				deleteProduct={() => deleteProduct(index)}
				errorAlert={setErrorAlert}
			/>
		))
	}

	useEffect(() => {
		setDataCategory(catalogData)
	}, [catalogData])

	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>Создание скидки</h4>
					</div>

					<Row>
						<Col lg={12}>
							<form onSubmit={handleSubmit(createSale)}>
								<Card>
									<CardBody>
										<Row>
											<Col md={4}>
												<FormGroup>
													<Label>Название скидки</Label>
													<Input
														register={register}
														name="name"
														type="text"
														error={errObj.name}
													/>
												</FormGroup>
											</Col>
											<Col md={4}>
												<FormGroup>
													<Label>Тип скидки</Label>
													<select
														ref={register}
														name="type"
														className="form-control"
													>
														<option value="2">В рублях</option>
														<option value="1">В процентах</option>
													</select>
												</FormGroup>
											</Col>
											<Col md={4}>
												<FormGroup>
													<Label>Величина скидки</Label>
													<Input
														register={register}
														type="text"
														name="value"
														error={errObj.value}
													/>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<Label>Дата начала</Label>
													<Input
														register={register}
														type="datetime-local"
														name="dateStart"
														error={errObj.startDate}
													/>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<Label>Дата окончания</Label>
													<Input
														register={register}
														type="datetime-local"
														name="dateEnd"
														error={errObj.endDate}
													/>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									<CardFooter>
										<Button type="submit" color="primary">
											Создать
										</Button>
									</CardFooter>
								</Card>
							</form>
						</Col>
					</Row>
				</div>
				<Modal
					size="lg"
					isOpen={modalItems}
					toggle={() => {
						history.goBack()
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Характеристики скидки</h5>
						<button
							type="button"
							onClick={() => {
								history.goBack()
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="row">
							<div className="col-12">
								<Label className="mb-2 mt-2">Категории</Label>
								{dataCategory && renderAccordeonCatalog(dataCategory)}
								<div className="d-flex jc-between mt-4 mb-2">
									<Label className="">Товары</Label>
									<div className="btn-group btn-group-sm">
										{" "}
										<Button color="primary" onClick={() => addProdLine()}>
											<i className="bx bx-plus" />
										</Button>
									</div>
								</div>
								{prodsLine && renderProductsLine(prodsLine)}
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<Button color="primary" onClick={() => history.goBack()}>
							Сохранить
						</Button>
					</div>
				</Modal>
				{errorAlert ? (
					<SweetAlert
						title="Произошла ошибка, проверьте товары."
						onConfirm={() => {
							setErrorAlert(false)
						}}
					></SweetAlert>
				) : null}
			</div>
		</>
	)
}

export default withTranslation()(CreateSale)
