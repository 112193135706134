import { apiPost } from "api"
import Input from "components/Input"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	FormGroup,
	Label,
	Row,
} from "reactstrap"
import "./styling.scss"

const CreatePickUpPoint = props => {
	const history = useHistory()

	const [sending, setSending] = useState(false)
	const [errObj, setErrObj] = useState({})

	const { register, handleSubmit } = useForm()

	const createPickUp = data => {
		const {
			name,
			country,
			city,
			street,
			house,
			housing,
			structure,
			timeStart,
			timeEnd,
			holdingDays,
		} = data

		if (!sending) {
			setSending(true)
			apiPost({
				url: `/points/create`,
				postData: {
					name,
					country,
					city,
					street,
					house,
					housing,
					structure,
					holdingDays: parseInt(holdingDays),
					workTime: { start: timeStart, end: timeEnd },
				},
			})
				.then(res => {
					if (res.status === 406) {
						setErrObj(res.data)

						return
					} else {
						history.goBack()
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}
	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>Создание пункта выдачи</h4>
					</div>

					<Row>
						<Col lg={12}>
							<form onSubmit={handleSubmit(createPickUp)}>
								<Card>
									<CardBody>
										<Row>
											<Col sm="6">
												<FormGroup>
													<Label className="mt-2">Пункт выдачи</Label>
													<Input
														name="name"
														type="text"
														register={register}
														error={errObj.name}
													/>
													<Label className="mt-2">Страна</Label>
													<Input
														name="country"
														type="text"
														register={register}
														error={errObj.country}
													/>
													<Label className="mt-2">Город</Label>
													<Input
														name="city"
														type="text"
														register={register}
														error={errObj.city}
													/>
													<Label className="mt-2">Улица</Label>
													<Input
														name="street"
														type="text"
														register={register}
														error={errObj.street}
													/>
													<Label className="mt-2">Дом</Label>
													<Input
														name="house"
														type="text"
														register={register}
														error={errObj.house}
													/>
													<Label className="mt-2">Строение</Label>
													<Input
														name="housing"
														type="text"
														register={register}
														error={errObj.housing}
													/>
													<Label className="mt-2">Корпус</Label>
													<Input
														name="structure"
														type="text"
														register={register}
														error={errObj.structure}
													/>
												</FormGroup>
											</Col>
											<Col sm="6">
												<FormGroup>
													<Label className="mt-2">Время начала работы</Label>
													<Input
														name="timeStart"
														type="time"
														register={register}
														error={errObj.workTime}
													/>
													<Label className="mt-2">Время окончания работы</Label>
													<Input
														register={register}
														name="timeEnd"
														type="time"
														error={errObj.workTime}
													/>
													<Label className="mt-2">
														Срок хранения заказа (дней)
													</Label>
													<Input
														register={register}
														name="holdingDays"
														type="number"
														error={errObj.shelfLife}
													/>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									<CardFooter>
										<Row>
											<Col xs={"auto"}>
												<Button color="primary">Создать</Button>
											</Col>
										</Row>
									</CardFooter>
								</Card>
							</form>
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}

export default withTranslation()(CreatePickUpPoint)
