import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import {
	changeLayoutWidth,
	changeSidebarTheme,
	changeSidebarType,
	changeTopbarTheme,
} from "../../redux/layout/actions"
import Rightbar from "../CommonForBoth/Rightbar"
import Footer from "./Footer"
// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"

const Layout = props => {
	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

	const capitalizeFirstLetter = string => {
		return string.charAt(1).toUpperCase() + string.slice(2)
	}

	useEffect(() => {
		if (props.isPreloader === true) {
			document.getElementById("preloader").style.display = "block"
			document.getElementById("status").style.display = "block"

			setTimeout(function () {
				document.getElementById("preloader").style.display = "none"
				document.getElementById("status").style.display = "none"
			}, 2500)
		} else {
			document.getElementById("preloader").style.display = "none"
			document.getElementById("status").style.display = "none"
		}
		// Scroll Top to 0
		window.scrollTo(0, 0)
		let currentage = capitalizeFirstLetter(props.location.pathname)

		document.title =
			currentage + " | Skote - Responsive Bootstrap 4 Admin Dashboard"
		if (props.leftSideBarTheme) {
			changeSidebarTheme(props.leftSideBarTheme)
		}

		if (props.layoutWidth) {
			changeLayoutWidth(props.layoutWidth)
		}

		if (props.leftSideBarType) {
			changeSidebarType(props.leftSideBarType)
		}
		if (props.topbarTheme) {
			changeTopbarTheme(props.topbarTheme)
		}
	}, [])

	const toggleMenuCallback = () => {
		if (props.leftSideBarType === "default") {
			changeSidebarType("condensed", state.isMobile)
		} else if (props.leftSideBarType === "condensed") {
			changeSidebarType("default", state.isMobile)
		}
	}
	return (
		<>
			<div id="preloader">
				<div id="status">
					<div className="spinner-chase">
						<div className="chase-dot" />
						<div className="chase-dot" />
						<div className="chase-dot" />
						<div className="chase-dot" />
						<div className="chase-dot" />
						<div className="chase-dot" />
					</div>
				</div>
			</div>

			<div id="layout-wrapper">
				<Header toggleMenuCallback={() => toggleMenuCallback()} />

				<Sidebar
					theme={props.leftSideBarTheme}
					type={props.leftSideBarType}
					isMobile={isMobile}
				/>
				<div className="main-content">{props.children}</div>

				<Footer />
			</div>

			{props.showRightSidebar ? <Rightbar /> : null}
		</>
	)
}

Layout.propTypes = {
	props: PropTypes.object,
	children: PropTypes.any,
}

export default withRouter(Layout)

// class Layout extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
//     }
//     this.toggleMenuCallback = this.toggleMenuCallback.bind(this)
//   }
//
//   capitalizeFirstLetter = string => {
//     return string.charAt(1).toUpperCase() + string.slice(2)
//   }
//
//   componentDidMount() {
//     if (this.props.isPreloader === true) {
//       document.getElementById("preloader").style.display = "block"
//       document.getElementById("status").style.display = "block"
//
//       setTimeout(function () {
//         document.getElementById("preloader").style.display = "none"
//         document.getElementById("status").style.display = "none"
//       }, 2500)
//     } else {
//       document.getElementById("preloader").style.display = "none"
//       document.getElementById("status").style.display = "none"
//     }
//
//     // Scroll Top to 0
//     window.scrollTo(0, 0)
//     let currentage = this.capitalizeFirstLetter(this.props.location.pathname)
//
//     document.title =
//       currentage + " | Skote - Responsive Bootstrap 4 Admin Dashboard"
//     if (this.props.leftSideBarTheme) {
//       this.props.changeSidebarTheme(this.props.leftSideBarTheme)
//     }
//
//     if (this.props.layoutWidth) {
//       this.props.changeLayoutWidth(this.props.layoutWidth)
//     }
//
//     if (this.props.leftSideBarType) {
//       this.props.changeSidebarType(this.props.leftSideBarType)
//     }
//     if (this.props.topbarTheme) {
//       this.props.changeTopbarTheme(this.props.topbarTheme)
//     }
//   }
//   toggleMenuCallback = () => {
//     if (this.props.leftSideBarType === "default") {
//       this.props.changeSidebarType("condensed", this.state.isMobile)
//     } else if (this.props.leftSideBarType === "condensed") {
//       this.props.changeSidebarType("default", this.state.isMobile)
//     }
//   }
//
//   render() {
//     return (
//       <>
//         <div id="preloader">
//           <div id="status">
//             <div className="spinner-chase">
//               <div className="chase-dot"/>
//               <div className="chase-dot"/>
//               <div className="chase-dot"/>
//               <div className="chase-dot"/>
//               <div className="chase-dot"/>
//               <div className="chase-dot"/>
//             </div>
//           </div>
//         </div>
//
//         <div id="layout-wrapper">
//           <Header toggleMenuCallback={this.toggleMenuCallback} />
//           <Sidebar
//             theme={this.props.leftSideBarTheme}
//             type={this.props.leftSideBarType}
//             isMobile={this.state.isMobile}
//           />
//           <div className="main-content">{this.props.children}</div>
//           <Footer />
//         </div>
//         {this.props.showRightSidebar ? <Rightbar /> : null}
//       </>
//     )
//   }
// }
//
//
//
// const mapStatetoProps = state => {
//   return {
//     ...state.Layout,
//   }
// }
// export default connect(mapStatetoProps, {
//   changeLayout,
//   changeSidebarTheme,
//   changeSidebarType,
//   changeTopbarTheme,
//   changeLayoutWidth,
// })(withRouter(Layout))
