import AccordeonCharacters from "components/Accodeons/AccordeonCharacters"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Card, CardBody, Col, Container, Form, Input, Row } from "reactstrap"
import updateUrl from "utils/updateUrl"

const Characters = props => {
	const [search, setSearch] = useState("")
	const { page } = useUrl()
	const [charactersData, setCharactersData] = useState("")

	const { response } = useFetch({
		url: `/characteristic/index?&search=${search}&page=${page || 1}&limit=10`,
	})

	const renderAccordeonCharacters = () => {
		if (charactersData) {
			return charactersData.models.map(i => (
				<AccordeonCharacters key={i.id} item={i}>
					{i.children && renderAccordeonCharacters(i.children, i.id)}
				</AccordeonCharacters>
			))
		}
	}

	useEffect(() => {
		updateUrl({ key: "page", value: "1" })
	}, [search])

	useEffect(() => {
		setCharactersData(response)
		renderAccordeonCharacters()
	}, [response])

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Characters")}</h4>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<Row className="mb-3">
										<Col>
											<Form className="mt-4 mt-sm-0  form-inline">
												<div className="search-box mr-2">
													<div className="position-relative">
														<Input
															type="text"
															className="form-control border-0"
															placeholder={props.t("Search") + "..."}
															onChange={e => setSearch(e.target.value)}
														/>
														<i className="bx bx-search-alt search-icon" />
													</div>
												</div>
											</Form>
										</Col>
									</Row>
									<Row>
										<Col xs={12}>{renderAccordeonCharacters()}</Col>
									</Row>
								</CardBody>
								<Pagination lastPage={charactersData?.lastPage} page={page} />
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default withTranslation()(Characters)
