import { apiGet, apiPost } from "api"
import AccordeonPromo from "components/Accodeons/AccordeonPromo"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	Container,
	FormGroup,
	Label,
	Row,
} from "reactstrap"

const CreatePromo = props => {
	const history = useHistory()
	const [dataCategory, setDataCategory] = useState()
	const [sending, setSending] = useState(false)
	const [errObj, setErrObj] = useState({})
	const [type, setType] = useState(2)
	const [categoryList, setCategoryList] = useState([])

	const { response: catalogData } = useFetch({
		url: `/category/index`,
	})

	const { register, handleSubmit } = useForm()

	const createPromoFunc = data => {
		const { name, code, dateStart, dateEnd, value } = data
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/promocode/create`,
				postData: {
					name,
					code,
					dateStart: dateStart.replace("T", " "),
					dateEnd: dateEnd.replace("T", " "),
					sale: { type: type, value: value },
					categoryList,
				},
			})
				.then(res => {
					if (res.status === 406) {
						setErrObj(res.data)
						return
					} else {
						history.goBack()
					}
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const clickAcc = id => {
		let arr = [...categoryList]
		let access = false
		categoryList.map(
			(i, index) =>
				i === id &&
				(arr.splice(index, 1), setCategoryList(arr), (access = true))
		)

		if (access === false) {
			arr.push(id)
			setCategoryList(arr)
		}
	}

	const clickedTabId = id => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/category/index?categoryId=${id}`,
			})
				.then(res => {
					if (res.error) {
						return
					}
					let arr = [...dataCategory]

					const recSearchIdFunc = (stack, id) => {
						stack.map(i =>
							i.id == id
								? (i.children = res.data)
								: i.children && recSearchIdFunc(i.children, id)
						)
						setDataCategory(arr)
					}
					recSearchIdFunc(arr, id)
					renderAccordeonCatalog(dataCategory)
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const renderAccordeonCatalog = item => {
		return item.map(i => (
			<AccordeonPromo
				key={i.id}
				blocked={i.hasSubCategories == false}
				item={i}
				checked={categoryList?.filter(f => i.id === f)}
				click={() => clickAcc(i.id)}
				clickedTabId={() => clickedTabId(i.id)}
			>
				{i.children && renderAccordeonCatalog(i.children)}
			</AccordeonPromo>
		))
	}

	useEffect(() => {
		setDataCategory(catalogData)
	}, [catalogData])

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>Создание промокода</h4>
					</div>

					<Row>
						<Col lg={12}>
							<form onSubmit={handleSubmit(createPromoFunc)}>
								<Card>
									<CardBody>
										<Row>
											<Col md={6}>
												<FormGroup>
													<Label>Название промокода</Label>
													<Input
														register={register}
														name="name"
														type="text"
														error={errObj.name}
													/>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<Label>Промокод</Label>
													<Input
														register={register}
														name="code"
														type="text"
														error={errObj.code}
													/>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<Label>Дата начала</Label>
													<Input
														register={register}
														type="datetime-local"
														name="dateStart"
														error={errObj.dateStart}
													/>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<Label>Дата окончания</Label>
													<Input
														register={register}
														type="datetime-local"
														name="dateEnd"
														error={errObj.dateEnd}
													/>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<Label>Тип скидки</Label>
													<select
														onChange={e => setType(e.target.value)}
														className="form-control"
													>
														<option value="2">В рублях</option>
														<option value="1">В процентах</option>
													</select>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<Label>Величина скидки</Label>
													<Input
														register={register}
														type="text"
														name="value"
														error={errObj.sale}
													/>
												</FormGroup>
											</Col>
										</Row>
										{dataCategory && renderAccordeonCatalog(dataCategory)}
									</CardBody>
									<CardFooter>
										<Button
											color="primary"
											type="submit"
											className="mr-1 waves-effect"
										>
											Создать
										</Button>
									</CardFooter>
								</Card>
							</form>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default withTranslation()(CreatePromo)
