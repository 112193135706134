import React, { useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Card, CardBody, CardHeader, Collapse } from "reactstrap"
import "./styling.scss"

const AccordeonPromo = ({
	children,
	item,
	blocked,
	clickedTabId,
	checked,
	click,
}) => {
	const [opened, setOpened] = useState(false)

	const openAccordeon = () => {
		setOpened(!opened)
	}

	return (
		<>
			<div id="accordion">
				<Card className="mb-0">
					<CardHeader
						className={`${!blocked && "clicked"} p-3 card-header`}
						id="headingThree"
						onClick={e => {
							!blocked &&
								e.target.id == "headingThree" &&
								(openAccordeon(), clickedTabId(e))
						}}
					>
						<div
							style={{ display: "inline-flex" }}
							className="custom-control custom-checkbox custom-checkbox-primary mb-3"
						>
							<input
								type="checkbox"
								className="custom-control-input"
								defaultChecked={checked?.length}
								checked={checked?.length}
								onChange={click}
								onClick={click}
							/>

							<label className="custom-control-label" onClick={click}>
								{item.name}
							</label>
						</div>
					</CardHeader>
					<Collapse isOpen={opened}>
						<Card>
							<CardBody>{children}</CardBody>
						</Card>
					</Collapse>{" "}
				</Card>
			</div>
		</>
	)
}

export default withTranslation()(AccordeonPromo)
