import { apiPost } from "api"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Modal, Row, Table } from "reactstrap"
import "./styling.scss"

const Sales = props => {
	const { page } = useUrl()

	const [deleteId, setDeleteId] = useState("")
	const [sending, setSending] = useState(false)

	const [deleteModal, setDeleteModal] = useState(false)

	const { response: dataSales, fetchData } = useFetch({
		url: `/discount/index?page=${page || 1}&limit=15`,
	})

	const deleteSale = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/discount/delete/${deleteId}`,
			})
				.then(res => {
					if (res?.error) {
						return
					}
					setDeleteModal(false)
					fetchData()
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Sales")}</h4>
						<Link
							to="/sale/create"
							color="primary"
							className="btn btn-primary waves-effect waves-light mb-2"
						>
							Добавить скидку
						</Link>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<div className="table-responsive">
										<Table className="table mb-0">
											<thead className="thead-light">
												<tr>
													<th>#</th>
													<th>Название</th>
													<th>Величина скидки</th>

													<th></th>
												</tr>
											</thead>
											<tbody>
												{dataSales &&
													dataSales?.models.map(i => (
														<tr key={i.id}>
															<th scrope="row">{i.id}</th>
															<td>{i.name}</td>
															<td>{i.value}</td>
															<td className="edit">
																<Link
																	to={`/sale/edit/${i.id}`}
																	className="mr-1 btn btn-warning waves-effect waves-light"
																>
																	<i className="bx bx-cog" />
																</Link>
																<Button
																	type="submit"
																	color="danger"
																	className="mr-1 waves-effect waves-light"
																	onClick={() => {
																		setDeleteModal(true)
																		setDeleteId(i.id)
																	}}
																>
																	<i className="bx bx-trash-alt" />
																</Button>
															</td>
														</tr>
													))}
											</tbody>
										</Table>
									</div>
								</CardBody>
							</Card>
							<Pagination lastPage={dataSales?.lastPage} page={page} />
						</Col>
					</Row>
				</div>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить эту скидку?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deleteSale()}
							>
								Да
							</Button>
							<Button color="primary" onClick={() => setDeleteModal(false)}>
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default withTranslation()(Sales)
