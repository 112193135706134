import { apiGet, apiPost } from "api"
import Pagination from "components/Pagination"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	Col,
	Input,
	Modal,
	Row,
	Table,
} from "reactstrap"
import { updateCountNotifications } from "redux/actions/user"
import updateUrl from "utils/updateUrl"
import "./styling.scss"

const Questions = props => {
	const dispatch = useDispatch()
	const [deleteModal, setDeleteModal] = useState(false)
	const [search, setSearch] = useState("")
	const [status, setStatus] = useState("")
	const [deleteId, setDeleteId] = useState("")
	const [deleteText, setDeleteText] = useState("")
	const [errObj, setErrObj] = useState({})
	const { page } = useUrl()

	const { response: getQuestions, fetchData } = useFetch({
		url: `question/index?search=${search}&status=${status}&page=${
			page || 1
		}&limit=10`,
	})

	const deleteQuestionFunc = id => {
		setDeleteId(id)
		setDeleteModal(true)
	}

	const rejectQuestion = () => {
		apiPost({
			url: `/question/reject/${deleteId}`,
			postData: { reject: deleteText },
		}).then(res => {
			if (res.status === 406) {
				setErrObj(res.data)
				return
			}
			setErrObj({})
			setDeleteModal(false)
			fetchData()
			updateNotifications()
		})
	}

	const updateNotifications = () => {
		apiGet({
			url: `/notification/index`,
		}).then(res => {
			if (res.error) {
				return
			}

			dispatch(updateCountNotifications(res.data))
		})
	}

	useEffect(() => {
		updateUrl({ key: "page", value: "1" })
	}, [search])
	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Questions")}</h4>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<Row className="mb-2">
										<Col>
											<div className="top-controller">
												<div className="search-box mr-2">
													<div className="position-relative">
														<Input
															type="text"
															className="form-control border-0"
															placeholder={props.t("Search") + "..."}
															onChange={e => setSearch(e.target.value)}
														/>
														<i className="bx bx-search-alt search-icon" />
													</div>
												</div>

												<div className="d-flex">
													<div className="col-auto">
														<select
															onChange={e => setStatus(e.target.value)}
															className="form-control"
														>
															<option value="">Все вопросы</option>
															<option value="1">Новые</option>
															<option value="2">Опубликованные</option>
															<option value="3">Отклоненные</option>
														</select>
													</div>
												</div>
											</div>
										</Col>
									</Row>

									<div className="table-responsive">
										<Table className="table mb-0">
											<thead className="thead-light">
												<tr>
													<th>#</th>
													<th>Название товара</th>
													<th>Дата вопроса</th>
													<th>Статус</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{getQuestions?.models.map((i, index) => (
													<tr key={i.id}>
														<th scope="row">{i.id}</th>
														<td>{i.productName}</td>
														<td>{new Date(i?.date * 1000).toLocaleString()}</td>
														<td>
															{i.status === 1 && "Новый вопрос"}
															{i.status === 2 && "Опубликованный вопрос"}
															{i.status === 3 && "Отклоненный вопрос"}
														</td>
														<td className="edit">
															<Link
																to={`/question/edit/${i.id}`}
																type="submit"
																color="primary"
																className="mr-1 btn btn-primary waves-effect waves-light"
															>
																<i className="bx bx-cog" />
															</Link>
															{(i.status === 1 || i.status === 2) && (
																<Button
																	type="submit"
																	color="danger"
																	className="mr-1 waves-effect waves-light"
																	onClick={() => deleteQuestionFunc(i.id)}
																>
																	<i className="bx bx-trash-alt" />
																</Button>
															)}
														</td>
													</tr>
												))}
											</tbody>
										</Table>
									</div>
								</CardBody>
								<Pagination lastPage={getQuestions?.lastPage} page={page} />
							</Card>
						</Col>
					</Row>
				</div>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите отклонить этот вопрос?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<textarea
							className={`form-control d-block ${errObj.reject && "_error"}`}
							id="productdesc"
							rows="5"
							placeholder="Опишите причину отклонения вопроса..."
							onChange={e => setDeleteText(e.target.value)}
						/>
						<div className="mb-2" style={{ color: "#c72626" }}>
							{errObj.reject && errObj.reject}
						</div>
						<div className="text-center mt-4 m-auto">
							<Button
								onClick={() => rejectQuestion()}
								className="mr-2 "
								color="danger"
							>
								Да
							</Button>
							<Button onClick={() => setDeleteModal(false)} color="primary">
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

Questions.propTypes = {
	t: PropTypes.any,
}

export default withTranslation()(Questions)
