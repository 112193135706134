import { apiGet, apiPost } from "api"
import AccordeonItem from "components/Accodeons/AccordeonItem"
import CharactersProductEdit from "components/CharactersProductEdit"
import Input from "components/Input"
import { useFetch } from "hooks/useFetch"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useHistory, useParams } from "react-router-dom"
import Select from "react-select"
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	Container,
	FormGroup,
	Label,
	Modal,
	Row,
} from "reactstrap"
import "./styling.scss"

const ProductEdit = () => {
	const { id } = useParams()
	const history = useHistory()

	const { register, handleSubmit, setValue } = useForm()

	const [errObj, setErrObj] = useState({})
	const [sending, setSending] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [charactersModal, setCharactersModal] = useState(false)
	const [clickedBrandId, setClickedBrandId] = useState("")
	const [clickedCategoryId, setClickedCategoryId] = useState("")
	const [constCategory, setConstCategory] = useState("")
	const [catalogData, setCatalogData] = useState()
	const [categoryChar, setCategoryChar] = useState()
	const [selectedMulti, setselectedMulti] = useState()
	const [saveBtnTags, setSaveBtnTags] = useState(false)
	const [description, setDescription] = useState("")
	const [prodTags, setProdTags] = useState()
	const [themes, setThemes] = useState()

	const { response: brandsData } = useFetch({
		url: `/brand/index`,
	})

	const { response: catalogList } = useFetch({
		url: `/category/index`,
	})

	const { response: dataProduct, fetchData } = useFetch({
		url: `/product/view/${id}`,
	})

	const openOptionsProduct = () => {
		getCharactersCategory()
		getTagsProduct()
	}

	const getTagsProduct = () => {
		if (!sending) {
			setSending(true)

			apiGet({
				url: ``,
			})
				.then(res => {
					if (res.error) {
						return
					}

					setCharactersModal(true)
				})
				.finally(() => setSending(false))
		}
	}

	const getCharactersCategory = () => {
		let url = ""

		url = `/product-characteristic/index/${id}`

		// else {
		// 	url = `/characteristic/index?categoryId=${clickedCategoryId}`
		// }

		if (!sending) {
			setSending(true)

			apiGet({
				url: url,
			})
				.then(res => {
					if (res.error) {
						return
					}

					// if (clickedCategoryId === constCategory) {
					setCategoryChar(res.data)
					renderCharactersCategory(res.data)
					// }
					// else {
					// 	setCategoryChar(res.data.models)
					// 	renderCharactersCategory(res.data.models)
					// }

					setCharactersModal(true)
				})
				.finally(() => setSending(false))
		}
	}

	const renderCharactersCategory = characters => {
		if (characters) {
			return characters.map((i, index) => (
				<CharactersProductEdit item={i} key={index} productId={id} />
			))
		}
	}

	const clickedTabId = id => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/category/index?categoryId=${id}`,
			})
				.then(res => {
					if (res.error) {
						return
					}
					let arr = [...catalogData]

					const recSearchIdFunc = (stack, id) => {
						stack.map(i =>
							i.id === id
								? (i.children = res.data)
								: i.children && recSearchIdFunc(i.children, id)
						)
						setCatalogData(arr)
					}
					recSearchIdFunc(arr, id)
				})
				.finally(() => setSending(false))
		}
	}

	const renderAccordeonCatalog = item => {
		return item.map(i => (
			<AccordeonItem
				key={i.id}
				blocked={i.hasSubCategories === false}
				item={i}
				checkedPrev={clickedCategoryId === i.id && true}
				clickCategory={() => setClickedCategoryId(i.id)}
				openModalAdd={() => addCategory(i.id)}
				openModalEdit={() => editCategory(i.id)}
				clickedTabId={() => clickedTabId(i.id)}
			>
				{i.children && renderAccordeonCatalog(i.children)}
			</AccordeonItem>
		))
	}
	useEffect(() => {
		if (clickedCategoryId !== constCategory) {
			apiPost({
				url: `/product/update/${id}`,
				postData: {
					name: dataProduct.name,
					price: dataProduct.price.current,
					oldPrice: dataProduct?.price.old,
					description: dataProduct?.description,
					brandId: dataProduct?.brand?.id,
					categoryId: clickedCategoryId,
				},
			}).then(res => {
				if (res.status === 406) {
					setErrObj(res.data)
					return
				}
			})
		}
	}, [clickedCategoryId])

	const deleteProduct = () => {
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/product/delete/${id}`,
				postData: {},
			})
				.then(res => {
					if (res.status === 406) {
						setErrObj(res.data)
						return
					}

					history.goBack()
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const deleteTheme = idTheme => {
		if (!sending) {
			apiPost({
				url: `theme-product/untie-theme/${id}`,
				postData: { themeProductId: idTheme },
			})
				.then(res => {
					if (res.status === 406) {
						return
					}
					// let obj = { ...dataVersion.theme }
					// obj.sizes.splice(index, 1)
					// setDataVersion(obj)
					fetchData()
					getProdTags()
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	const editProduct = data => {
		const { name, price, oldPrice } = data
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/product/update/${id}`,
				postData: {
					name,
					price,
					oldPrice,
					description,
					brandId: clickedBrandId,
					categoryId: clickedCategoryId,
				},
			})
				.then(res => {
					if (res.status === 406) {
						setErrObj(res.data)
						return
					}

					history.goBack()
				})
				.finally(() => {
					setSending(false)
				})
		}
	}

	function handleMulti(e) {
		setselectedMulti(e)
	}

	useEffect(() => {
		if (selectedMulti?.length >= 1) {
			setSaveBtnTags(true)
		} else {
			setSaveBtnTags(false)
		}
	}, [selectedMulti])

	useEffect(() => {
		setCatalogData(catalogList)
	}, [catalogList])

	const getProdTags = () => {
		if (!sending) {
			setSending(true)
			apiGet({
				url: `/theme-product/index`,
			})
				.then(res => {
					if (!res.error) {
						const arr = res.data.models.filter(
							i1 => !themes.find(i2 => i1.id == i2.id)
						)
						let newArr = arr.map(i => {
							return { label: i.name, value: i.id }
						})
						setProdTags([
							{
								options: newArr,
							},
						])
					}
				})
				.finally(() => setSending(false))
		}
	}

	const getInfo = () => {
		setValue("name", dataProduct?.name)
		setClickedBrandId(dataProduct?.brand?.id)
		setValue("price", dataProduct?.price?.current)
		setValue("oldPrice", dataProduct?.price?.old)
		setClickedCategoryId(dataProduct?.category?.id)
		setConstCategory(dataProduct?.category?.id)
		setDescription(dataProduct?.description)
		setThemes(dataProduct?.theme)
	}

	const editProdTag = () => {
		let arr = []
		if (selectedMulti?.length) {
			selectedMulti.map(i => arr.push(i.value))
		}
		if (!sending) {
			setSending(true)
			apiPost({
				url: `/theme-product/bind-theme/${id}`,
				postData: {
					ids: arr,
				},
			})
				.then(res => {
					if (!res.error) {
						fetchData()
						setselectedMulti([])
						setSaveBtnTags(false)
					}
				})
				.finally(() => setSending(false))
		}
	}

	useEffect(() => {
		if (themes) {
			getProdTags()
		}
	}, [themes])

	useEffect(() => {
		getInfo()
	}, [dataProduct])

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Row>
						<Col xs="12">
							<form onSubmit={handleSubmit(editProduct)}>
								<Card>
									<CardBody>
										<Row>
											<Col sm="4" className="mb-3">
												<Label>Название товара</Label>
												<Input
													name="name"
													type="text"
													register={register}
													error={errObj.name}
												/>
											</Col>
											<Col sm="4">
												<Label for="formrow-InputState">Название бренда</Label>
												{clickedBrandId && (
													<select
														className={`form-control ${
															errObj.brand_id && "form-input _error"
														}`}
														onChange={e => setClickedBrandId(e.target.value)}
														value={clickedBrandId}
													>
														{brandsData?.models &&
															brandsData.models.map(i => (
																<option key={i.id} value={i.id}>
																	{i.name}
																</option>
															))}
													</select>
												)}
												<div className="form-error">
													{errObj.brand_id && errObj.brand_id}
												</div>
											</Col>
											<Col sm="2">
												<Label>Старая цена</Label>
												<Input
													name="oldPrice"
													type="text"
													register={register}
													error={errObj.oldPrice}
												/>
											</Col>
											<Col sm="2">
												<Label>Новая цена</Label>
												<Input
													name="price"
													type="text"
													register={register}
													error={errObj.price}
												/>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card>
									<CardBody>
										<Row>
											<Col lg={12}>
												<FormGroup>
													<Label htmlFor="productdesc">Описание товара</Label>

													{description && (
														<ReactQuill
															value={description}
															onChange={setDescription}
														/>
													)}
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
								</Card>

								<Card>
									<CardBody>
										<Row>
											<Col sm={12}>
												<FormGroup>
													<h5> Категория товара</h5>

													{catalogData && renderAccordeonCatalog(catalogData)}

													<div className="form-error">
														{errObj.category_id && errObj.category_id}
													</div>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<Button
													color="success"
													className="w-100"
													onClick={() => openOptionsProduct()}
												>
													Настройки товара
												</Button>
											</Col>
										</Row>
									</CardBody>
									<CardFooter>
										<div className="row">
											<div className="col-auto">
												<Button
													type="submit"
													color="primary"
													className="mr-1 waves-effect waves-light"
												>
													Изменить товар
												</Button>
											</div>
											<div className="col-auto">
												<Button
													onClick={() => setDeleteModal(true)}
													color="danger"
													className="waves-effect"
												>
													Удалить
												</Button>
											</div>
										</div>
									</CardFooter>
								</Card>
							</form>
						</Col>
					</Row>
				</Container>

				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0">
							Вы точно хотите удалить этот товар?
						</h5>
						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								className="mr-2 "
								color="danger"
								onClick={() => deleteProduct()}
							>
								Да
							</Button>
							<Button
								color="primary"
								onClick={() => {
									setDeleteModal(false)
								}}
							>
								Нет
							</Button>
						</div>
					</div>
				</Modal>

				<Modal
					size="xl"
					isOpen={charactersModal}
					toggle={() => {
						setCharactersModal(!charactersModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">Теги товара</h5>
						<button
							type="button"
							onClick={() => {
								setCharactersModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<Row>
							{dataProduct?.theme.length > 0 &&
								dataProduct?.theme.map(i => (
									<Col xs={"auto"} className="d-flex mb-2 mt-2" key={1}>
										<div className="d-flex">
											<Input defaultValue={i.name} readOnly />

											<Button
												color="danger"
												style={{ height: "100%" }}
												onClick={() => deleteTheme(i.id)}
											>
												<i className="bx bx-trash-alt" />
											</Button>
										</div>
									</Col>
								))}
						</Row>

						<Row>
							{prodTags && (
								<Col xs={10}>
									<Select
										noOptionsMessage={() => "Нет вариантов.."}
										className="basic-multi-select"
										value={selectedMulti}
										placeholder="Выберите теги..."
										isMulti={true}
										onChange={e => {
											handleMulti(e)
										}}
										options={prodTags}
										classNamePrefix={`select-control`}
									/>
								</Col>
							)}

							<Col xs={1}>
								{saveBtnTags && (
									<Button
										style={{ height: "100%" }}
										color="success"
										className="mr-1 waves-effect waves-light "
										onClick={() => editProdTag()}
									>
										<i className="bx bx-save" />
									</Button>
								)}
							</Col>
						</Row>
					</div>

					<div className="modal-header">
						<h5 className="modal-title mt-0">Характеристики товара</h5>
					</div>

					<div className="modal-body">
						<FormGroup>{renderCharactersCategory(categoryChar)}</FormGroup>
					</div>

					<div className="modal-footer">
						<Button color="primary" onClick={() => setCharactersModal(false)}>
							Закрыть
						</Button>
					</div>
				</Modal>
			</div>
		</>
	)
}

export default ProductEdit
