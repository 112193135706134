import cx from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { Button, Card, CardHeader, Collapse } from "reactstrap"
import "./styling.scss"

const AccordeonCategories = ({
	children,
	item,
	blocked,
	openModalAdd,
	openModalEdit,
	openModalDelete,
	openModalView,
	clickedTabId,
	togglePublic,
}) => {
	const [opened, setOpened] = useState(false)

	const openAccordeon = () => {
		setOpened(!opened)
	}

	return (
		<>
			<div id="accordion">
				<Card className="mb-0">
					<CardHeader
						className={cx("p-3 content-head ai-center head-acc", {
							clicked: !blocked,
							blocked: !item.public,
						})}
						id="headingThree"
						onClick={e => {
							!blocked &&
								e.target.id == "headingThree" &&
								(openAccordeon(), clickedTabId(e))
						}}
					>
						{item.name}
						<div className="btn-group btn-group-sm">
							<Button color="info" className="mr-1" onClick={openModalView}>
								<i className="mdi mdi-eye"></i>
							</Button>
							<Button color="primary" className="mr-1" onClick={openModalAdd}>
								<i className="bx bx-plus" />
							</Button>
							<Button color="warning" className="mr-1" onClick={openModalEdit}>
								<i className="bx bx-cog" />
							</Button>
							{item.public ? (
								<Button color="danger" className="mr-1" onClick={togglePublic}>
									<i
										className="	bx bx-lock-alt
"
									/>
								</Button>
							) : (
								<Button color="success" className="mr-1" onClick={togglePublic}>
									<i
										className="bx bx-lock-open
"
									/>
								</Button>
							)}

							<Button color="danger" onClick={openModalDelete}>
								<i className="bx bx-trash-alt" />
							</Button>
						</div>
					</CardHeader>
					<Collapse isOpen={opened}>
						<div className="content-acc">{children}</div>
					</Collapse>{" "}
				</Card>
			</div>
		</>
	)
}

AccordeonCategories.propTypes = {
	title: PropTypes.any,
}

export default withTranslation()(AccordeonCategories)
