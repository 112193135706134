import { apiGet, apiPost } from "api"
import { useFetch } from "hooks/useFetch"
import useUrl from "hooks/useUrl"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
	Button,
	Card,
	CardBody,
	Col,
	Input,
	Modal,
	Row,
	Table,
} from "reactstrap"
import { updateCountNotifications } from "redux/actions/user"
import "./style.scss"

const Reviews = props => {
	const dispatch = useDispatch()
	const [deleteModal, setDeleteModal] = useState(false)
	const [search, setSearch] = useState("")
	const [status, setStatus] = useState("")
	const [deleteId, setDeleteId] = useState("")

	const { page } = useUrl()

	const { response: getReviewData, fetchData } = useFetch({
		url: `reviews/index?status=${status}&page=${
			page || 1
		}&limit=10&search=${search}`,
	})

	const deleteReviewFunc = id => {
		setDeleteId(id)
		setDeleteModal(true)
	}

	const rejectAnswer = () => {
		apiPost({
			url: `reviews/delete/${deleteId}`,
		}).then(res => {
			if (res.status === 406) {
				setErrObj(res.data)
				return
			}
			setDeleteModal(false)
			fetchData()
			updateNotifications()
		})
	}

	const updateNotifications = () => {
		apiGet({
			url: `/notification/index`,
		}).then(res => {
			if (res.error) {
				return
			}

			dispatch(updateCountNotifications(res.data))
		})
	}

	return (
		<>
			<div className="page-content">
				<div className="container-fluid">
					<div className="d-flex justify-content-between mb-4 ai-center">
						<h4>{props.t("Reviews")}</h4>
					</div>

					<Row>
						<Col lg={12}>
							<Card>
								<CardBody>
									<Row className="mb-2">
										<Col>
											<div className="top-controller">
												<div className="search-box mr-2">
													<div className="position-relative">
														<Input
															type="text"
															className="form-control border-0"
															placeholder={props.t("Search") + "..."}
															onChange={e => setSearch(e.target.value)}
														/>
														<i className="bx bx-search-alt search-icon" />
													</div>
												</div>

												<div className="d-flex">
													<div className="col-auto">
														<select
															onChange={e => setStatus(e.target.value)}
															className="form-control"
														>
															<option value="">Все отзывы</option>
															<option value="1">Новые</option>
															<option value="2">Опубликованный</option>
														</select>
													</div>
												</div>
											</div>
										</Col>
									</Row>

									<div className="table-responsive">
										<Table className="table mb-0">
											<thead className="thead-light">
												<tr>
													<th>#</th>
													<th>Название товара</th>
													<th>Дата отзыва</th>
													<th>Статус</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{getReviewData?.models.map(i => (
													<tr key={i.id}>
														<td>{i.id}</td>
														<td>{i.productName}</td>
														<td>{new Date(i?.date * 1000).toLocaleString()}</td>
														<td>
															{i.status === 1 && "Новый отзыв"}
															{i.status === 2 && "Опубликованный отзыв"}
														</td>
														<td className="edit">
															<Link
																to={`/edit-review/${i.id}`}
																type="submit"
																color="primary"
																className="mr-1 btn btn-primary waves-effect waves-light"
															>
																<i className="bx bx-cog" />
															</Link>
															<Button
																type="submit"
																color="danger"
																className="mr-1 waves-effect waves-light"
																onClick={() => deleteReviewFunc(i.id)}
															>
																<i className="bx bx-trash-alt" />
															</Button>
														</td>
													</tr>
												))}
											</tbody>
										</Table>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
				<Modal
					isOpen={deleteModal}
					toggle={() => {
						setDeleteModal(!deleteModal)
					}}
					centered={true}
				>
					<div className="modal-header">
						<h5 className="modal-title mt-0 d-block">
							Вы точно хотите удалить этот отзыв?
						</h5>

						<button
							type="button"
							onClick={() => {
								setDeleteModal(false)
							}}
							className="close"
							data-dismiss="modal"
							aria-label="Закрыть"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body ">
						<div className="text-center mt-4 m-auto">
							<Button
								onClick={() => rejectAnswer()}
								className="mr-2 "
								color="danger"
							>
								Да
							</Button>
							<Button onClick={() => setDeleteModal(false)} color="primary">
								Нет
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	)
}

Reviews.propTypes = {
	t: PropTypes.any,
}

export default withTranslation()(Reviews)
