import { apiPost } from "api"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Input from "components/Input"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import ReactQuill from "react-quill"
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Collapse,
	FormGroup,
	Label,
	Modal,
} from "reactstrap"
import "./index.scss"

const AccordeonQuestions = ({ item, key, refresh }) => {
	const [opened, setOpened] = useState(false)
	const [modal, setModal] = useState({ edit: false, delete: false })
	const [question, setQuestion] = useState(item.question)
	const [answer, setAnswer] = useState(item.answer)
	const [errObj, setErrObj] = useState({})

	const deleteQuestion = () => {
		apiPost({
			url: `faq-question/delete/${item.id}`,
		}).then(res => {
			refresh()
			setModal(s => ({ ...s, delete: false }))
		})
	}

	const updateQuestion = () => {
		apiPost({
			url: `faq-question/update/${item.id}`,
			postData: {
				question,
				answer,
			},
		}).then(res => {
			if (res.error) {
				setErrObj(res.data)
				return
			}
			refresh()
			setModal(s => ({ ...s, edit: false }))
		})
	}

	return (
		<>
			<div id="accordion" key={key}>
				<Card className="mb-0">
					<CardHeader
						className="p-3"
						id="headingOne"
						style={{ cursor: "pointer" }}
						onClick={e => {
							e.target.id == "headingOne" && setOpened(!opened)
						}}
					>
						<div
							id="headingOne"
							className="d-flex justify-content-between ai-center"
						>
							<div
								className="m-0 font-14 d-flex"
								style={{ justifyContent: "space-between" }}
								id="headingOne"
							>
								{item.question}
							</div>
							<div className="btn-group btn-group-md">
								<Button
									color="warning"
									onClick={() => setModal(s => ({ ...s, edit: true }))}
									className="mr-1"
								>
									<i className="bx bx-cog" />
								</Button>
								<Button
									color="danger"
									onClick={() => setModal(s => ({ ...s, delete: true }))}
								>
									<i className="bx bx-trash-alt" />
								</Button>
							</div>
						</div>
					</CardHeader>

					<Collapse isOpen={opened}>
						<CardBody>
							<div dangerouslySetInnerHTML={{ __html: item.answer }}></div>
						</CardBody>
					</Collapse>
				</Card>
			</div>
			<Modal
				isOpen={modal.edit}
				toggle={() => setModal(s => ({ ...s, edit: !s.edit }))}
				centered={true}
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0">Изменение вопроса</h5>
					<button
						type="button"
						onClick={() => setModal(s => ({ ...s, edit: false }))}
						className="close"
						data-dismiss="modal"
						aria-label="Закрыть"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">
					{" "}
					<AvForm className="form-horizontal">
						<div className="form-group">
							<Input
								label="Вопрос"
								value={question}
								onChange={e => setQuestion(e)}
								placeholder="Введите вопрос..."
								type="text"
								fieldClasses="mb-2"
								error={errObj.question}
							/>
							<FormGroup>
								<Label
									style={{ paddingLeft: 15, fontWeight: 400, fontSize: 14 }}
								>
									Ответ
								</Label>

								<ReactQuill value={answer} onChange={setAnswer} />
								{errObj.answer && errObj.answer}
							</FormGroup>
						</div>
						<div className="text-center mt-4">
							<Button type="submit" color="primary" onClick={updateQuestion}>
								Изменить вопрос
							</Button>
						</div>
					</AvForm>
				</div>
			</Modal>
			<Modal
				isOpen={modal.delete}
				toggle={() => setModal(s => ({ ...s, delete: !s.delete }))}
				centered={true}
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0">
						Вы точно хотите удалить этот вопрос?
					</h5>
					<button
						type="button"
						onClick={() => setModal(s => ({ ...s, delete: false }))}
						className="close"
						data-dismiss="modal"
						aria-label="Закрыть"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body ">
					<div className="text-center mt-4 m-auto">
						<Button
							className="mr-2 "
							color="danger"
							onClick={() => deleteQuestion()}
						>
							Да
						</Button>
						<Button
							color="primary"
							onClick={() => setModal(s => ({ ...s, delete: !s.delete }))}
						>
							Нет
						</Button>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default withTranslation()(AccordeonQuestions)
